<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1>Loading Seller Central </h1>
            <h4>Payments</h4>
            <h4>Profits</h4>
            <h3>Audits</h3>
        </div><mat-spinner></mat-spinner>
    </div>
</div>
<div class="contentSC">
    <div class="bannerSCentral">
        <div class="sellerCentralHeading">
            <h1 class="headingcentral">
                welcome to seller central
            </h1>
            <div class="profileActions">
                <div class="viewProfile sellerCentralHeaderButton">
                    <button
                        [routerLink]="['/Merchant_Network/Check_Business_Profile', this.currentUserId,this.businessName,this.showLnkCTA]"
                        mat-stroked-button>
                        View My Profile
                    </button>
                </div>
                <div class="sellerRatings sellerCentralHeaderButton">
                    <button mat-stroked-button>
                        <mat-icon *ngFor="let ratings of ratingsAsStars(this.sellerRating)"
                            style="color: rgb(226, 226, 7);">star</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="overview">
            <div class="sales sec">
                <div class="yearsales salessubsec">
                    <mat-card>
                        <div class="col">
                            <div class="cardstate">
                                <span>sales this year</span>
                            </div>
                            <div class="amountAndpercChange">
                                <div class="amount">
                                    <p> {{this.salesDoneOfCurrentYear}} ₹</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="cardstate">
                                <span>sales last month</span>
                            </div>
                            <div class="amountAndpercChange">
                                <div class="amount">
                                    <p> {{this.salesDoneOfPreviousMonth}} ₹</p>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="currentmonthsales salessubsec">
                    <mat-card>
                        <div class="col">
                            <div class="cardstate">
                                <span>sales this month</span>
                            </div>
                            <div class="amountAndpercChange">
                                <div class="amount">
                                    <p>{{this.salesDoneOfCurrentMonth}} ₹</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="cardstate">
                                <span>Sales Trend</span>
                            </div>
                            <div class="amountAndpercChange">
                                <div class="percentage">
                                    <div class="trendUp" *ngIf="this.percentageChangeFromLastMonth > 0">
                                        <p>
                                            <span class="perc">{{this.percentageChangeFromLastMonth}} % </span>
                                            <span class="trend"> up from last month</span>
                                        </p>
                                    </div>
                                    <div class="trendDwn" *ngIf="this.percentageChangeFromLastMonth < 0">
                                        <p>
                                            <span class="perc">{{this.percentageChangeFromLastMonth * -1}} % </span>
                                            <span class="trend"> less from last month</span>
                                        </p>
                                    </div>
                                    <div class="trendNeut" *ngIf="this.percentageChangeFromLastMonth === 0">
                                        <p>
                                            <span class="perc">{{this.percentageChangeFromLastMonth}} % </span>
                                            <span class="trend"> up from last month</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="overviewInfo sec">
                <mat-card>
                    <div class="overView">
                        <div class="cardstate">
                            <span>Total Orders Settled</span>
                        </div>
                        <div class="amountAndpercChange">
                            <div class="ordVal">
                                <p *ngIf="this.allPurchaseOrdersSettled && this.allPurchaseOrdersSettled.length">
                                    {{allPurchaseOrdersSettled.length}}
                                </p>
                            </div>
                        </div>
                        <div class="cardstate">
                            <span>Total Invoices Paid</span>
                        </div>
                        <div class="amountAndpercChange">
                            <div class="ordVal">
                                <p *ngIf="this.totalInvoicesPaid && this.totalInvoicesPaid.length">
                                    {{this.totalInvoicesPaid.length}} </p>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
        <div class="balance sec">
            <mat-card>
                <div class="cardstate">
                    <span>Accounting & Withdrawal</span>
                </div>
                <div class="actionWithdraw">
                    <button *ngIf="this.accountingInfo && this.accountingInfo.length > 0" class="viewWithdrawals"
                        [routerLink]="['/SellerCentral/AllOrdersAccounting', this.currentUserId]" mat-stroked-button>
                        View All {{this.accountingInfo.length}} Orders
                    </button>
                    <button mat-stroked-button class="viewWithdrawals">
                        <mat-slide-toggle (ngModelChange)="filterOrdersWithInvoices(this.accountingInfo)"
                            [(ngModel)]="this.viewWithdrawableOrders">Withdrawable Orders
                        </mat-slide-toggle>
                    </button>
                </div>
                <div *ngIf="this.accountingInfo && this.accountingInfo.length > 0" class="placedOrders">
                    <div class="eachOrder" *ngFor="let useOrder of this.accountingInfo  | slice:0:8; let i=index">
                        <div class="accountingOrder">
                            <div class="orderAndCharges">
                                <div class="orderInfoAmount">
                                    <div class="orderAndInvoice">
                                        <span class="accounting aheading">{{useOrder.orderNumber}}</span>
                                    </div>
                                    <span class="accounting aheading small"
                                        *ngIf="!useOrder.invoiceInfo || useOrder.invoiceInfo && useOrder.invoiceInfo.length === 0">
                                        No Invoice Bill Paid
                                    </span>
                                </div>
                                <div class="orderValue">
                                    <span class="accounting akeys"> order value </span>
                                    <span
                                        class="accounting warn avalues">{{(useOrder.orderFinalValue).toLocaleString('en-IN')}}</span>
                                </div>
                                <div class="orderTotalCt">
                                    <span class="accounting akeys">including credit interest</span>
                                    <span class="accounting dontcount avalues">
                                        ({{(useOrder.orderCreditInterest).toLocaleString('en-IN')}})</span>
                                </div>
                                <div class="sellerChargesHandlingAndTCS">
                                    <span class="accounting akeys">seller platform charges (S.P.C)</span>
                                    <span class="accounting debit avalues">
                                        -{{(useOrder.orderTCSHandlingFeesAndFromSeller).toLocaleString('en-IN')}}</span>
                                </div>
                                <div class="orderPlatformCt">
                                    <span class="accounting akeys">credit terms interest charges (C.T.I)</span>
                                    <span class="accounting debit avalues">
                                        -{{(useOrder.orderPlatformCreditInterestShare).toLocaleString('en-IN')}}</span>
                                </div>
                                <div class="buyerChargesHandling">
                                    <span class="accounting akeys">buyer platform charges (B.P.C)</span>
                                    <span class="accounting debit avalues">
                                        -{{(useOrder.orderHandlingFeesFromBuyer).toLocaleString('en-IN')}}</span>
                                </div>
                                <div class="orderFinancing">
                                    <span class="accounting aheading payout">Payout Details</span>
                                </div>
                                <div class="orderFinalShare">
                                    <span class="accounting akeys">payout</span>
                                    <span class="accounting credit avalues">
                                        {{(useOrder.orderFinalSellerShareOfOrder).toLocaleString('en-IN')}}</span>
                                </div>
                                <div class="orderSellerCt">
                                    <span class="accounting akeys">seller share of credit interest</span>
                                    <span class="accounting credit avalues">
                                        +{{(useOrder.orderCreditInterestShareOfSeller).toLocaleString('en-IN')}}</span>
                                </div>
                                <div class="orderSellerCt">
                                    <span class="accounting akeys">final payout</span>
                                    <span class="accounting finalPayout avalues">₹
                                        {{(useOrder.orderFinalPayout).toLocaleString('en-IN')}}</span>
                                </div>
                            </div>
                            <div class="invoicesAndPayments"
                                *ngIf="useOrder.invoiceInfo && useOrder.invoiceInfo.length > 0">
                                <mat-accordion>
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                Withdraw from
                                            </mat-panel-title>
                                            <mat-panel-description>
                                                {{useOrder.invoicedTimes}} Paid Invoices
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <section class="invoices">
                                            <div *ngFor="let useInvoice of useOrder.invoiceInfo" class="eachInvoice">
                                                <div class="invoiceNumb">
                                                    <span class="accounting akeys">
                                                        Invoice
                                                    </span>
                                                    <span>{{useInvoice.useInvoiceNumber}}
                                                    </span>
                                                </div>
                                                <div class="paidDate">
                                                    <span class="accounting akeys">
                                                        paid on
                                                    </span>
                                                    <span>{{useInvoice.useInvoicePaidDate | date}} </span>
                                                </div>
                                                <div class="withdrawableDate">
                                                    <span class="accounting akeys">
                                                        withdrawable date
                                                    </span>
                                                    <span class="accounting warn avalues">
                                                        {{useInvoice.useFundWithdrawabledate | date}}</span>
                                                </div>
                                                <div class="invoiceAmount">
                                                    <span class="accounting akeys">
                                                        invoice amount
                                                    </span>
                                                    <span
                                                        class="accounting credit avalues">{{(useInvoice.useInvoiceAmount).toLocaleString('en-IN')}}
                                                    </span>
                                                </div>
                                                <div *ngIf="useInvoice.usetotalDeducDone" class="deduction">
                                                    <span class="accounting akeys">
                                                        deductions ( S.P.C + C.T.I)
                                                    </span>
                                                    <span
                                                        class="accounting debit avalues">{{(useInvoice.usetotalDeducDone).toLocaleString('en-IN')}}
                                                    </span>
                                                </div>
                                                <div *ngIf="useInvoice.usetotalDeducDone" class="withdrawable">
                                                    <span class="accounting akeys">
                                                        withdrawable
                                                    </span>
                                                    <span
                                                        class="accounting finalPayout avalues">{{(useInvoice.useWithdrawableAmount).toLocaleString('en-IN')}}
                                                    </span>
                                                </div>
                                                <div class="withDrawButton">
                                                    <button
                                                        *ngIf="useInvoice.useIsFundWithdrawable && useInvoice.useWithdrawableAmount > 0 "
                                                        class="createWithdrawals"
                                                        (click)="this.newWithdrawInvoice(useInvoice)"
                                                        mat-stroked-button>
                                                        Withdraw
                                                    </button>
                                                    <div class="warn" *ngIf="!useInvoice.useIsFundWithdrawable">
                                                        <span class="hint"
                                                            *ngIf="!useInvoice.useIsWithdrawalReqAlreadyPlaced"> Fund
                                                            cannot be
                                                            withdrawn before Withdrawal Date.
                                                        </span>
                                                        <br>
                                                        <span class="hint" *ngIf="useInvoice.useWithdrawableAmount <=0">
                                                            Deductions are more than this invoice amount
                                                        </span>
                                                        <br>
                                                        <span class="hint"
                                                            *ngIf="useInvoice.useIsWithdrawalReqAlreadyPlaced">
                                                            Withdrawal
                                                            Request is in process
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="this.accountingInfo && this.accountingInfo.length === 0" class="noPlacedOrders">
                    <div class="none">
                        <span>No Orders</span>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="settledorders sec">
            <mat-card>
                <div class="cardstate cardheader">
                    Settled Purchase Orders
                </div>
                <mat-divider></mat-divider>

                <div *ngIf="this.allPurchaseOrdersSettled && this.allPurchaseOrdersSettled.length > 0">
                    <div class="eachitmCol">
                        <div class="index"> Id</div>
                        <div class="order"> Order</div>
                        <div class="dateSettle"> Settlement Date</div>
                        <div class="settledAmount">Amount</div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="eachitmRow" *ngFor="let ords of this.allPurchaseOrdersSettled; index as i">
                        <div class="itm index highlight"> {{i+1}}</div>
                        <div class="itm order val"> {{ords.orderDetails.orderNumber}}</div>
                        <div class="itm dateSettle val">
                            {{ords.orderDetails.orderSettledAndPaidDate| date}}</div>
                        <div class="itm settledAmount val">
                            {{(ords.orderDetails.orderItemsFinalValue).toLocaleString('en-IN')}}</div>
                    </div>

                </div>
                <div class="none" *ngIf="this.allPurchaseOrdersSettled && this.allPurchaseOrdersSettled.length === 0">
                    <p>No Settled Orders</p>
                </div>
            </mat-card>
        </div>
        <div class="itemsSales sec">
            <mat-card>
                <div class="cardstate cardheader">
                    Net Sales of Items on Purchase Orders
                </div>
                <mat-divider></mat-divider>
                <div *ngIf="this.useItemSalesGroup && this.useItemSalesGroup.length > 0">
                    <div class="eachitmCol">
                        <div class="index"> Id</div>
                        <div class="title"> Item Title</div>
                        <div class="numberPOs">Orders</div>
                        <div class="netSales"> Net Sales</div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="eachitmRow" *ngFor="let items of this.useItemSalesGroup; index as i">
                        <div class="itm index highlight"> {{i+1}}</div>
                        <div [routerLink]="['/Listing/Place_Bulk_Order',items.itemId,items.catUniq,items.prodName]"
                            class="gotoItem itm title val">
                            {{items.itemTitle}}</div>
                        <div class="itm numberPOs val"> {{items.numberOfPurchaseOrders}}</div>
                        <div class="itm netSales val"> {{(items.netSales).toLocaleString('en-IN')}}</div>
                    </div>
                </div>
                <div class="none" *ngIf="this.useItemSalesGroup && this.useItemSalesGroup.length === 0">
                    <p>No Items Sold</p>
                </div>
            </mat-card>
        </div>
        <div class="upcomingpayments sec">
            <mat-card>
                <div class="cardstate cardheader">
                    Active Orders with Pending Payments
                </div>
                <mat-divider></mat-divider>
                <div *ngIf="this.allPurchaseOrdersActive && this.allPurchaseOrdersActive.length > 0 
                    && this.pendingPurchaseOrders">
                    <div class="eachitmCol">
                        <div class="index"> Id</div>
                        <div class="order"> Order</div>
                        <div class="amountorder"> Total</div>
                        <div class="pendingamount"> Pending Amount</div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="eachitmRow" *ngFor="let ords of this.pendingPurchaseOrders ; index as i">
                        <div class="itm index highlight"> {{i+1}}</div>
                        <div class="itm order val"> {{ords.orderNumber}}</div>
                        <div class="itm amountorder val">
                            {{(ords.orderAmount).toLocaleString('en-IN')}}</div>
                        <div class="itm pendingamount warn val">
                            {{(ords.pendingAmount).toLocaleString('en-IN')}}</div>
                    </div>
                </div>
                <div class="none" *ngIf="this.allPurchaseOrdersActive && this.allPurchaseOrdersActive.length === 0">
                    <p>No Active Orders</p>
                </div>
            </mat-card>
        </div>
        <div class="latepayments sec">
            <mat-card>
                <div class="cardstate cardheader">
                    Late Payments
                </div>
                <mat-divider></mat-divider>
                <div *ngIf="this.allPurchaseOrdersLate && this.allPurchaseOrdersLate.length > 0">
                    <div class="eachitmCol">
                        <div class="index"> Id</div>
                        <div class="order"> Order</div>
                        <div class="lateItems"> Late Items</div>
                        <div class="lateAmount">Late Amount</div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="eachitmRow" *ngFor="let ords of this.allPurchaseOrdersLate ; index as i">
                        <div class="itm index highlight"> {{i+1}}</div>
                        <div class="itm order val"> {{ords.orderNumber}}</div>
                        <div class="itm lateItems val"> {{ords.numberOfItemsLate}}</div>
                        <div class="itm lateAmount alert val"> {{(ords.lateAmountOfAllitems).toLocaleString('en-IN')}}
                        </div>
                    </div>
                </div>
                <div class="none"
                    *ngIf="!this.allPurchaseOrdersLate || this.allPurchaseOrdersLate && this.allPurchaseOrdersLate.length === 0">
                    <p>No Late Payment Orders</p>
                </div>
            </mat-card>
        </div>
    </div>
</div>