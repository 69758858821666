import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { HandleListingsService } from 'src/app/services/handle-listings.service';

@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.css']
})
export class Home2Component implements OnInit {
  userIsAuthenticated: boolean = false;
  private loginUserInfo: any
  gstDetailsPending: boolean = false
  gstVerificationPending: boolean = false
  //insert image banners here
  appBannerslandingproducts: string[] = [
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/1myapaar_track_Payments_invoices.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/2myapaar_Suppliers_Bulk_Orders_Origin.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/3myapaar_doorstep_bulk_Order_delivery.png",
  ]
  appBannerslandingvarieties: string[] = [
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/34.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/8myapaar-purchase-ordering.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/4multiple-varieties-choose.png",
  ]
  appBannersallproducthomegrid: string[] = [
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/7-myapaar-suppliers.png",
    "https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/Banners/9-vaayusupport.png",
  ]
  allLeads: any;
  private currentUserId: string
  constructor(
    private hls: HandleListingsService,
    private authService: AuthService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.doAuth()
    this.appBannerslandingproducts
    this.appBannersallproducthomegrid
    this.appBannerslandingvarieties
  }
  doAuth() {
    try {   //add conditions to if where backbutton should not be visible
      //since app component is loading first and then other components- we need to manually check with authservice for latest auth status
      // keep checking for user auth status
      // this.authService.autoAuthUser();
      this.userIsAuthenticated = this.authService.getIsAuth();
      this.loginUserInfo = this.authService.getUserLoginInfo();
      this.currentUserId = this.loginUserInfo.userId
      if (this.loginUserInfo.GST_Id === '') {
        this.gstDetailsPending = true
      }
      if (this.loginUserInfo.isGSTVerified === false) {
        this.gstVerificationPending = true
      }
    }
    catch (e) { }

  }
  changeUserInfo() {
    this.router.navigate([`accountInformation/${this.currentUserId}`])
  }
}
