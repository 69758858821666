import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, inject } from '@angular/core';
import { FlexLayoutModule } from "@angular/flex-layout";
import { AppRoutingModule } from '../app/modules/app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MaterialModule } from './material.module'
import { HttpClientModule, HttpClient, HttpBackend, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { CartComponent } from './components/cart/cart.component';
import { AllproductsComponent } from './components/allproducts/allproducts.component';
import { AllproducthomegridComponent } from './components/allproducthomegrid/allproducthomegrid.component';
import { SinglecategoryproductsComponent } from './components/singlecategoryproducts/singlecategoryproducts.component';
import { StateproductcategoryComponent } from './components/stateproductcategory/stateproductcategory.component';
import { StateallitemsofcategoryComponent } from './components/stateallitemsofcategory/stateallitemsofcategory.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchComponent } from './components/search/search.component';
import { FilterComponent } from './components/filter/filter.component';
import { StatefilterTabComponent } from './components/statefilter-tab/statefilter-tab.component';
import { QuickViewDialogComponent } from './components/quick-view-dialog/quick-view-dialog.component';
import { VarietyofproductsComponent } from './components/varietyofproducts/varietyofproducts.component';
import { FilterVarietiesComponent } from './components/filter-varieties/filter-varieties.component';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { LandingproductsComponent } from './components/landingproducts/landingproducts.component';
import { LandingvarietiesComponent } from './components/landingvarieties/landingvarieties.component';
import { FeaturedproductvarietiesComponent } from './components/featuredproductvarieties/featuredproductvarieties.component';
// import { FeaturedproductPageComponent } from './components/featuredproduct-page/featuredproduct-page.component';
import { LandingstatesComponent } from './components/landingstates/landingstates.component';
import { PostCreateComponent } from './components/post-create/post-create.component';
import { PostListComponent } from './components/post-list/post-list.component';
import { ProductsofstateComponent } from './components/productsofstate/productsofstate.component';
import { AllstatesComponent } from './components/allstates/allstates.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { AuthInterceptor } from './auth/auth-interceptor';
import { CreateListingComponent } from './components/create-listing/create-listing.component';
import { ShowListingComponent } from './components/show-listing/show-listing.component';
import { ViewListingComponent } from './components/view-listing/view-listing.component';
import { DeleteListingDialogComponent } from './components/view-listing/delete-listing-dialog/delete-listing-dialog.component';
import { EditListingDialogComponent } from './components/create-listing/edit-listing-dialog/edit-listing-dialog.component';
import { BidListingComponentComponent } from './components/bid-listing-component/bid-listing-component.component';
import { ViewbidsComponent } from './components/viewbids/viewbids.component';
import { BidcardComponent } from './components/bidcard/bidcard.component';
import { SingleListingComponent } from './components/single-listing/single-listing.component';
import { BackButtonDirective } from './directives/back-button.directive';
import { BidfilterComponent } from './components/viewbids/bidfilter/bidfilter.component';
import { BidsortComponent } from './components/viewbids/bidsort/bidsort.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { CreatePurchaseOrderComponent } from './components/create-purchase-order/create-purchase-order.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { MatSelectModule } from '@angular/material/select';
import { ViewPurchaseOrderComponent } from './components/view-purchase-order/view-purchase-order.component';
import { ViewAllPurchaseOrdersComponent } from './components/view-all-purchase-orders/view-all-purchase-orders.component';
import { ManageOrdersComponent } from './components/manage-orders/manage-orders.component';
import { ViewAllInvoicesComponent } from './components/view-all-invoices/view-all-invoices.component';
import { CreateInvoiceComponent } from './components/create-invoice/create-invoice.component';
import { ViewInvoiceOrderComponent } from './components/view-invoice-order/view-invoice-order.component';
import { DeletePurchaseOrderComponent } from './components/view-purchase-order/delete-purchase-order/delete-purchase-order.component';
import { RaiseDisputePURComponent } from './components/raise-dispute-pur/raise-dispute-pur.component';
import { RaiseDisputeINVComponent } from './components/raise-dispute-inv/raise-dispute-inv.component';
import { ViewIssuesPageComponent } from './components/view-issues-page/view-issues-page.component';
import { FeaturedlistingcategoryComponent } from './components/featuredlistingcategory/featuredlistingcategory.component';
import { CategoriesproductComponent } from './components/categoriesproduct/categoriesproduct.component';
import { PayhandlingfeesComponent } from './components/create-purchase-order/payhandlingfees/payhandlingfees.component';
import { environment } from 'src/environments/environment';
import { SettingsService } from './services/settings.service';
import { catchError, tap } from 'rxjs/operators';
import { ReturnordersComponent } from './components/returnorders/returnorders.component';
import { RefundPolicyComponent } from './components/refund-policy/refund-policy.component';
import { ViewReturnOrderComponent } from './components/view-return-order/view-return-order.component';
import { ViewAllReturnOrdersComponent } from './components/view-all-return-orders/view-all-return-orders.component';
import { RaiseDisputeReturnComponent } from './components/raise-dispute-return/raise-dispute-return.component';
import { DeliveryInvorderaVaayuputraComponent } from './components/delivery-invordera-vaayuputra/delivery-invordera-vaayuputra.component';
import { SellerCentralComponent } from './components/seller-central/seller-central.component';
import { CreateWithdrawalComponent } from './components/create-withdrawal/create-withdrawal.component';
import { ViewallaccountingComponent } from './components/viewallaccounting/viewallaccounting.component';
import { FaqPostsComponent } from './components/faq-posts/faq-posts.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { ViewPostComponent } from './components/view-post/view-post.component';
import { MyprofileComponent } from './components/myprofile/myprofile.component';
import { AllUserIssuesComponent } from './components/view-issues-page/all-user-issues/all-user-issues.component';
import { ViewpaymentsComponent } from './components/viewpayments/viewpayments.component';
import { BecomePartnerComponent } from './components/become-partner/become-partner.component';
import { RequirementsComponent } from './components/requirements/requirements.component';
import { MandipricespageComponent } from './components/mandipricespage/mandipricespage.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { DeleteAccountComponent } from './components/delete-account/delete-account.component';
import { BlogPostsComponent } from './components/blog-posts/blog-posts.component';
import { ReadBlogsComponent } from './components/read-blogs/read-blogs.component';
import { MyvyapaariChatComponent } from './components/myvyapaari-chat/myvyapaari-chat.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ViewSubscriptionComponent } from './components/view-subscription/view-subscription.component';
import { OneAccountypeComponent } from './components/signup/one-accountype/one-accountype.component';
import { TwoAccountInfoComponent } from './components/signup/two-account-info/two-account-info.component';
import { ThreeCompanyInfoComponent } from './components/signup/three-company-info/three-company-info.component';
import { BookDemoComponent } from './components/book-demo/book-demo.component';
import { ProductsWithLotSizeComponent } from './components/products-with-lot-size/products-with-lot-size.component';
import { ConfirmPaymentmodeComponent } from './components/single-listing/confirm-paymentmode/confirm-paymentmode.component';
import { TeamsPageComponent } from './components/teams-page/teams-page.component';
import { EditTeamComponent } from './components/edit-team/edit-team.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TradeProcurementComponent } from './components/trade-procurement/trade-procurement.component';
import { NewSupplyTradeComponent } from './components/new-supply-trade/new-supply-trade.component';
import { FourCommDetailsComponent } from './components/signup/four-comm-details/four-comm-details.component';
import { Home2Component } from './components/home2/home2.component';
import { MyTradesComponent } from './components/my-trades/my-trades.component';
import { FiveGstkycComponent } from './components/signup/five-gstkyc/five-gstkyc.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NewBuyTradeComponent } from './components/new-buy-trade/new-buy-trade.component';
import { NewAppliedTradesComponent } from './components/new-applied-trades/new-applied-trades.component';
import { LiveTradesComponent } from './components/live-trades/live-trades.component';
import { TrDetailsSheetComponent } from './components/tr-details-sheet/tr-details-sheet.component';
import { ViewContractorProfileComponent } from './components/view-contractor-profile/view-contractor-profile.component';
import { ViewContractorItemDetailsComponent } from './components/view-contractor-item-details/view-contractor-item-details.component';
import { QuickEnquiryFormComponent } from './components/quick-enquiry-form/quick-enquiry-form.component';

export function HttpLoaderFactory(http: HttpClient) {
  try {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
  }
  catch (e) { throw e }
}

declare global {
  interface Window {
    PhonePeCheckout?: any;
  }
}
@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    FooterComponent,
    CartComponent,
    AllproductsComponent,
    AllproducthomegridComponent,
    SinglecategoryproductsComponent,
    StateproductcategoryComponent,
    StateallitemsofcategoryComponent,
    SearchComponent,
    FilterComponent,
    StatefilterTabComponent,
    QuickViewDialogComponent,
    VarietyofproductsComponent,
    FilterVarietiesComponent,
    LandingproductsComponent,
    LandingvarietiesComponent,
    FeaturedproductvarietiesComponent,
    // FeaturedproductPageComponent,
    LandingstatesComponent,
    PostCreateComponent,
    PostListComponent,
    ProductsofstateComponent,
    AllstatesComponent,
    LoginComponent,
    SignupComponent,
    CreateListingComponent,
    ShowListingComponent,
    ViewListingComponent,
    DeleteListingDialogComponent,
    EditListingDialogComponent,
    BidListingComponentComponent,
    ViewbidsComponent,
    BidcardComponent,
    SingleListingComponent,
    BackButtonDirective,
    BidfilterComponent,
    BidsortComponent,
    ForgotpasswordComponent,
    CreatePurchaseOrderComponent,
    UserProfileComponent,
    ViewPurchaseOrderComponent,
    ViewAllPurchaseOrdersComponent,
    ManageOrdersComponent,
    ViewAllInvoicesComponent,
    CreateInvoiceComponent,
    ViewInvoiceOrderComponent,
    DeletePurchaseOrderComponent,
    RaiseDisputePURComponent,
    RaiseDisputeINVComponent,
    ViewIssuesPageComponent,
    FeaturedlistingcategoryComponent,
    CategoriesproductComponent,
    PayhandlingfeesComponent,
    ReturnordersComponent,
    RefundPolicyComponent,
    ViewReturnOrderComponent,
    ViewAllReturnOrdersComponent,
    RaiseDisputeReturnComponent,
    DeliveryInvorderaVaayuputraComponent,
    SellerCentralComponent,
    CreateWithdrawalComponent,
    ViewallaccountingComponent,
    FaqPostsComponent,
    AboutusComponent,
    ViewPostComponent,
    MyprofileComponent,
    AllUserIssuesComponent,
    ViewpaymentsComponent,
    BecomePartnerComponent,
    RequirementsComponent,
    MandipricespageComponent,
    TermsConditionsComponent,
    DeleteAccountComponent,
    BlogPostsComponent,
    ReadBlogsComponent,
    MyvyapaariChatComponent,
    LoaderComponent,
    ViewSubscriptionComponent,
    OneAccountypeComponent,
    TwoAccountInfoComponent,
    ThreeCompanyInfoComponent,
    BookDemoComponent,
    ProductsWithLotSizeComponent,
    ConfirmPaymentmodeComponent,
    TeamsPageComponent,
    EditTeamComponent,
    TradeProcurementComponent,
    NewSupplyTradeComponent,
    FourCommDetailsComponent,
    Home2Component,
    MyTradesComponent,
    FiveGstkycComponent,
    NewBuyTradeComponent,
    NewAppliedTradesComponent,
    LiveTradesComponent,
    TrDetailsSheetComponent,
    ViewContractorProfileComponent,
    ViewContractorItemDetailsComponent,
    QuickEnquiryFormComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    FlexLayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatSelectModule,
    DragDropModule,
    // BackButtonDirective
    MatCarouselModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
    // BackButtonDirective
  ],
  //use AuthInterceptor for HttpInterceptors
  //can have multi interceptors
  providers: [
    { provide: Location, useClass: BackButtonDirective }, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const settingsServ = inject(SettingsService)
        const http = inject(HttpClient)
        return () => new Promise(resolve => {
          // setings for deployed app
          if (environment.production) {
            http.get('../../config.json').pipe(tap((data: any) => {
              settingsServ.cherryURL = data.cherryURL
              resolve(true);
            }), catchError(err => {
              //fallback url
              settingsServ.cherryURL = 'http://localhost:4000';
              resolve(true);
              return null
            })
            ).subscribe()
          }
          else {
            //setings for local app
            const settings = require('../../config-dev.json')
            settingsServ.cherryURL = settings.cherryURL
            resolve(true);
          }
        });
      },
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
