import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ListingModel } from 'src/app/datamodels/ListingModel';
import { ProductCategoryModel } from 'src/app/datamodels/productcategory';
import { UserAuthModel } from 'src/app/datamodels/userModel';
import { HandleInvoiceService } from 'src/app/services/handle-invoice.service';
import { HandleListingsService } from 'src/app/services/handle-listings.service';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { HandleUserLinkService } from 'src/app/services/handle-user-link.service';
import { ProductcategoryService } from 'src/app/services/productcategory.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css']
})
export class MyprofileComponent implements OnInit {
  mode: string;
  currentUserId: string
  userId: string;
  isLoading: boolean = false;
  isUserLogoPresent: boolean = false;
  isUserShopImagePresent: boolean = false;
  private loginUserInfo: any
  private viewUser: UserAuthModel;
  userCompanyName: string;
  userName: string;
  userGST: string;
  userPAN: string;
  userState: string;
  userCity: string;
  userRating: number;
  displayLogo: string;
  displayShopImg: string;
  nodisplayShopImg: string;
  isOwnerProfile: boolean = false;
  isUserGSTavailable: boolean;
  isUserPANavailable: boolean;
  isUserPANVerified: boolean;
  isUserGSTVerified: boolean;
  userIsVerified: boolean = false;
  isSellerProfile: boolean = false;
  isBuyerProfile: boolean = false;
  userAccountType: string;
  userSubAccountTypes: string[];
  userPlacedTotalPos: number;
  userPaidTotalPos: number;
  userSoldTotalPos: number;
  myListings: ListingModel[] = [];
  noListingFound: boolean = false;
  noListings: string;
  paidInvoices: number;
  paidInvoIntime: number;
  paidInvoLate: number;
  paidInvoPending: number;
  ownerProfileRefCode: string;
  copyReferral: string;
  useEmailReferral: string;
  copyReferral2: string;
  filteredCats: ProductCategoryModel[];
  showLnkCTA: boolean = false;
  constructor(
    private usrLnkSrv: HandleUserLinkService,
    private utilS: UtilsService,
    private categoryService: ProductcategoryService,
    public route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private hps: HandlePurchaseOrderService,
    private his: HandleInvoiceService,
    private handleListing: HandleListingsService,
    private _snackBar: MatSnackBar,
  ) { }
  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('userId')) {
        //ListingId is in routes - editListing
        this.userId = paramMap.get('userId');
        this.loadUserDetails(this.userId)
        this.showLnkCTA = JSON.parse(paramMap.get('showlinkCta'));
      }
      else {
        this.router.navigate(['/'])
      }
    });

  }
  ngDoCheck() {
    this.checkOwnerProfile()
  }
  checkOwnerProfile() {
    try {
      this.loginUserInfo = this.authService.getUserLoginInfo();
      this.currentUserId = this.loginUserInfo.userId
      if (this.currentUserId && this.currentUserId !== '' && this.currentUserId === this.userId) {
        this.isOwnerProfile = true
        this.ownerProfileRefCode = this.loginUserInfo.profileAppCode || 'mypr-genus'
        this.copyReferral = `Hey! Join Myapaar with me and let's grow our business together. Sign up with my referral code ${this.ownerProfileRefCode} to start with a 4 Star Account Instantly - free of cost. Code expires soon, take care.`;
        this.useEmailReferral = `mailto:?subject= Join Myapaar with me&body=Hey ! Let's grow our business together. 
        Sign up with my personal referral code ${this.ownerProfileRefCode} to start with a 4 Star Account Instantly - free of cost. Code expires soon, take care.`
      }
    }
    catch (e) {
      throw e
    }
  }
  loadUserDetails(userID: string) {
    try {
      this.isLoading = true; //spinner starts
      this.authService.getAccountDetails(userID).then(info => {
        this.isLoading = false
        this.viewUser = info.result
        if (this.viewUser.interCategors && this.viewUser.interCategors.length > 0) {
          const allCategories = this.viewUser.interCategors
          this.filterCategories(allCategories)
        }
        this.loadPlacedOrders(userID)
        if (this.viewUser.accounttype === "Seller") {
          this.isSellerProfile = true
          this.getSellerListings(userID)
          this.loadSoldOrders(userID)
          this.loadPaidOrders(userID)
        }
        if (this.viewUser.accounttype === "Buyer") {
          this.isBuyerProfile = true
          this.getBuyerPaymentTrends(userID)
          this.loadPaidOrders(userID)
        }
        this.userCompanyName = this.viewUser.companyName;
        this.userName = this.viewUser.firstname + ' ' + this.viewUser.lastname;
        this.isUserGSTavailable = this.viewUser.isGSTAvailable
        this.isUserGSTVerified = this.viewUser.isGSTVerified
        if (this.isUserGSTavailable) {
          this.userGST = this.utilS.replaceLast4Digits(this.viewUser.GST_Id)
        }
        if (this.isUserGSTavailable && this.isUserGSTVerified) {
          this.userIsVerified = true
        }
        this.userAccountType = this.viewUser.accounttype
        this.userSubAccountTypes = this.viewUser.accountSubtype
        this.userState = this.viewUser.state
        this.userCity = this.viewUser.city
        this.userRating = this.viewUser?.accountRating || 2
        this.viewUser.logoImage && this.viewUser.logoImage !== "" ? this.isUserLogoPresent = true : this.isUserLogoPresent = false
        this.viewUser.shopImage && this.viewUser.shopImage !== "" ? this.isUserShopImagePresent = true : this.isUserShopImagePresent = false
        if (this.isUserLogoPresent) {
          this.displayLogo = this.viewUser.logoImage
        }
        if (this.isUserShopImagePresent) {
          this.displayShopImg = this.viewUser.shopImage
        }
        if (!this.isUserShopImagePresent) {
          this.nodisplayShopImg = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23a4b3ff' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%238a9cff' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%236f85ff' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23556eff' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%233B56FF' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%234364ff' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%234b71ff' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23527eff' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%235a8aff' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%236295FF' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E"
        }
      })
    }
    catch (e) {
      throw e
    }

  }
  filterCategories(cats: any) {
    try {
      this.categoryService.getCategories().subscribe(allCat => {
        this.filteredCats = allCat.filter(filcats => {
          return cats.includes(filcats._id)
        })
      })

    }
    catch (e) {
      throw e
    }
  }

  getSellerListings(sellerId: string) {
    this.handleListing.getUserListings(sellerId).subscribe(
      allUserListings => {
        this.myListings = allUserListings
        this.isLoading = false;
        if (!this.myListings || this.myListings.length === 0) {
          this.noListingFound = true;
          this.noListings = "No listings available"
          return
        }
      }
    )
    //loadingStarts
    this.isLoading = true;
  }

  getBuyerPaymentTrends(buyerId: string) {
    this.his.getInvosPaidTrend(buyerId).then(invosPaidTrend => {
      if (invosPaidTrend && invosPaidTrend.status === 200) {
        this.paidInvoices = invosPaidTrend.totalInvosPaid
        this.paidInvoIntime = invosPaidTrend.totalInvosOnTime
        this.paidInvoLate = invosPaidTrend.totalInvosLate
        this.paidInvoPending = invosPaidTrend.totalInvosPending
      }
    })
  }

  ratingsAsStars(stars: any) {
    return Array(stars)
  }

  loadPlacedOrders(commonUserId: string) {
    this.hps.getNumberPOsPlaced(commonUserId).then(totalPlacedPos => {
      if (totalPlacedPos && totalPlacedPos.status === 200) {
        this.userPlacedTotalPos = totalPlacedPos.placedPOS
      }
    })
  }

  loadSoldOrders(sellerUserId: string) {
    this.hps.getNumberPOsSOLD(sellerUserId).then(totalSOLDPos => {
      if (totalSOLDPos && totalSOLDPos.status === 200) {
        this.userSoldTotalPos = totalSOLDPos.soldPOS
      }
    })
  }

  loadPaidOrders(buyerUserId: string) {
    this.hps.getNumberPOsPaid(buyerUserId).then(totalPAIDPos => {
      if (totalPAIDPos && totalPAIDPos.status === 200) {
        this.userPaidTotalPos = totalPAIDPos.paidPOS
      }
    })
  }

  successCopyToClipboard(success: string) {
    try {
      const action = "Ok"
      // give snackBar error then route to profule page on action
      // login error notific
      this._snackBar.open(success, action, {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
    }
    catch (e) {
      throw e
    }
  }
}
