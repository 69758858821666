<div class="contentAbout">
    <div class="serviceCard part1">
        <div class="serviceBanner1">
            <div class="centered">
                <h1 class="heading">About Myapaar</h1>
            </div>
            <img src="https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/myapaar-aboutus-team.jpg"
                alt="Myapaar about platform" style="width:100%;">
        </div>
        <div class="contentAboutusteam">
            <div class="contentHeading">
                <h1 class="mainHead">India's Leading B2B E-Commerce Platform</h1>
                <h3 class="subHead">Transforming Merchant Networks</h3>
            </div>
            <div class="mainContent">
                <p>
                    Myapaar is a revolutionary B2B e-commerce platform for <b>post-harvest non-perishable food commodities.</b> We bridge the gap between quality food commodities and global buyers by offering advanced digital tools for a seamless procurement experience. From creating purchase orders to checking real-time item rates, we simplify the entire trade process. Whether you’re looking to <b>export, import, or engage in domestic trade</b>, Myapaar provides instant custom quotes and a transparent platform for negotiating the best prices. Establish sustainable merchant networks that help build long-term partnerships, fostering trust, quality, and seamless procurement satisfaction.
                </p>
                <p>
                    India, being the largest producer of milk, spices, pulses, bananas, wheat, and jute, and the second largest producer of rice, sugarcane, fruits, and vegetables, holds immense potential as a food exporter. By connecting buyers globally, Myapaar taps into India's vast agricultural production, ensuring <b>quality and affordability</b> at every transaction. With a focus on supporting fair trade practices and ethical sourcing, Myapaar enhances the global food supply chain, benefiting both suppliers and buyers. Join us to be a part of this impactful transformation in food trade.
                </p>
            </div>
        </div>
    </div>
    <div class="serviceCard part2">
        <div class="contentAboutusteam">
            <div class="contentHeading">
                <h1 class="mainHead">Mobile Se Vyapaar</h1>
                <h3 class="subHead">Trade on Mobile</h3>
            </div>
            <div class="mainContent">
                <p>
                    Our mission is to streamline the bulk buying and selling process, making high-quality products accessible, efficient, and affordable for all businesses—big or small. From local farmers to large multinational corporations, Myapaar ensures that everyone can access the best commodities at competitive prices. By reducing overhead costs, increasing transparency, and fostering trust, Myapaar helps businesses unlock greater profits and operational efficiency. With a focus on sustainable and ethical sourcing, our platform makes it easier than ever to source responsibly.
                </p>
                <p>
                    With over 4 crore merchants, including producers, wholesalers, and retailers, aiming to connect, Myapaar ensures a vibrant, dynamic, and trusted ecosystem for all. Our marketplace not only connects suppliers and buyers but also empowers merchants with tools that help them grow. Whether you’re a small producer or a large corporate buyer, Myapaar simplifies sourcing, enhances supply chain management, and promotes sustainable procurement practices, ensuring fair trade and global connectivity.
                </p>
            </div>
        </div>
        <div class="serviceBanner2">
            <div class="centered">
                <h1 class="heading">Mission</h1>
            </div>
            <img src="https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/myapaar-aboutus-seller.jpg"
                alt="Myapaar about seller" style="width:100%;">
        </div>
    </div>
    <div class="serviceCard part3">
        <div class="serviceBanner3">
            <div class="centered">
                <h1 class="heading">Vision</h1>
            </div>
            <img src="https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/myapaar-aboutus-buyer.jpg"
                alt="Myapaar about buyer" style="width:100%;">
        </div>
        <div class="contentAboutusteam">
            <div class="contentHeading">
                <h1 class="mainHead">Redefining B2B Commerce</h1>
                <h3 class="subHead">A Global Marketplace</h3>
            </div>
            <div class="mainContent">
                <p>
                    Businesses worldwide are gaining direct access to India's rich agricultural resources through Myapaar's innovative platform. <b>Best quality spices, grains, vegetables, essential oils, and other food commodities can be sourced directly from the lands where they are grown and processed with transparency and ease.</b> Our focus on ethical sourcing and sustainability ensures that both buyers and sellers can engage in responsible trade while benefiting from high-quality products at competitive rates.
                </p>
                <p>
                    Leveraging India's unique position as a leading agricultural producer, we aspire to redefine bulk transactions globally. By creating a reliable and efficient supply chain, Myapaar ensures that each trade delivers unmatched value. Our platform makes it easy for businesses to adopt sustainable sourcing practices, minimize waste, and support fair trade, thus contributing to long-term environmental and social benefits. Myapaar is more than a marketplace; it’s a movement towards a smarter, greener, and more connected global trade system.
                </p>
            </div>
        </div>
    </div>
    <div class="serviceCard part4">
        <div class="contentAboutusteam2">
            <div class="servicesList">
                <div class="serv1 secServ">
                    <div class="imgSec im1"></div>
                    <div class="descSec">
                        <p class="desc de1">Domestic Trade & Impex</p>
                    </div>
                </div>
                <div class="serv2 secServ">
                    <div class="imgSec im2"></div>
                    <div class="descSec">
                        <p class="desc de2">Inventory Listings and Chain Tracking</p>
                    </div>
                </div>
                <div class="serv3 secServ">
                    <div class="imgSec im3"></div>
                    <div class="descSec">
                        <p class="desc de3">Origin Pickup and Lastmile Delivery</p>
                    </div>
                </div>
                <div class="serv4 secServ">
                    <div class="imgSec im4"></div>
                    <div class="descSec">
                        <p class="desc de4">Delivery Quality to Brands</p>
                    </div>
                </div>
                <div class="serv5 secServ">
                    <div class="imgSec im5"></div>
                    <div class="descSec">
                        <p class="desc de5">Easy Finance and Online Payments</p>
                    </div>
                </div>
                <div class="serv6 secServ">
                    <div class="imgSec im6"></div>
                    <div class="descSec">
                        <p class="desc de6">Sales, Supply and Purchase Trends</p>
                    </div>
                </div>
                <div class="serv7 secServ">
                    <div class="imgSec im7"></div>
                    <div class="descSec">
                        <p class="desc de7">Customer Support Solutions</p>
                    </div>
                </div>
            </div>
            <div class="finalClosing">
                <h1>Join us at Myapaar and experience a new era of efficiency and opportunity in bulk commerce.</h1>
            </div>
        </div>
    </div>
</div>