import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TradeItemDetails } from 'src/app/datamodels/contractOrders.ts/tradeItemsDetails';

@Component({
  selector: 'app-tr-details-sheet',
  templateUrl: './tr-details-sheet.component.html',
  styleUrls: ['./tr-details-sheet.component.css']
})
export class TrDetailsSheetComponent implements OnInit {

  collection = Array.from(document.getElementsByClassName('mat-bottom-sheet-container') as HTMLCollectionOf<HTMLElement>)[0]
  dataType: string
  detailType: string
  contractorID: string
  itemDetails: TradeItemDetails
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public trData: any,
    private _bottomSheetRef: MatBottomSheetRef<TrDetailsSheetComponent>
  ) { }


  ngOnInit(): void {
    this.dataType = this.trData.dt
    if (this.trData.dt == 'contract') {
      this.detailType = 'Contractor Business Details'
      this.contractorID = this.trData.d.contrCreatorId
    }
    else if (this.trData.dt == 'item') {
      this.detailType = 'Item Details'
      this.itemDetails = this.trData.d
    }
  }

  openLink(event: MouseEvent): void {
    try {
      this._bottomSheetRef.dismiss();
      event.preventDefault();
    }
    catch (e) {
      throw e
    }
  }
}
