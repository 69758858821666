import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ListingModel } from 'src/app/datamodels/ListingModel';
import { ProductCategoryModel } from 'src/app/datamodels/productcategory';
import { UserAuthModel } from 'src/app/datamodels/userModel';
import { HandleInvoiceService } from 'src/app/services/handle-invoice.service';
import { HandleListingsService } from 'src/app/services/handle-listings.service';
import { HandlePurchaseOrderService } from 'src/app/services/handle-purchase-order.service';
import { HandleUserLinkService } from 'src/app/services/handle-user-link.service';
import { ProductcategoryService } from 'src/app/services/productcategory.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-view-contractor-profile',
  templateUrl: './view-contractor-profile.component.html',
  styleUrls: ['./view-contractor-profile.component.css']

})
export class ViewContractorProfileComponent implements OnInit, OnChanges {
  @Input() viewUserID: string;
  mode: string;
  currentUserId: string
  userId: string;
  isLoading: boolean = false;
  isUserLogoPresent: boolean = false;
  isUserShopImagePresent: boolean = false;
  private loginUserInfo: any
  private viewUser: UserAuthModel;
  userCompanyName: string;
  userName: string;
  userGST: string;
  userPAN: string;
  userState: string;
  userCity: string;
  userRating: number;
  displayLogo: string;
  displayShopImg: string;
  nodisplayShopImg: string;
  isOwnerProfile: boolean = false;
  isUserGSTavailable: boolean;
  isUserPANavailable: boolean;
  isUserPANVerified: boolean;
  isUserGSTVerified: boolean;
  userIsVerified: boolean = false;
  isSellerProfile: boolean = false;
  isBuyerProfile: boolean = false;
  userAccountType: string;
  userSubAccountTypes: string[];
  userPlacedTotalPos: number;
  userPaidTotalPos: number;
  userSoldTotalPos: number;
  myListings: ListingModel[] = [];
  noListingFound: boolean = false;
  noListings: string;
  paidInvoices: number;
  paidInvoIntime: number;
  paidInvoLate: number;
  paidInvoPending: number;
  ownerProfileRefCode: string;
  copyReferral: string;
  useEmailReferral: string;
  copyReferral2: string;
  filteredCats: ProductCategoryModel[];
  showLnkCTA: boolean = false;
  constructor(
    private utilS: UtilsService,
    private categoryService: ProductcategoryService,
    public route: ActivatedRoute,
    private authService: AuthService,
    private handleListing: HandleListingsService,
    private cdr: ChangeDetectorRef,
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['viewUserID'] && changes['viewUserID'].currentValue) {
      this.loadUserDetails(changes['viewUserID'].currentValue);
    }
    console.log('Child Component UserID:', this.viewUserID);
  }
  ngOnInit(): void {
    this.loadUserDetails(this.viewUserID)
  }
  loadUserDetails(userID: string) {
    try {
      this.authService.getAccountDetails(userID).then(info => {
        this.viewUser = info.result
        if (this.viewUser.interCategors && this.viewUser.interCategors.length > 0) {
          const allCategories = this.viewUser.interCategors
          this.filterCategories(allCategories)
        }
        this.userCompanyName = this.viewUser.companyName;
        this.userName = this.viewUser.firstname + ' ' + this.viewUser.lastname;
        this.isUserGSTavailable = this.viewUser.isGSTAvailable
        this.isUserGSTVerified = this.viewUser.isGSTVerified
        if (this.isUserGSTavailable) {
          this.userGST = this.utilS.replaceLast4Digits(this.viewUser.GST_Id)
        }
        if (this.isUserGSTavailable && this.isUserGSTVerified) {
          this.userIsVerified = true
        }
        this.userAccountType = this.viewUser.accounttype
        this.userSubAccountTypes = this.viewUser.accountSubtype
        this.userState = this.viewUser.state
        this.userCity = this.viewUser.city
        this.userRating = this.viewUser?.accountRating || 2
        this.viewUser.logoImage && this.viewUser.logoImage !== "" ? this.isUserLogoPresent = true : this.isUserLogoPresent = false
        this.viewUser.shopImage && this.viewUser.shopImage !== "" ? this.isUserShopImagePresent = true : this.isUserShopImagePresent = false
        this.cdr.markForCheck(); // Mark component for changes
        if (this.isUserLogoPresent) {
          this.displayLogo = this.viewUser.logoImage
        }
        if (this.isUserShopImagePresent) {
          this.displayShopImg = this.viewUser.shopImage
        }
      })
    }
    catch (e) {
      throw e
    }
  }
  ratingsAsStars(stars: any) {
    return Array(stars)
  }
  filterCategories(cats: any) {
    try {
      this.categoryService.getCategories().subscribe(allCat => {
        this.filteredCats = allCat.filter(filcats => {
          return cats.includes(filcats._id)
        })
      })

    }
    catch (e) {
      throw e
    }
  }


}
