<div class="content">
    <div class="loginBox">
        <div class="loginText">
            <h1>
                {{'member login'|translate}}
            </h1>
        </div>
        <mat-card>
            <mat-spinner *ngIf="isLoading"></mat-spinner>
            <div class="loginFormFill">
                <form [formGroup]="loginform" (submit)="onLogin()" *ngIf="!isLoading">
                    <div class="filleither">
                        <div *ngIf="!this.loginform.value.email" class="sec1">
                            <mat-form-field>
                                <input matInput type="tel" name="phone" [(ngModel)]="this.userPhone"
                                    (ngModelChange)="this.checkPhoneVerified()" formControlName="phone"
                                    [placeholder]="'phone number'|translate" pattern="[0-9]*" maxlength="10">
                                <mat-error *ngIf="formErrors.phone">{{formErrors.phone}}</mat-error>
                                <mat-hint>98xxxxxxx9</mat-hint>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!this.loginform.value.phone && !this.loginform.value.email" class="sec2">
                            <mat-hint>
                                {{'or'|translate}}
                            </mat-hint>
                        </div>
                        <div *ngIf="!this.loginform.value.phone" class="sec3">
                            <mat-form-field>
                                <input matInput type="email" name="email" formControlName="email"
                                    [placeholder]="'email'|translate">
                                <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error>
                                <mat-hint>Paw***ar@emailprovider.com</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>


                    <mat-form-field class="passField" appearance="outline">
                        <input matInput type="password" name="password" formControlName="password"
                            [placeholder]="'password'|translate" [type]="hide? 'password':'text'" required>
                        <button mat-icon-button matSuffix (click)="hide=!hide" type="button"
                            [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide? 'visibility_off' : 'visibility'}}</mat-icon></button>
                        <mat-error *ngIf="formErrors.password">{{formErrors.password}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="this.showOTPActivation">
                        <mat-label>{{"otp verification"|translate}}</mat-label>
                        <input matInput type="number" maxlength="5" [disabled]="!this.showOTPActivation"
                            pattern="[0-9]*" formControlName="verifyOTP" autocomplete="off" />
                    </mat-form-field>
                    <div class="loginActions">
                        <div class="authActions">
                            <button mat-raised-button class="login-button" type="submit">{{'login'|translate}}
                            </button>
                            <button mat-raised-button class="signup-button" routerLink="/signup/DailyBulkSupply">{{'sign
                                up'|translate}}
                            </button>
                        </div>
                        <div class="forgotPass">
                            <a mat-stroked-button routerLink="/forgotPassword">{{'forgot password'|translate}} ?</a>
                        </div>
                    </div>
                </form>
            </div>
            <div class="loginFooter">
                <div class="nizdaAhmedBox">
                    <div class="rowBanner r1">
                        <h1>
                            {{'get the app'|translate}}
                        </h1>
                        <h2>
                            {{'today'|translate}}
                        </h2>
                    </div>

                </div>
                <div class="playstoreLinks">
                    <div class="ln">
                        <a class="circle-link" title="Myapaar google playstore platform"
                            href="https://play.google.com/store/apps/details?id=com.myapaar.ang" target="_blank"
                            rel="noopener">
                            <img alt="playstore myapaar"
                                src="../../../assets/svgiconsandimages/playstore-svgrepo-com.svg"
                                alt="Myapaar google playstore platform" style="width:100%;">
                        </a>
                    </div>
                    <div class="fb">
                        <a class="circle-link" title="business profile google myapaar wholesale"
                            href="https://maps.app.goo.gl/ujLQMEyVx8hfE5e2A" target="_blank" rel="noopener">
                            <img alt="business profile myapaar"
                                src="../../../assets/svgiconsandimages/business-google-svgrepo-com.svg"
                                alt="Myapaar business profile google address maps" style="width:100%;">
                        </a>
                    </div>
                </div>
            </div>

        </mat-card>

    </div>

</div>