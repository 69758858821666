import { OpenTrade } from '../datamodels/contractOrders.ts/openpurchaseorder';
import { Injectable } from '@angular/core';
import { ListingModel } from '../datamodels/ListingModel';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, throwError, } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { DetailProductInfo } from '../datamodels/groupedmodel/productDetailInfoModel';
import { SocketServiceService } from './socket-service.service';
@Injectable({
  providedIn: 'root'
})
export class HandleTradesService {
  private listings: ListingModel[] = [];
  public allListings: ListingModel[] = [];
  private domain: string | undefined
  private listingsUpdate = new Subject<{ listings: ListingModel[], totalListings: number }>();
  private ProductNamelistingsUpdate = new Subject<{ listings: ListingModel[], totalListings: number }>();
  public createListingResponse: { message: string; createlisting: ListingModel; };
  singleListing: ListingModel;
  userlistings: ListingModel[]
  totalListings: number;
  myPBTrLoad: OpenTrade[] = []
  myAPTrLoad: OpenTrade[] = []
  constructor(
    private skts: SocketServiceService,
    private http: HttpClient, private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  // Listen for new trades
  onNewTrade(): Observable<any> {
    return new Observable((observer) => {
      this.skts.getSocket().on('new', (data) => {
        observer.next(data);
      });
    });
  }
  geUsPbTr(userId: string, loginAccounttype: string): Observable<OpenTrade[]> {
    const queryParams = `?userId=${userId}&loginAccounttype=${loginAccounttype}`;
    // const queryParams = `?pagesize=${userId}&pagesize=${listingsPerPage}&page=${currentPage}`;
    return new Observable(
      userTrdsbserver => {
        if (this.myPBTrLoad && this.myPBTrLoad.length > 0) {
          userTrdsbserver.next(this.myPBTrLoad)
          return userTrdsbserver.complete()
        }
        this.http.get<{
          message: 'Applied Trades',
          mtrPack: OpenTrade[];
          status: number
        }>(`${this.domain}api/tradeContractRoutes/gmpt` + queryParams)
          .subscribe((fetchedusTrs) => {
            this.myPBTrLoad = fetchedusTrs.mtrPack;
            userTrdsbserver.next(this.myPBTrLoad);
            userTrdsbserver.complete();
          });
      }
    )

  }

  geUsApTr(userId: string, loginAccounttype: string): Observable<OpenTrade[]> {
    const queryParams = `?userId=${userId}&loginAccounttype=${loginAccounttype}`;
    // const queryParams = `?pagesize=${userId}&pagesize=${listingsPerPage}&page=${currentPage}`;
    return new Observable(
      userTrdsbserver => {
        if (this.myAPTrLoad && this.myAPTrLoad.length > 0) {
          userTrdsbserver.next(this.myAPTrLoad)
          return userTrdsbserver.complete()
        }
        this.http.get<{
          message: 'Applied Trades',
          mtrPack: OpenTrade[];
          status: number
        }>(`${this.domain}api/tradeContractRoutes/gmat` + queryParams)
          .subscribe((fetchedusTrs) => {
            this.myAPTrLoad = fetchedusTrs.mtrPack;
            userTrdsbserver.next(this.myAPTrLoad);
            userTrdsbserver.complete();
          });
      }
    )

  }

  gtAlLivTr(userId: string, loginAccounttype: string): Observable<OpenTrade[]> {
    const queryParams = `?userId=${userId}&loginAccounttype=${loginAccounttype}`;
    // const queryParams = `?pagesize=${userId}&pagesize=${listingsPerPage}&page=${currentPage}`;
    return new Observable(
      userTrdsbserver => {
        if (this.myAPTrLoad && this.myAPTrLoad.length > 0) {
          userTrdsbserver.next(this.myAPTrLoad)
          return userTrdsbserver.complete()
        }
        this.http.get<{
          message: 'Applied Trades',
          mtrPack: OpenTrade[];
          status: number
        }>(`${this.domain}api/tradeContractRoutes/galt` + queryParams)
          .subscribe((fetchedusTrs) => {
            this.myAPTrLoad = fetchedusTrs.mtrPack;
            userTrdsbserver.next(this.myAPTrLoad);
            userTrdsbserver.complete();
          });
      }
    )

  }

  async newSupplyTrade(tradeForm: OpenTrade): Promise<{ message: string, addedTrade: OpenTrade, status: number }> {
    const tradeFormData = new FormData();
    //f0
    tradeFormData.append("contractDetails", JSON.stringify(tradeForm.contractDetails));
    tradeFormData.append("itemDetails", JSON.stringify(tradeForm.itemDetails));
    tradeFormData.append("sellerDetails", JSON.stringify(tradeForm.sellerDetails));
    tradeFormData.append("buyerDetails", JSON.stringify(tradeForm.buyerDetails));
    tradeFormData.append("sellerCertifications", JSON.stringify(tradeForm.sellerCertifications));
    tradeFormData.append("creditTerms", JSON.stringify(tradeForm.creditTerms));
    tradeFormData.append("requiredLocation", JSON.stringify(tradeForm.requiredLocation));
    // tradeFormData.append("listingCreatorID", listingCreatorID);
    //f1
    return this.http.post<{ message: string, addedTrade: OpenTrade, status: number }>(`${this.domain}api/tradeContractRoutes/newTrade`, tradeFormData)
      .toPromise()
  }
}
