<div class="notposted" *ngIf="!this.displayPostSuccess">
    <div class="isNewRequirement">
        <div class="banner">
            <div class="overlay">
                <h1 class="fade-in-text">Request Own Price</h1>
            </div>
        </div>
        <div class="fields">
            <mat-form-field appearance="outline">
                <input [(ngModel)]="this.itemQuantity" matInput type="tel" name="itemQuantity" placeholder="Quantity"
                    pattern="[0-9]*" maxlength="10" required>
                <mat-hint>Kg</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <input [(ngModel)]="this.targetPrice" matInput type="tel" name="targetPrice"
                    placeholder="Target Price in Kg" pattern="[0-9]*" maxlength="10" required>
                <mat-hint>₹ / Kg</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <input [(ngModel)]="this.phoneContact" matInput type="tel" name="phone" placeholder="Phone Number"
                    pattern="[0-9]*" maxlength="10" required>
                <mat-hint>98xxxxxxx9</mat-hint>
            </mat-form-field>
        </div>
        <button class="done" (click)="this.doneForm()" mat-stroked-button>Get Quote</button>
    </div>

</div>
<div class="posted" *ngIf="this.displayPostSuccess">
    <div class="banner">
        <div class="overlay">
            <h1 class="fade-in-text">Requirement Received</h1>
        </div>
    </div>
</div>