
import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Product } from '../../datamodels/MainProductModel';
import { ProductCategoryModel } from '../../datamodels/productcategory';
import { VarietyItems } from '../../datamodels/varietyModel';
import { ProductsdialogmanagerService } from '../../services/productsdialogmanager.service';
import { Subscription, Observable } from 'rxjs';
import { AllstatesService } from '../../services/allstates.service';
import { AuthService } from '../../auth/auth.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HandleListingsService } from '../../services/handle-listings.service';
import { ListingModel } from '../../datamodels/ListingModel';
import { ProductService } from '../../services/product.service';
import { ListingdialogmanagerServiceService } from 'src/app/services/listingdialogmanager-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductcategoryService } from 'src/app/services/productcategory.service';
import { VarietyServiceService } from 'src/app/services/variety-service.service';
import { CreditTermsModel } from 'src/app/datamodels/groupedmodel/creditTermsDetails';
import { ImageHandlingService } from 'src/app/services/image-handling.service';
import { PackagingTypeModel } from 'src/app/datamodels/groupedmodel/packagingTypeModel';
import { DetailProductInfo } from 'src/app/datamodels/groupedmodel/productDetailInfoModel';
import { UtilsService } from 'src/app/services/utils.service';
import { OpenTrade } from 'src/app/datamodels/contractOrders.ts/openpurchaseorder';
import { HandleTradesService } from 'src/app/services/handle-trades.service';
interface Ipackaging { type: string, pimg: string }
interface itemUnits {
  value: number;
  useUnit: string;
  useUnitShort: string;
}
@Component({
  selector: 'app-new-supply-trade',
  templateUrl: './new-supply-trade.component.html',
  styleUrls: ['./new-supply-trade.component.css']
})
export class NewSupplyTradeComponent implements OnInit {
  //formEssentials
  isLoading = false;
  sortexListing: boolean
  private listing: ListingModel;
  listingFormGenericDetails: FormGroup;
  //if clicked on create listing - mode becomes create
  //form 1 - category - product - has varieties ? if yes then enable varieties form, give variety price but not product item price (if only one variety- this is shown as product price, if 3 varieties, then range is given for product)
  listingFormOneItemDetails: FormGroup;
  mode = 'createListing';
  createListing: boolean = false;
  editListing: boolean = false;
  private listingId: string;
  productcategories: ProductCategoryModel[] = [];
  allproducts: Product[] = []
  varieties: VarietyItems[] = []
  IdsOfCategories: any[] = []
  //category value of dropdown
  selectedCategoryValue: string
  selectedProductValue: string;
  selectedVarietyValue: string;
  selectedPackaging: Ipackaging;
  //product quantity values
  bulkPriceValue: number
  productLotSizeValue: number = 5;
  productQuantityValue: number;
  //disable select while list reloads
  isfilterProductsLoading = false;
  isfilterVarietiesLoading = false;
  //if clicked on view old items- you can select an old item and edit a listing, then mode becomes edit - > example from post-create
  isVarietyAvailable: boolean;
  //dependent dropdown list
  private filteredProductSub: Subscription;
  filteredProducts: any
  private filteredVarietiesSub: Subscription;
  filteredVarieties: VarietyItems[]
  tempListHoldVar: VarietyItems[]
  //if stock available - can order asap , ifcomingsoon - notified when arrives and 2 days before - buyers can place bids on coming soon , transparent
  //form 2 - upload images 
  listingFormTwoImages: FormGroup;
  Uploadsuccessful: boolean;
  images: any;
  //form 3 - Address of pickup
  listingFormThreePickupAddress: FormGroup;
  pincodeFill: string;
  public storeStates;
  public stateHasCities;
  public CitiesList;
  filteredStates: Observable<string[]>;
  filteredCities: Observable<string[]>;
  public FilterCityFromStatesValue;
  // form 4 - contact information 
  //prefilledDataOfSellerContactInformation
  listingFormFourContact: FormGroup;
  isGSTAvailable: boolean;
  isPANAvailable: boolean;
  smallScreen: boolean;
  currentdate = new Date();
  companyName: string;
  itemDetailsForm: FormGroup;
  //login
  private loginUserInfo: any
  private authListenerSubs: Subscription;
  userIsAuthenticated = false;
  private loginUser: any;
  reviewImagePreview: string;
  createTradeResponse: any;
  editListingResponse: any;
  private currentUserId: string
  private selectedFilteredProductCategory: ProductCategoryModel
  private selectedFilteredProduct: Product
  private selectedFilteredVariety: VarietyItems
  private imageDataback: any;
  imagesUriArray: string[];
  private editListingData: any;
  isPackingImageAdded: boolean = false;
  logoImagePreview: string;
  allproductsnew: any;
  allvarietiesnew: any;
  selectedProduct: any;
  selectedPincode: string = '';
  pincodeFullInfo: any = [];
  stateName: string;
  districtName: string;
  localityName: string;
  pinLocalities: unknown[];
  allComplete: boolean = false;
  allpackagingComplete: boolean = false;
  disableCreditTerms: boolean = false;
  disablepackagingType: boolean = false;
  disablePersonalInfoChange: boolean = true;
  gstDetailsPending: boolean = false
  gstVerificationPending: boolean = false
  creditTerms: CreditTermsModel[] = [
    {
      value: 0, percentage: 0.01, viewValue: '1% Token', checked: false
      , hint: "Buyers create paid purchase order with 1% token amount & remaining on delivery after quality check"
    },
    {
      value: 0, percentage: 0.1, viewValue: '10% Token', checked: false
      , hint: "Buyers create paid purchase order with 1% token amount & remaining on delivery after quality check"
    },
    {
      value: 0, percentage: 0.13, viewValue: '13% Token', checked: false
      , hint: "Buyers create paid purchase order with 13% token amount & remaining on delivery after quality check"
    },
    {
      value: 0, percentage: 0.26, viewValue: '26% Token', checked: false
      , hint: "Buyers create paid purchase order with 26% token amount & remaining on delivery after quality check"
    },
    {
      value: 0, percentage: 0.52, viewValue: '52% Token', checked: false
      , hint: "Buyers create paid purchase order with 52% token amount & remaining on delivery after quality check"
    },
    {
      value: 0, percentage: 1, viewValue: '100% Prepaid', checked: false
      , hint: "Buyers create paid purchase order with 100% token amount - prepaid order."
    }];
  usePackagingType: PackagingTypeModel[] = [
    { value: 1, isBranded: false, checked: false, packagingType: 'Jute(Gunny) Bag', packagingImg: '../../../assets/svgiconsandimages/packaging/brandedsack-svgrepo-com.svg' },
    { value: 2, isBranded: false, checked: false, packagingType: 'Polypropylene(PP) Bag', packagingImg: '../../../assets/svgiconsandimages/packaging/plastic-sack.svg' },
    { value: 8, isBranded: false, checked: false, packagingType: 'Polypropylene(PP) Can', packagingImg: '../../../assets/svgiconsandimages/packaging/plastic-barrel-svgrepo-com.svg' },
    { value: 19, isBranded: false, checked: false, packagingType: 'Tinplate Can', packagingImg: '../../../assets/svgiconsandimages/packaging/metal-barrel-svgrepo-com.svg' },
    // { value: 3, isBranded: false, checked: false, packagingType: 'Net Sack', packagingImg: '../../../assets/svgiconsandimages/packaging/net-svgrepo-com.svg' },
    // { value: 4, isBranded: false, checked: false, packagingType: 'Wooden Crate', packagingImg: '../../../assets/svgiconsandimages/packaging/openwoodencrate.svg' },
    // { value: 6, isBranded: false, checked: false, packagingType: 'Metal Crate', packagingImg: '../../../assets/svgiconsandimages/packaging/sealed-steel-crate.svg' },
    // { value: 7, isBranded: false, checked: false, packagingType: 'Polypropylene(pp) Box', packagingImg: '../../../assets/svgiconsandimages/packaging/stitched-fiber-box.svg' },
    // { value: 9, isBranded: false, checked: false, packagingType: 'Metal Drum', packagingImg: '../../../assets/svgiconsandimages/packaging/metal-barrel-svgrepo-com.svg' },
    // { value: 11, isBranded: false, checked: false, packagingType: 'No-Brand Jute Sack', packagingImg: '../../../assets/svgiconsandimages/packaging/brandedsack-svgrepo-com.svg' },
    // { value: 12, isBranded: false, checked: false, packagingType: 'No-Brand Plastic Sack', packagingImg: '../../../assets/svgiconsandimages/packaging/plastic-sack.svg' },
    // { value: 13, isBranded: false, checked: false, packagingType: 'No-Brand Net Sack', packagingImg: '../../../assets/svgiconsandimages/packaging/net-svgrepo-com.svg' },
    // { value: 14, isBranded: false, checked: false, packagingType: 'No-Brand Open Wooden Crate', packagingImg: '../../../assets/svgiconsandimages/packaging/openwoodencrate.svg' },
    // { value: 15, isBranded: false, checked: false, packagingType: 'No-Brand Sealed Wooden Crate', packagingImg: '../../../assets/svgiconsandimages/packaging/sealed-wooden-crate.svg' },
    // { value: 16, isBranded: false, checked: false, packagingType: 'No-Brand Sealed Metal Crate', packagingImg: '../../../assets/svgiconsandimages/packaging/sealed-steel-crate.svg' },
    // { value: 17, isBranded: false, checked: false, packagingType: 'No-Brand Stitched Plastic/Fiber Box', packagingImg: '../../../assets/svgiconsandimages/packaging/stitched-fiber-box.svg' },
    // { value: 18, isBranded: false, checked: false, packagingType: 'No-Brand Plastic Drum', packagingImg: '../../../assets/svgiconsandimages/packaging/plastic-barrel-svgrepo-com.svg' },
    // { value: 20, isBranded: false, checked: false, packagingType: 'No-Brand Wooden Drum', packagingImg: '../../../assets/svgiconsandimages/packaging/wooden-barrel-svgrepo-com.svg' },
  ]
  // usePackagingType: PackagingTypeModel[] = [
  //   { value: 1, isBranded: false, checked: false, packagingType: 'Branded Jute Sack', packagingImg: '../../../assets/svgiconsandimages/packaging/brandedsack-svgrepo-com.svg' },
  //   { value: 2, isBranded: false, checked: false, packagingType: 'Branded Plastic Sack', packagingImg: '../../../assets/svgiconsandimages/packaging/plastic-sack.svg' },
  //   { value: 3, isBranded: false, checked: false, packagingType: 'Branded Net Sack', packagingImg: '../../../assets/svgiconsandimages/packaging/net-svgrepo-com.svg' },
  //   { value: 4, isBranded: false, checked: false, packagingType: 'Branded Open Wooden Crate', packagingImg: '../../../assets/svgiconsandimages/packaging/openwoodencrate.svg' },
  //   { value: 5, isBranded: false, checked: false, packagingType: 'Branded Sealed Wooden Crate', packagingImg: '../../../assets/svgiconsandimages/packaging/sealed-wooden-crate.svg' },
  //   { value: 6, isBranded: false, checked: false, packagingType: 'Branded Sealed Metal Crate', packagingImg: '../../../assets/svgiconsandimages/packaging/sealed-steel-crate.svg' },
  //   { value: 7, isBranded: false, checked: false, packagingType: 'Branded Stitched Plastic/Fiber Box', packagingImg: '../../../assets/svgiconsandimages/packaging/stitched-fiber-box.svg' },
  //   { value: 8, isBranded: false, checked: false, packagingType: 'Branded Plastic Drum', packagingImg: '../../../assets/svgiconsandimages/packaging/plastic-barrel-svgrepo-com.svg' },
  //   { value: 9, isBranded: false, checked: false, packagingType: 'Branded Metal Drum', packagingImg: '../../../assets/svgiconsandimages/packaging/metal-barrel-svgrepo-com.svg' },
  //   { value: 10, isBranded: false, checked: false, packagingType: 'Branded Wooden Drum', packagingImg: '../../../assets/svgiconsandimages/packaging/wooden-barrel-svgrepo-com.svg' },
  //   { value: 11, isBranded: false, checked: false, packagingType: 'No-Brand Jute Sack', packagingImg: '../../../assets/svgiconsandimages/packaging/brandedsack-svgrepo-com.svg' },
  //   { value: 12, isBranded: false, checked: false, packagingType: 'No-Brand Plastic Sack', packagingImg: '../../../assets/svgiconsandimages/packaging/plastic-sack.svg' },
  //   { value: 13, isBranded: false, checked: false, packagingType: 'No-Brand Net Sack', packagingImg: '../../../assets/svgiconsandimages/packaging/net-svgrepo-com.svg' },
  //   { value: 14, isBranded: false, checked: false, packagingType: 'No-Brand Open Wooden Crate', packagingImg: '../../../assets/svgiconsandimages/packaging/openwoodencrate.svg' },
  //   { value: 15, isBranded: false, checked: false, packagingType: 'No-Brand Sealed Wooden Crate', packagingImg: '../../../assets/svgiconsandimages/packaging/sealed-wooden-crate.svg' },
  //   { value: 16, isBranded: false, checked: false, packagingType: 'No-Brand Sealed Metal Crate', packagingImg: '../../../assets/svgiconsandimages/packaging/sealed-steel-crate.svg' },
  //   { value: 17, isBranded: false, checked: false, packagingType: 'No-Brand Stitched Plastic/Fiber Box', packagingImg: '../../../assets/svgiconsandimages/packaging/stitched-fiber-box.svg' },
  //   { value: 18, isBranded: false, checked: false, packagingType: 'No-Brand Plastic Drum', packagingImg: '../../../assets/svgiconsandimages/packaging/plastic-barrel-svgrepo-com.svg' },
  //   { value: 19, isBranded: false, checked: false, packagingType: 'No-Brand Metal Drum', packagingImg: '../../../assets/svgiconsandimages/packaging/metal-barrel-svgrepo-com.svg' },
  //   { value: 20, isBranded: false, checked: false, packagingType: 'No-Brand Wooden Drum', packagingImg: '../../../assets/svgiconsandimages/packaging/wooden-barrel-svgrepo-com.svg' },
  // ]
  preserverdTerms: CreditTermsModel[] = this.creditTerms
  preserverdPackaging: PackagingTypeModel[] = this.usePackagingType
  isImageLoading: boolean = false;
  isPKGImageLoading: boolean = false;
  noUploadMsg: string;
  previewListingID: string;
  useDetailType1: string;
  useDetailType2: string;
  useDetailType3: string;
  useDetailType4: string;
  useDetailType5: string;
  useMetricType1: string;
  useMetricType2: string;
  useMetricType3: string;
  useMetricType4: string;
  useMetricType5: string;
  usedetailInfoProduct: DetailProductInfo[];
  productPacketSize: number[];
  isProductDetailsAdd: boolean = false;
  disabledMultiImageUpload: boolean = false;
  allListingImages: string[] = []
  pkgImage: string;
  useListingUnit: string;
  useShortListingUnit: string;
  newBulkPrice: number;
  oldBulkPrice: number;
  priceUpdateDate: Date;
  useCategoryUnique: string;
  useProductName: string;
  allOpenInvoiceUnits: itemUnits[] = [{
    value: 1,
    useUnit: 'Packet',
    useUnitShort: 'Pkt.',
  },
  {
    value: 2,
    useUnit: 'Quintal',
    useUnitShort: 'Ql.',
  },
  {
    value: 3,
    useUnit: 'Metric Ton',
    useUnitShort: 'MT.',
  }]
  packagingSizes: number[] = []
  showFarmListing: boolean = false;
  showFactoryListing: boolean = false;
  showWholesaleListing: boolean = false;
  validationMessages = {

    //createListingModule
    //form one
    'title': {
      'required': 'Please enter SKU for your Product',
    },
    'categoryUnique': {
      'required': 'Please choose one category',
    },
    'productName': {
      'required': 'Product name is required',
    },

    'varietyName': {
      'required': 'Please select a variety from options',
    },
    'bulkPrice': {
      'required': 'Please enter price for bulk orders',
      'pattern': 'Only numbers are allowed'
    },
    'bulkQuantity': {
      'required': 'Please enter price for bulk orders',
      'pattern': 'Only numbers are allowed'
    },
    'packetSize': {
      'required': 'Enter Packet Size',
      'pattern': 'Only numbers are allowed',
    },
    'lotSize': {
      'required': 'Please enter minimum lot size that you wish to sell',
      'pattern': 'Only numbers are allowed'
    },
    'packagingType': {
      'required': 'Please add packaging type',
      'pattern': 'Only numbers are allowed'
    },
    //form two
    'imageUrls': {
      'required': 'Upload original photos of item'
    },
    //form three
    'creditTerms': {
      'required': 'Please enter Pin Code',
    },
    'pinCode': {
      'required': 'Please enter Pin Code',
      'pattern': 'Only numbers are allowed',
    },
    'state': {
      'required': 'Please select a State',
    },
    'city': {
      'required': 'Please select a City',
    },
    'addressStreet': {
      'required': 'Please give block or area name'
    },
    'addressBuildingNameAndFloor': {
      'required': 'Please give building name and floor number'
    },
    'landmark': {
      'required': 'Closest landmark to location'
    },
    //form four  
    // 'alternatePhoneContact': {
    //     'required': 'Choose one'
    // },
    'listingCreator': {
      'required': 'your full name is required',
      'minlength': 'Name should atleast be two characters long',
      'pattern': 'Only alphabets are allowed',
    },
    'GST_Id': {
      'required': 'GST for Managers is required',
    },
    'phone': {
      'required': 'Mobile number is required',
      'minlength': 'Atleast 10 digits required. ',
      'pattern': 'Only numbers are allowed',
    },
    'alternatePhoneContact': {

    },
    'email': {
      'required': 'Email is required',
      'email': 'Email not in valid format'
    }
  }

  //js object to display form errors
  formErrors = {
    'title': {
      'required': 'Please enter SKU for your Product',
    },
    'categoryUnique': '',
    'productName': '',
    'varietyName': 'Please select a variety from options',
    'bulkPrice': '',
    'bulkQuantity': '',
    'packetSize': '',
    'lotSize': '',
    'packagingType': '',
    'imageUrls': '',
    'creditTerms': '',
    'pinCode': '',
    'state': '',
    'city': '',
    'addressStreet': '',
    'addressBuildingNameAndFloor': '',
    'landmark': '',
    'listingCreator': '',
    'GST_Id': '',
    'phone': '',
    'alternatePhoneContact': '',
    'email': '',
  }
  constructor(
    private categoryService: ProductcategoryService,
    public pdms: ProductsdialogmanagerService,
    private productService: ProductService,
    private varietyService: VarietyServiceService,
    private stateService: AllstatesService,
    private authService: AuthService,
    public route: ActivatedRoute,
    public router: Router,
    private handlingListing: HandleListingsService,
    private handleTrds: HandleTradesService,
    public ldms: ListingdialogmanagerServiceService,
    private breakpointObserver: BreakpointObserver,
    private utils: UtilsService,
    private _snackBar: MatSnackBar,
    private imgHandler: ImageHandlingService
  ) {
    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(resultScreen => {
      this.smallScreen = resultScreen.matches;
    })
  }



  ngOnInit(): void {
    this.isLoading = true;//spinner starts
    this.loginUserInfo = this.authService.getUserLoginInfo();
    const accountSubtypes: string[] = this.loginUserInfo.accountSubtype
    this.enableListings(accountSubtypes)
    const accountType = this.loginUserInfo.accounttype
    if (accountType === 'Buyer') {
      this.router.navigate([`/`])
      return
    }
    this.currentUserId = this.loginUserInfo.userId
    this.isfilterProductsLoading = true; //load starts
    this.isfilterVarietiesLoading = true;
    this.itemDetailsForm = new FormGroup({
      items: new FormArray([
        new FormGroup({
          type: new FormControl(null, { validators: [Validators.required, Validators.minLength(1), Validators.maxLength(100)] }),
          size: new FormControl(null, { validators: [Validators.required, Validators.minLength(1)] })
        })
      ])
    })
    this.listingFormGenericDetails = new FormGroup({
      PublishDate: new FormControl(''),
      listingCreatorID: new FormControl(''),
      hsnCodeItem: new FormControl(''),
      taxSlab: new FormControl(0),
    })

    //formOne
    this.listingFormOneItemDetails = new FormGroup({
      title: new FormControl('', { validators: [Validators.required] }),
      categoryUnique: new FormControl('', { validators: [Validators.required] }),
      productUnique: new FormControl(''),
      //if variety available then productNormal and bulkprice are null
      isVarietyAvailable: new FormControl(false),
      //
      varietyUnique: new FormControl(''),
      bulkPrice: new FormControl('', { validators: [Validators.required] }),
      bulkQuantity: new FormControl('', { validators: [Validators.required] }),
      packetSize: new FormControl('', { validators: [Validators.required] }),
      lotSize: new FormControl('', { validators: [Validators.required] }),
      //add button to add new div that has variety details
    })
    //formTwo
    this.listingFormTwoImages = new FormGroup({
      imageUrls: new FormControl(null, {
        validators: [Validators.required]
      }),
      description: new FormControl(''),
    })
    //formThree
    this.listingFormThreePickupAddress = new FormGroup({
      //when pincode is put -> State -> city -> selected. can use Google Geocoding API, simple HTTP request with JSON response.
      pinCode: new FormControl('', { validators: [Validators.required] }),
      state: new FormControl('', { validators: [Validators.required] }),
      city: new FormControl('', { validators: [Validators.required] }),
      addressStreet: new FormControl('', { validators: [Validators.required] }),
      addressBuildingNameAndFloor: new FormControl('', { validators: [Validators.required] }),
      landmark: new FormControl(''),
    })

    //formFour
    this.listingFormFourContact = new FormGroup({
      listingCreator: new FormControl('', { validators: [Validators.required] }),
      //-----
      isGSTAvailable: new FormControl(false),
      //----
      GST_Id: new FormControl(''),
      //
      //
      phone: new FormControl('', { validators: [Validators.required, Validators.minLength(10), Validators.maxLength(10)] }),
      alternatePhoneContact: new FormControl('', { validators: [Validators.minLength(10), Validators.maxLength(10)] }),
      email: new FormControl('', { validators: [Validators.required, Validators.email] }),
      //shopAddress - same as pickupaddress option is given
    })
    this.listingFormOneItemDetails.valueChanges.subscribe(data => {
      this.onValueChanged(data)
    });
    this.onValueChanged();
    this.loginUserInfo.companyName == "" || this.loginUserInfo.companyName === null ? this.companyName = "Unregistered" : this.companyName = this.loginUserInfo.companyName
    if (this.loginUserInfo.GST_Id === '') {
      this.gstDetailsPending = true
    }
    if (this.loginUserInfo.isGSTVerified === false) {
      this.gstVerificationPending = true
      this.listingFormGenericDetails.disable()
      this.listingFormOneItemDetails.disable()
      this.listingFormTwoImages.disable()
      this.listingFormThreePickupAddress.disable()
    }
    this.getAllItems().then(
      ifAllitemsLoaded => {
        //form four is perosnal information and company information -> to edit this info the user must go to UseR-Profile page, 
        // it cannot be edited here or the purhchase order will have 2 seller names,  2 GST IDS etc, but it is fine to show the User Info here 
        this.listingFormFourContact.disable()
        this.route.paramMap.subscribe(async (paramMap: ParamMap) => {
          if (paramMap.has('listingId')) {
            //ListingId is in routes - editListing
            this.editListing = true;
            this.listingId = paramMap.get('listingId');
            this.mode = "editListing";
            this.handlingListing.getSingleListing(this.listingId).then(
              foundListing => {
                this.editListingData = foundListing
                if (this.editListingData) {
                  this.sortexListing = this.editListingData.isListingSortexType
                  this.setFormValuesOnEdit()
                }
              }
            )
          }
          else {
            this.mode = "createListing"
            this.sortexListing = undefined
            this.createListing = true;
            if (this.loginUserInfo.GST_Id) {
              this.isGSTAvailable = true;
            }
            this.listingId = null;
            this.listingFormThreePickupAddress.patchValue({
              'pinCode': this.loginUserInfo.pinCode || "",
              'state': this.loginUserInfo.state || "",
              'city': this.loginUserInfo.city || "",
              'addressBuildingNameAndFloor': this.loginUserInfo.addressBuildingNameAndFloor || "",
              'addressStreet': this.loginUserInfo.addressLocality || "",
              'landmark': this.loginUserInfo.landmark || "",
            })
            this.listingFormFourContact.patchValue({
              'listingCreator': this.loginUserInfo.firstname + ' ' + this.loginUserInfo.lastname,
              'GST_Id': this.loginUserInfo.GST_Id || "",
              'isGSTAvailable': this.loginUserInfo.isGSTAvailable || false,
              'phone': this.loginUserInfo.phone || null,
              'alternatePhoneContact': this.listingFormFourContact.value.alternatePhoneContact,
              'email': this.loginUserInfo.email || ""
            })
            this.selectedPincode = this.listingFormThreePickupAddress.value.pinCode
            this.stateName = this.listingFormThreePickupAddress.value.state
            this.districtName = this.listingFormThreePickupAddress.value.city
            this.localityName = this.listingFormThreePickupAddress.value.addressStreet
          }
        });
      }
    )
  }
  enableListings(accountSubtypes: string[]) {
    accountSubtypes.forEach(eachSubType => {
      if (eachSubType.includes('Farmer') ||
        eachSubType.includes('FPO') ||
        eachSubType.includes('FPC')) {
        this.showFarmListing = true
      }
      if (eachSubType.includes('Mill') ||
        eachSubType.includes('Manufacturer') ||
        eachSubType.includes('Factory') ||
        eachSubType.includes('Agro Processor') ||
        eachSubType.includes('Agent | Broker')

      ) {
        this.showFactoryListing = true
      }
      if (eachSubType.includes('Trader') ||
        eachSubType.includes('Wholesaler')
      ) {
        this.showWholesaleListing = true
      }
    })
  }
  addItem() {
    const items = <FormArray>this.itemDetailsForm.controls['items']
    items.push(
      new FormGroup({
        itemName: new FormControl(null, { validators: [Validators.required, Validators.minLength(1), Validators.maxLength(100)] }),
        unit: new FormControl(null, { validators: [Validators.required, Validators.minLength(1)] }),
        category: new FormControl(null, { validators: [Validators.required, Validators.minLength(1), Validators.maxLength(100)] }),
        type: new FormControl(null, { validators: [Validators.required, Validators.minLength(1), Validators.maxLength(100)] }),
        size: new FormControl(null, { validators: [Validators.required, Validators.minLength(1)] })
      })
    )
  }

  removeItem(index) {
    const items = <FormArray>this.itemDetailsForm.controls['items']
    items.removeAt(index)
  }

  selectedlistingType(sortexType: boolean) {
    try {
      if (sortexType) {
        this.sortexListing = true
        this.useListingUnit = 'Packet'
        this.useShortListingUnit = 'Pkt.'
        this.productLotSizeValue = 50
      }
      else {
        this.sortexListing = false
        this.useListingUnit = 'Quintal'
        this.useShortListingUnit = 'Ql.'
        this.productLotSizeValue = 250
      }
    }
    catch (e) {
      throw e
    }
  }
  onValueChanged(data?: any) {
    if (!this.listingFormOneItemDetails) {
      return;
    }
    const sform = this.listingFormOneItemDetails;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        //clear previous messages if any
        this.formErrors[field] = '';
        const control = sform.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              if (messages[key] === undefined) {
                messages[key] = ''
              }
              this.formErrors[field] += messages[key] + '';
            }
          }
        }
      }
    }
  }
  async getAllItems() {
    await this.loadProductCategories().then(
      async catsAre => {
        this.productcategories = catsAre
        if (this.productcategories && this.productcategories.length) {
          await this.loadProducts().then(async prodsAre => {
            this.allproducts = prodsAre
            if (this.allproducts && this.allproducts.length) {
              await this.loadVarieties().then(varsAre => {
                this.varieties = varsAre
                this.isLoading = false;
              }).catch(eVar => {
                throw eVar
              })
            }
          }).catch(eProd => {
            throw eProd
          })
        }
      }
    ).catch(eCat => {
      throw eCat
    })
  }

  //ProductCategory Api
  async loadProductCategories(): Promise<ProductCategoryModel[]> {
    try {
      const allCats = this.categoryService.getCategories().toPromise()
      return allCats
    }
    catch (e) {
      e
    }
  }

  async loadProducts(): Promise<Product[]> {
    try {
      const prodsAre = await this.productService.getProducts().toPromise()
      return prodsAre
    }
    catch (e) {
      e
    }
    this.isfilterProductsLoading = true
  }

  async loadVarieties(): Promise<VarietyItems[]> {
    try {
      const varsAre = await this.varietyService.getVarieties().toPromise()
      return varsAre
    }
    catch (e) {
      e
    }
  }

  filterProductsAndChooseCategory() {
    if (this.allproducts && this.selectedCategoryValue && this.selectedCategoryValue !== '' && this.selectedCategoryValue !== null && this.selectedCategoryValue !== undefined) {
      this.selectedFilteredProductCategory = this.productcategories.find((categories: ProductCategoryModel) => {
        return categories.categoryUnique === this.selectedCategoryValue
      })
      this.filteredProducts = this.allproducts.filter(
        allProds => {
          return allProds.categoryUnique === this.selectedCategoryValue
        }
      )
      this.isfilterProductsLoading = false
    }
    return
  }

  getItemsUniqueNames(categoryID: string, productID: string, varietyID: string) {
    let categoryUniq: string = ''
    let productUniq: string = ''
    let varietyUniq: string = ''
    this.selectedFilteredProductCategory = this.productcategories.find(category => {
      return category._id === categoryID
    })
    if (this.selectedFilteredProductCategory && this.selectedFilteredProductCategory != undefined && this.selectedFilteredProductCategory != null) {
      categoryUniq = this.selectedFilteredProductCategory.categoryUnique
    }
    this.selectedFilteredProduct = this.allproducts.find(product => {
      return product._id === productID
    })

    if (this.selectedFilteredProduct && this.selectedFilteredProduct != undefined && this.selectedFilteredProduct != null) {
      productUniq = this.selectedFilteredProduct.productUnique
    }
    this.selectedFilteredVariety = this.varieties.find(variety => {
      return variety._id === varietyID
    })
    if (this.selectedFilteredVariety && this.selectedFilteredVariety != undefined && this.selectedFilteredVariety != null) {
      varietyUniq = this.selectedFilteredVariety.varietyUnique
    }
    return {
      categoryUniq,
      productUniq,
      varietyUniq,
    }
  }

  filterVarietiesAndChooseProduct() {
    this.isfilterVarietiesLoading = true
    if (this.varieties && this.selectedProductValue && this.selectedProductValue !== '' && this.selectedProductValue !== null && this.selectedProductValue !== undefined) {
      this.selectedFilteredProduct = this.filteredProducts.find((prods: Product) => {
        return prods.productUnique === this.selectedProductValue
      })
      this.filteredVarieties = this.varieties.filter(
        allVarieties => {
          return allVarieties.productUnique === this.selectedProductValue
        }
      )
      this.isfilterVarietiesLoading = false
    }
    if (this.filteredVarieties && this.filteredVarieties.length === 0) {
      this.isVarietyAvailable = false
    }
    return
  }

  chooseFilteredVariety() {
    if (this.varieties && this.selectedVarietyValue && this.selectedVarietyValue !== '' && this.selectedVarietyValue !== null && this.selectedVarietyValue !== undefined) {
      this.selectedFilteredVariety = this.filteredVarieties.find((varieties: VarietyItems) => {
        return varieties.varietyUnique === this.selectedVarietyValue
      })
    }
  }
  updateAllComplete() {
    this.allComplete = this.creditTerms != null && this.creditTerms.every(t => t.checked);
    return
  }

  someComplete(): boolean {
    if (this.creditTerms == null) {
      return false;
    }
    if (this.mode === "editListing" && this.editListingData && this.editListing) {
      this.disableCreditTerms = true
      // return this.creditTerms.filter(t => t.checked).length > 0 && !this.allComplete;
    }
    this.creditTerms.filter(t => t.checked).length > 0 && !this.allComplete;
    return
  }
  setAll(checked: boolean) {
    this.allComplete = checked;
    if (this.creditTerms == null) {
      return;
    }
    this.creditTerms.forEach(t => (t.checked = true));
    return
  }

  updateAllpackagingComplete() {
    this.allpackagingComplete = this.usePackagingType != null && this.usePackagingType.every(t => t.checked);
    return
  }

  somepackagingComplete(): boolean {
    if (this.usePackagingType == null) {
      return false;
    }
    if (this.mode === "editListing" && this.editListingData && this.editListing) {
      this.disablepackagingType = true
      // return this.creditTerms.filter(t => t.checked).length > 0 && !this.allComplete;
    }
    this.usePackagingType.filter(t => t.checked).length > 0 && !this.allpackagingComplete;
    return
  }
  setpackagingAll(checked: boolean) {
    this.allComplete = checked;
    if (this.usePackagingType == null) {
      return;
    }
    this.usePackagingType.forEach(t => (t.checked = true));
    return
  }


  // async () {
  setFormValuesOnEdit() {
    // form 1 and form 2
    const itemsUniques = this.getItemsUniqueNames(this.editListingData.categoryId,
      this.editListingData.productId, this.editListingData.varietyId)
    this.isfilterVarietiesLoading = false; //var load stops
    //for editListing, give selectedCategoryValue already used value of the Listing, so that null vals dont load
    this.selectedCategoryValue = itemsUniques.categoryUniq
    this.selectedProductValue = itemsUniques.productUniq
    this.selectedVarietyValue = itemsUniques.varietyUniq
    this.bulkPriceValue = this.editListingData.bulkPrice
    this.productQuantityValue = this.editListingData.bulkQuantity
    this.productPacketSize = this.editListingData.packetSize
    this.productLotSizeValue = this.editListingData.lotSize
    this.isVarietyAvailable = this.editListingData.isVarietyAvailable
    // form 2
    this.editListingData.productDetailInfo.forEach((eachDetail: DetailProductInfo) => {
      if (eachDetail.value === 1) {
        this.useDetailType1 = eachDetail.type
        this.useMetricType1 = eachDetail.metric
      }
      if (eachDetail.value === 2) {
        this.useDetailType2 = eachDetail.type
        this.useMetricType2 = eachDetail.metric
      }
      if (eachDetail.value === 3) {
        this.useDetailType3 = eachDetail.type
        this.useMetricType3 = eachDetail.metric
      }
      if (eachDetail.value === 4) {
        this.useDetailType4 = eachDetail.type
        this.useMetricType4 = eachDetail.metric
      }
      if (eachDetail.value === 5) {
        this.useDetailType5 = eachDetail.type
        this.useMetricType5 = eachDetail.metric
      }
    })
    this.usedetailInfoProduct = this.editListingData.productDetailInfo
    //form3
    this.creditTerms = this.editListingData.creditTerms
    this.usePackagingType = this.editListingData.packagingType
    this.selectedPincode = this.editListingData.pinCode
    this.stateName = this.editListingData.state
    this.districtName = this.editListingData.city
    this.localityName = this.editListingData.addressStreet
    //form4
    this.isGSTAvailable = this.editListingData.isGSTAvailable
    this.isPANAvailable = this.editListingData.isPANAvailable
    this.listingFormGenericDetails.setValue({
      'listingCreatorID': this.editListingData.listingCreatorID,
      'PublishDate': this.editListingData.PublishDate,
      'hsnCodeItem': this.editListingData.hsnCodeItem,
      'taxSlab': this.editListingData.taxSlab,
    });
    this.listingFormOneItemDetails.setValue({
      'title': this.editListingData.title,
      'categoryUnique': this.selectedCategoryValue,
      'productUnique': this.selectedProductValue,
      'isVarietyAvailable': this.editListingData.isVarietyAvailable,
      'varietyUnique': this.selectedVarietyValue,
      'bulkPrice': this.editListingData.bulkPrice,
      'bulkQuantity': this.editListingData.bulkQuantity,
      'packetSize': this.editListingData.packetSize,
      'lotSize': this.editListingData.lotSize,
    });
    this.listingFormTwoImages.setValue({
      'imageUrls': this.editListingData.imageUrls,
      'description': this.editListingData.description,
    });
    this.listingFormThreePickupAddress.setValue({
      'pinCode': this.editListingData.pinCode,
      'state': this.editListingData.state,
      'city': this.editListingData.city,
      'addressBuildingNameAndFloor': this.editListingData.addressBuildingNameAndFloor,
      'addressStreet': this.editListingData.addressStreet,
      'landmark': this.editListingData.landmark,
    });
    this.listingFormFourContact.setValue({
      'listingCreator': this.editListingData.listingCreator,
      'GST_Id': this.editListingData.GST_Id,
      'isGSTAvailable': this.editListingData.isGSTAvailable,
      'phone': this.editListingData.phone,
      'alternatePhoneContact': this.editListingData.alternatePhoneContact || this.listingFormFourContact.value.alternatePhoneContact || '',
      'email': this.editListingData.email
    });
    // this.disablepackagingType = true
    // this.disableCreditTerms = true
  }

  onBrandingImagePicked(event: Event, type: string) {
    try {
      // this.listingFormTwoImages.markAsDirty();
      if (event) {
        const file = (event.target as HTMLInputElement).files[0];
        if (!file || file === undefined || file === null) {
          return
        }
        if (file && file !== undefined && file !== null) {
          const reader = new FileReader();
          reader.readAsDataURL(file)
          // this.imgHandler.compressSingle(file).subscribe(thisImg => {
          // })
          if (type === 'packageImage') {
            reader.onload = async () => {
              const useWidth = 620
              const useHeight = 620
              const checkResizedImg = await this.imgHandler.resizeSingleImage(reader.result as string, file.type, useWidth, useHeight)
              this.logoImagePreview = checkResizedImg;
              this.onBrandingImageUploadS3(this.logoImagePreview, file.name, file.type, type)
              this.setBrandingImageBG(this.logoImagePreview, type)
            };
          }
        }
      }
    }
    catch (e) {
      throw e
    }
  }
  setBrandingImageBG(imageURL: string, imageType: string) {
    const imgInBG = "url(" + imageURL + ")";
    if (imageType == 'packageImage') {
      const packageImageReviewDiv = document.getElementById('packageImageReview')
      packageImageReviewDiv.style.backgroundImage = imgInBG;
      packageImageReviewDiv.style.backgroundSize = '70%';
    }
  }
  checkPackageInfo(packtype: string, sizes: number[]) {
  }
  onBrandingImageUploadS3(dataURI: string, fileName: string, fileFormat: any, type: string) {
    try {
      this.isPKGImageLoading = true;
      const imageName = fileName.replace(/\s/g, '')
      const file = new File([this.utils.convertDataUrlToBlob(dataURI)], imageName, { type: fileFormat });
      this.authService.addLogoImageforUserProfile(file)
        .subscribe((ImagesPostResponse) => {
          // mat spinner stops when result comes
          this.isPKGImageLoading = false;
          if (ImagesPostResponse && ImagesPostResponse.status === "ok") {
            if (type === 'packageImage') {
              this.pkgImage = ImagesPostResponse.fileLocationS3
              this.allListingImages[0] = ImagesPostResponse.fileLocationS3
              // this.userInfoForm.patchValue({
              //   'logoImage': ImagesPostResponse.fileLocationS3
              // })
            }
          }
          else {
            //uploadimages unsuccessful,
            window.alert("Image Upload Failed, try again !")
          }
        })
      //set values for Variety and Description
    }
    catch (e) {
      throw e
    }

  }

  //form2 funcs
  async onImagesPicked(event: Event) {
    //type conversion to tell typescript that event.target registers an HtmlInp ,
    //  which typescript by default doesn't know
    this.isImageLoading = true;
    this.Uploadsuccessful = false;
    const pickedFiles = (event.target as HTMLInputElement).files;
    if (!pickedFiles || pickedFiles.length === 0) {
      this.noUploadMsg = 'Add 3 Images of Item';
      return;
    }
    this.images = pickedFiles
    const compFiles = await this.imgHandler.compressMultiple(this.images)
    if (compFiles) {
      this.onMultiImageUpload(compFiles)
    }
  }

  onMultiImageUpload(images: any) {
    this.handlingListing.addImagesforListing(images)
      .subscribe((ImagesPostResponse) => {
        // mat spinner stops when result comes
        this.isLoading = false;
        this.allListingImages = [...ImagesPostResponse.locationArray]
        this.imagesUriArray = ImagesPostResponse.locationArray
        this.listingFormTwoImages.value.imageUrls = this.allListingImages
        if (ImagesPostResponse.status == 'ok') {
          //uploadimages successful,
          this.Uploadsuccessful = true;
          this.isImageLoading = false;
          //patch
          this.listingFormTwoImages.patchValue({
            'imageUrls': this.listingFormTwoImages.value.imageUrls
          })
        }
      },
        error => {
          //uploadimages unsuccessful,
          this.Uploadsuccessful = false;
          this.isImageLoading = false;
          this.errorForm("Image upload fail - Invalid Format & Size")

        })
  }
  async getPincodeDetails() {
    try {
      if (this.selectedPincode.length == 6) {
        const pincodeInfo = await this.stateService.getPincodeDetails(this.selectedPincode)
        this.pincodeFullInfo = pincodeInfo.pinCodeInfo
        this.stateName = this.pincodeFullInfo[0].State
        this.districtName = this.pincodeFullInfo[0].District
        const localities = [...new Set(this.pincodeFullInfo.map((obj: any) => obj.Name))];
        this.pinLocalities = localities
        this.localityName = ""
      }
    }
    catch (e) {
      throw e
    }
  }

  patchFormValues() {
    try {
      this.usedetailInfoProduct = [
        { value: 1, type: this.useDetailType1, metric: this.useMetricType1 },
        { value: 2, type: this.useDetailType2, metric: this.useMetricType2 },
        { value: 3, type: this.useDetailType3, metric: this.useMetricType3 },
        { value: 4, type: this.useDetailType4, metric: this.useMetricType4 },
        { value: 5, type: this.useDetailType5, metric: this.useMetricType5 },
      ]
      if (this.mode === "createListing") {
        //for editListing, give selectedCategoryValue already used value of the Listing, so that null vals dont load
        this.creditTerms = this.creditTerms.filter(ct => {
          return ct.checked === true
        })
        this.usePackagingType = this.usePackagingType.filter(ct => {
          return ct.checked === true
        })

        // const listingForm: ListingModel = {
        //   _id: null,
        //   PublishDate: null,
        //   listingCreatorID: this.currentUserId,
        //   hsnCodeItem: this.selectedFilteredProduct?.hsnCodeItem || null,
        //   taxSlab: this.selectedFilteredProduct?.taxSlab || 5,
        //   //form1
        //   title: this.listingFormOneItemDetails.value.title,
        //   categoryId: this.selectedFilteredProductCategory._id,
        //   categoryName: this.selectedFilteredProductCategory.categoryName,
        //   categoryUnique: this.selectedFilteredProductCategory.categoryUnique,
        //   productId: this.selectedFilteredProduct._id,
        //   productName: this.selectedFilteredProduct.productName,
        //   varietyId: this.selectedFilteredVariety?._id || null,
        //   varietyName: this.selectedFilteredVariety?.varietyName || null,
        //   isVarietyAvailable: this.listingFormOneItemDetails.value.isVarietyAvailable || false,
        //   bulkPrice: this.listingFormOneItemDetails.value.bulkPrice,
        //   oldBulkPrice: this.listingFormOneItemDetails.value.bulkPrice,
        //   bulkPriceUpdateDate: null,
        //   isListingSortexType: this.sortexListing,
        //   useUnit: this.useListingUnit,
        //   useUnitShort: this.useShortListingUnit,
        //   bulkQuantity: this.listingFormOneItemDetails.value.bulkQuantity,
        //   packetSize: this.listingFormOneItemDetails.value.packetSize,
        //   lotSize: this.listingFormOneItemDetails.value.lotSize,
        //   packagingType: this.usePackagingType && this.usePackagingType.length > 0 ? this.usePackagingType : this.preserverdPackaging,
        //   //form2
        //   imageUrls: this.listingFormTwoImages.value.imageUrls,
        //   productDetailInfo: this.usedetailInfoProduct,
        //   description: this.listingFormTwoImages.value.description,
        //   //form3
        //   creditTerms: this.creditTerms && this.creditTerms.length > 0 ? this.creditTerms : this.preserverdTerms,
        //   pinCode: this.listingFormThreePickupAddress.value.pinCode,
        //   state: this.listingFormThreePickupAddress.value.state,
        //   city: this.listingFormThreePickupAddress.value.city,
        //   companyName: this.loginUserInfo.companyName,
        //   addressStreet: this.listingFormThreePickupAddress.value.addressStreet,
        //   addressBuildingNameAndFloor: this.listingFormThreePickupAddress.value.addressBuildingNameAndFloor,
        //   landmark: this.listingFormThreePickupAddress.value.landmark,
        //   //form4
        //   listingCreator: this.loginUserInfo.firstname + ' ' + this.loginUserInfo.lastname,
        //   GST_Id: this.listingFormFourContact.value.GST_Id,
        //   isGSTAvailable: this.listingFormFourContact.value.isGSTAvailable,
        //   phone: this.listingFormFourContact.value.phone,
        //   alternatePhoneContact: this.listingFormFourContact.value.alternatePhoneContact || null,
        //   email: this.listingFormFourContact.value.email
        // }
        const today = new Date()
        const openTrade: OpenTrade = {
          contractDetails: {
            contrId: null,
            contrCreatorId: this.currentUserId,
            contractType: 'Sell',
            contrCreatorName: this.loginUserInfo.firstname + ' ' + this.loginUserInfo.lastname,
            contrCreatorBizName : this.loginUserInfo.companyName,
            contractBasePrice: this.listingFormOneItemDetails.value.bulkPrice,
            contractQuantity: this.listingFormOneItemDetails.value.bulkQuantity,
            contractPublishDate: new Date(),
            contractValidTillDate: null,
            contractApplicants: []
          },
          itemDetails: {
            SKU: this.listingFormOneItemDetails.value.title,
            categoryId: this.selectedFilteredProductCategory._id,
            categoryName: this.selectedFilteredProductCategory.categoryName,
            categoryUnique: this.selectedFilteredProductCategory.categoryUnique,
            isVarietyAvailable: this.listingFormOneItemDetails.value.isVarietyAvailable || false,
            varietyName: this.selectedVarietyValue || null,
            productName: this.selectedFilteredProduct.productName,
            productId: this.selectedFilteredProduct._id,
            bulkPrice: this.listingFormOneItemDetails.value.bulkPrice,
            useUnit: this.useListingUnit,
            useUnitShort: this.useShortListingUnit,
            bulkQuantity: this.listingFormOneItemDetails.value.bulkQuantity,
            packetSize: this.productPacketSize,
            packagingType: this.usePackagingType && this.usePackagingType.length > 0 ? this.usePackagingType : this.preserverdPackaging,
            lotSize: this.listingFormOneItemDetails.value.lotSize,
            imageUrls: this.listingFormTwoImages.value.imageUrls,
            productDetailInfo: this.usedetailInfoProduct,
            description: this.listingFormTwoImages.value.description,
          },
          sellerDetails: {
            sellerId: this.currentUserId,
            sellerName: this.loginUserInfo.firstname + ' ' + this.loginUserInfo.lastname,
            sellerCompanyName: this.loginUserInfo.companyName,
            sellerCompanyType: this.loginUserInfo.accountSubtype,
            sellerContactDetails: {
              phone: this.loginUserInfo.phone,
              alternatePhoneContact: this.loginUserInfo.phone,
              email: this.loginUserInfo.email,
            },
            sellerGST: this.loginUserInfo.GST_Id,
          },
          buyerDetails: null,
          sellerCertifications: [],
          requiredLocation: {
            pinCode: this.listingFormThreePickupAddress.value.pinCode,
            state: this.listingFormThreePickupAddress.value.state,
            city: this.listingFormThreePickupAddress.value.city,
          },
          creditTerms: this.creditTerms && this.creditTerms.length > 0 ? this.creditTerms : this.preserverdTerms,
        }

        this.useCategoryUnique = openTrade.itemDetails.categoryUnique
        // this.useProductName = openTrade.itemDetails.productName
        return openTrade
      }
    }
    catch (e) {
      throw e
    }

  }

  //form funcs
  onSaveListing() {
    //set form values
    // this.listingFormGenericDetails.setValue = this.loginUserInfo.userId
    if (this.listingFormGenericDetails.invalid) {
      return this.errorForm('Missing Generic Details. Contact Sales Admin')
    }
    if (this.listingFormOneItemDetails.invalid) {
      return this.errorForm('Missing Item Details in form 1')
    }
    if (this.listingFormTwoImages.invalid) {
      return this.errorForm('Missing Images in form 2')
    }
    if (this.listingFormThreePickupAddress.invalid) {
      return this.errorForm('Missing Payment Terms and Pickup Details in Form 3')
    }
    if (this.listingFormFourContact.invalid) {
      return this.errorForm('Update business details in User Profile Section')
    }
    if (!this.listingFormFourContact.value.phone || this.listingFormFourContact.value.phone === null || this.listingFormFourContact.value.phone == "") {
      return this.errorForm("Update Phone Number in 'My Profile'")
    }
    const tradeForm = this.patchFormValues()
    this.isLoading = true;//spinner starts
    this.listingFormGenericDetails.value.PublishDate = this.currentdate
    if (this.mode === 'createListing') {
      if (this.listingFormGenericDetails.valid && this.listingFormOneItemDetails.valid
        && this.listingFormTwoImages.valid && this.listingFormThreePickupAddress.valid &&
        this.listingFormFourContact.valid) {
      }
      // return


      this.handleTrds.newSupplyTrade(tradeForm).then(tradeResponse => {
        this.createTradeResponse = tradeResponse
        const tradeId = this.createTradeResponse.addedTrade.id
        const contractId = this.createTradeResponse.addedTrade._doc.contractDetails.contrId
        if (this.createTradeResponse.status === 201) {
          this.router.navigate(['/MTR/MyTradeRoom/myTrades', tradeId, contractId])
          // this.updateProduct(this.selectedFilteredProduct._id, this.listingFormThreePickupAddress.value.state)
          // return
        }
      }).catch(err => {
        this.errorForm("Uplodate Listing Failed")
      });
    }
    // else {
    //   if (
    //     this.editListingData.bulkPrice !== this.listingFormOneItemDetails.value.bulkPrice
    //   ) {
    //     this.newBulkPrice = this.listingFormOneItemDetails.value.bulkPrice
    //     this.oldBulkPrice = this.editListingData.bulkPrice
    //     this.priceUpdateDate = new Date()
    //   }
    //   else {
    //     this.newBulkPrice = this.editListingData.bulkPrice
    //     this.oldBulkPrice = this.editListingData.bulkPrice
    //     this.priceUpdateDate = this.editListingData.PublishDate
    //   }
    //   this.handlingListing.updateListing(
    //     //listing Id additionally to update which wasnt there while creating
    //     this.listingId,
    //     //f0
    //     this.listingFormGenericDetails.value.listingCreatorID,
    //     this.listingFormGenericDetails.value.PublishDate,
    //     this.selectedFilteredProduct.hsnCodeItem,
    //     this.selectedFilteredProduct.taxSlab,
    //     // this.listingFormGenericDetails.value.listingCreatorID,
    //     //f1
    //     this.listingFormOneItemDetails.value.title,
    //     this.selectedFilteredProductCategory._id,
    //     this.selectedFilteredProductCategory.categoryName,
    //     this.selectedFilteredProductCategory.categoryUnique,
    //     this.selectedFilteredProduct._id,
    //     this.selectedFilteredProduct.productName,
    //     this.listingFormOneItemDetails.value.isVarietyAvailable === true ? this.selectedFilteredVariety?._id : '',
    //     this.listingFormOneItemDetails.value.isVarietyAvailable === true ? this.selectedFilteredVariety?.varietyName : '',
    //     this.listingFormOneItemDetails.value.isVarietyAvailable,
    //     this.newBulkPrice,
    //     this.listingFormOneItemDetails.value.bulkQuantity,
    //     this.oldBulkPrice,
    //     this.priceUpdateDate,
    //     this.sortexListing,
    //     this.editListingData.useUnit,
    //     this.editListingData.useUnitShort,
    //     this.listingFormOneItemDetails.value.packetSize,
    //     this.listingFormOneItemDetails.value.lotSize,
    //     this.usePackagingType,
    //     //f2
    //     this.listingFormTwoImages.value.imageUrls,
    //     this.listingFormTwoImages.value.description,
    //     this.usedetailInfoProduct,
    //     //f3
    //     this.editListingData.creditTerms,
    //     this.listingFormThreePickupAddress.value.pinCode,
    //     this.listingFormThreePickupAddress.value.state,
    //     this.listingFormThreePickupAddress.value.city,
    //     this.companyName,
    //     this.listingFormThreePickupAddress.value.addressStreet,
    //     this.listingFormThreePickupAddress.value.addressBuildingNameAndFloor,
    //     this.listingFormThreePickupAddress.value.landmark,
    //     //f4
    //     this.listingFormFourContact.value.listingCreator,
    //     this.listingFormFourContact.value.GST_Id,
    //     this.listingFormFourContact.value.isGSTAvailable,
    //     this.listingFormFourContact.value.phone,
    //     this.listingFormFourContact.value.alternatePhoneContact,
    //     this.listingFormFourContact.value.email
    //   ).then(updatetradeResponse => {
    //     this.editListingResponse = updatetradeResponse
    //     if (this.editListingResponse) {
    //       this.useCategoryUnique = this.selectedFilteredProductCategory.categoryUnique
    //       this.useProductName = this.selectedFilteredProduct.productName
    //       this.previewListingID = this.listingId
    //       //if new state is added to a product , product list updates with new state , if already exists, returns empty
    //       //if new state is added to a category , category list updates with new state , if already exists, returns empty
    //       this.updateProduct(this.selectedFilteredProduct._id, this.listingFormThreePickupAddress.value.state)
    //       // return
    //     }
    //   }).catch(err => {
    //     this.errorForm("Uplodate Listing Failed")
    //   })
    // }
  }

  updateProduct(productId: string, stateName: string) {
    this.productService.updateProductListing(productId, stateName).then(
      updatedProductStates => {
        if (updatedProductStates && updatedProductStates.status == 200 || updatedProductStates.status == 201) {
          this.updateCategory(this.selectedFilteredProductCategory._id, this.listingFormThreePickupAddress.value.state)
        }
      }
    )
  }
  updateCategory(categoryId: string, stateName: string) {
    this.categoryService.updateProductCategoryListing(categoryId, stateName).then(
      updatecategoryStates => {
        if (updatecategoryStates && updatecategoryStates.status === 200 || updatecategoryStates.status === 201) {
          this.router.navigate(['/Listing/Place_Bulk_Order', this.previewListingID, this.useCategoryUnique, this.useProductName]);
        }
      }
    )
  }
  changeUserInfo() {
    this.router.navigate([`accountInformation/${this.currentUserId}`])
  }
  errorForm(err: string) {
    const action = "Ok"
    try {
      this._snackBar.open(err, action, {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      this.isLoading = false
    }
    catch (e) {
      throw e
    }
  }
}
