<div [ngSwitch]="this.dataType">
    <div class="header row justify">
        <div mat-stroked-button class="addHelp">
            {{this.detailType}}
        </div>

        <mat-icon (click)="openLink($event)" class="clearIcon">clear</mat-icon>
    </div>
    <ng-template ngSwitchCase="contract">
        <app-view-contractor-profile [viewUserID]="this.contractorID">
        </app-view-contractor-profile>
    </ng-template>
    <ng-template ngSwitchCase="item">
        <app-view-contractor-item-details [contractItemDetails]="this.itemDetails">
        </app-view-contractor-item-details>
    </ng-template>
</div>