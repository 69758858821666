<div class="wrapper">
    <div class="finalSelect">
        <form [formGroup]="addListingToCart">
            <div class="optionRow">
                <div class="buttonSetItem ctSelected">
                    <mat-form-field class="limitwidth" appearance="outline">
                        <mat-hint style="color: #37b83e;font-size: 13px;"
                            *ngIf="this.selectedCreditDays.viewValue=='Pay Later'">
                            Create Instant PO & Pay Later</mat-hint>
                        <mat-hint style="color: #37b83e;font-size: 13px;"
                            *ngIf="this.selectedCreditDays.viewValue=='Cash and Carry'">
                            Prepaid Order</mat-hint>
                        <mat-hint style="color: #37b83e;font-size: 13px;"
                            *ngIf="this.selectedCreditDays.viewValue=='Check and Pay on delivery'">
                            Pay 30% on checkout & 70 % on Delivery</mat-hint>
                        <mat-hint style="color: #37b83e;font-size: 13px;"
                            *ngIf="this.selectedCreditDays.viewValue!='Pay Later'
                                                                && this.selectedCreditDays.viewValue!='Cash and Carry'
                                                                && this.selectedCreditDays.viewValue!='Check and Pay on delivery'">
                            Pay Later with interest</mat-hint>
                        <mat-label>Payment Mode</mat-label>
                        <mat-error *ngIf="formErrors.creditTerms">{{formErrors.creditTerms}}</mat-error>
                        <mat-select [(ngModel)]="selectedCreditDays" formControlName="creditTerms" name="creditTerms"
                            required>
                            <mat-option *ngFor="let terms of this.currentListingwithId.creditTerms" [value]="terms">
                                {{terms.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="!this.bulkOrderdata.currentOrder.listingSortexType" class="buttonSetItem packInfo">
                    <mat-form-field class="limitwidth" appearance="outline">
                        <mat-hint>Packaging Type</mat-hint>
                        <mat-label>Select Packaging</mat-label>
                        <mat-error *ngIf="formErrors.packagingType">{{formErrors.packagingType}}</mat-error>
                        <mat-select (ngModelChange)="packagingChange($event)" [(ngModel)]="selectedPackagingType"
                            formControlName="packagingType" name="packagingType" required>
                            <mat-option *ngFor="let packtype of this.currentListingwithId.packagingType"
                                [value]="packtype">
                                {{packtype.packagingType}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="this.isAddBrandingSection" class="buttonSetItem brandingImage">
                    <button class="brandingButton" mat-stroked-button (click)="filePicker.click()"> Add Branding
                        Image</button>
                    <mat-hint class="logoImage"> Image and design for
                        packaging</mat-hint>
                    <input type="file" #filePicker (change)="onImagePicked($event)">
                </div>
            </div>
            <div class="action">
                <button (click)="this.addBulkItem()" mat-raised-button>
                    Done
                </button>
            </div>
        </form>

    </div>

</div>