import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/datamodels/MainProductModel';
import { ProductService } from 'src/app/services/product.service';
import { Router } from '@angular/router';
interface LoadingObj {
  quote: string; quotedBy: string
}

@Component({
  selector: 'app-landingproducts',
  templateUrl: './landingproducts.component.html',
  styleUrls: ['./landingproducts.component.css']
})

export class LandingproductsComponent implements OnInit {
  @Input() appBannerslandingproducts: string[];

  quotes = [
    { quote: "dream big, act boldly, succeed.", quotedBy: "ratan tata" },
    { quote: "innovation distinguishes leaders from followers.", quotedBy: "narendra modi" },
    { quote: "hard work beats talent, always.", quotedBy: "dhirubhai ambani" },
    { quote: "fail fast, learn fast, succeed.", quotedBy: "narayana murthy" },
    { quote: "believe in yourself and persist.", quotedBy: "azim premji" },
    { quote: "success requires relentless pursuit and perseverance.", quotedBy: "mukesh ambani" },
    { quote: "your vision is your power.", quotedBy: "sundar pichai" },
    { quote: "adaptability is the key to survival.", quotedBy: "kiran mazumdar-shaw" },
    { quote: "think big, start small, scale.", quotedBy: "cyrus mistry" },
    { quote: "integrity is the foundation of leadership.", quotedBy: "uday kotak" },
    { quote: "focus on solutions, not problems.", quotedBy: "rajeev chandrasekhar" },
    { quote: "passion drives excellence in everything.", quotedBy: "vineet nayar" },
    { quote: "embrace change, drive innovation forward.", quotedBy: "kumar mangalam birla" }
  ];

  selectedOBJ: LoadingObj;
  allnonfeaturedproducts: Product[] = [];
  nonfeaturedProd: Product;
  featureTrue: boolean;
  toggleProperty = false;
  holdingarray: Product[] = [];
  isLoading = false; //spinner stops
  constructor(public productService: ProductService,
    private router: Router) { }

  ngOnInit(): void {
    this.LoadNonFeaturedProducts();
    this.selectedOBJ = this.quotes[Math.floor(Math.random() * this.quotes.length)];
  }
  toggle() {
    this.toggleProperty = !this.toggleProperty;
  }
  LoadNonFeaturedProducts() {
    this.isLoading = true; //spinner starts
    this.productService.getProducts().subscribe((res) => {
      this.isLoading = false; //spinner stops
      // normal display when all products have listings
      // this.allnonfeaturedproducts = res

      // bhari dukan to show products with listings available only
      const populatedProducts = res.filter(allProds => {
        return allProds.productState && allProds.productState.length >= 1
      })

      const prodNamesarray = res.map(allProds => {
        return allProds.productName
      })
      this.allnonfeaturedproducts = populatedProducts.sort((a, b) => b.productState.length - a.productState.length);
      //removeBelow and replace with above liner
    })

  }

  onSelectProduct(productName: string) {
    this.router.navigate(['/showListingProduct', productName])
  }
}
