<div class="content">
    <div class="banner">
        <div class="overlay">
            <h1 class="fade-in-text">eKYC Document ✔</h1>
        </div>
    </div>
    <div class="signUpBox">
        <mat-card>
            <mat-spinner *ngIf="isLoading"></mat-spinner>
                <div class="conditionalRequired">
                    <div class="imgup onim">
                        <div id="packageImageReview">
                        </div>
                        <button mat-stroked-button color="primary" class="imagePickerbutton"
                            (click)="PckgfilePicker.click()">
                            <span *ngIf="!this.pdfPicked">Upload GST Document</span>
                            <div>
                                <span *ngIf="this.pdfPicked">Picked</span>
                                <mat-spinner *ngIf="pdfPickLoading" diameter="20"></mat-spinner>
                            </div>
                        </button>
                        <!-- <mat-error *ngIf="!this.images">{{this.noUploadMsg}}</mat-error> -->
                        <input hidden #PckgfilePicker type='file' name='images'
                            (change)="gstDocPicked()" />
                        <!-- <button *ngIf="!isLoading" class="imageUploaderbutton"
                    [disabled]="!this.images || this.images.length==undefined || this.images.length==0 " mat-raised-button
                    color="warn" (click)="onMultiImageUpload()"><span>Upload </span> <span *ngIf="this.Uploadsuccessful"
                      class="uploadSuccess">Successful !</span></button> -->
                    </div>
                </div>
                <div class="actions">
                    <div class="back">
                        <button raised-button type="submit" mat-raised-button>Sign Up</button>
                    </div>
                </div>
        </mat-card>
    </div>
</div>