<div class="contentLandingProducts">
    <div class="bannerData">
        <div routerLink="/viewItems/All" class="image">
            <!-- <img [src]=" this.currentListingwithId.imageUrls[0]" alt=""> -->
            <div class="img-slider">
                <div class="slide">
                    <mat-carousel class="mat-carousel" timings="300ms ease-in" [autoplay]="true" interval="4000"
                        color="accent" [loop]="true" maxWidth="auto" [hideIndicators]="true" [hideArrows]="true"
                        [useKeyboard]="true" [maintainAspectRatio]="false" orientation="ltr">
                        <mat-carousel-slide *ngFor="let slide of this.appBannerslandingproducts; let i = index"
                            [image]="slide" overlayColor="rgba(0, 0, 0, 0.1)" [hideOverlay]="true"></mat-carousel-slide>
                    </mat-carousel>
                </div>
            </div>
            <div class="viewProductbutton">
                <div class="viewall" routerLink="/viewItems/All">{{this.selectedOBJ.quote | translate}} <br>
                    ~{{this.selectedOBJ.quotedBy | translate}}</div>
            </div>
        </div>

        <div *ngIf="isLoading" class="content-loading">
            <div id="loadingScreen">
                <div class="loadingText">
                    <h1 class="loadingTitle">Place orders in Bulk </h1>
                    <h3 class="loadingSubtitle">Pay Later</h3>
                </div>
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <div class="productsOverlay" *ngIf="!isLoading && this.LoadNonFeaturedProducts">
            <div class="products">
                <div class="product-card" *ngFor="let product of this.allnonfeaturedproducts |  slice:0:4; let i=index">
                    <div class="singleCards">
                        <!-- <img mat-card-image src="https://ik.imagekit.io/ikmedia/backlit.jpg"> -->
                        <div (click)="onSelectProduct(product.productName)" class="image_wrapper">
                            <img [src]="product.productImage[0]" alt="product.productImage[0]" />
                            <div class=" overlay overlay_1">
                                <div class="productname">
                                    <p>
                                        {{product.productName | translate}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="products">
                <div class="product-card" *ngFor="let product of this.allnonfeaturedproducts |  slice:4:8; let i=index">
                    <div class="singleCards">
                        <!-- <img mat-card-image src="https://ik.imagekit.io/ikmedia/backlit.jpg"> -->
                        <div (click)="onSelectProduct(product.productName)" class="image_wrapper">
                            <img [src]="product.productImage[0]" alt="product.productImage[0]" />
                            <div class=" overlay overlay_1">
                                <div class="productname">
                                    <p>
                                        {{product.productName | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>