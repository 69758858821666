import { Injectable, OnChanges, SimpleChanges } from '@angular/core';
import * as io from 'socket.io-client';
import { Socket } from 'socket.io-client';
import { SettingsService } from './settings.service';
import { Observable } from 'rxjs';
import { OpenTrade } from '../datamodels/contractOrders.ts/openpurchaseorder';
@Injectable({
  providedIn: 'root'
})
export class SocketServiceService {
  private skt: Socket;
  private domain: string | undefined
  constructor(
    private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  initializeSocket(): void {
    if (!this.skt) {
      this.skt = io(this.domain); // Replace with your server's URL
    }
    this.skt.on('connect_error', function (socket) {
      socket.emit('error');
    });
    this.skt.on('connection', function (socket) {
      socket.emit('create_socket');
    });
    this.skt.on('Hello', (data) => {
      console.log('sockDaa', data)
    });
    this.skt.on('new', (data: OpenTrade) => {
      console.log('sockDaa', data)
    });
  }

  getSocket(): Socket {
    return this.skt;
  }
  // Emit an event to the server (e.g., 'createTrade')
  emitEvent(event: string, data: any): void {
    if (this.skt) {
      this.skt.emit(event, data);
    }
  }

  onEvent<T>(event: string): Observable<T> {
    return new Observable((observer) => {
      if (this.skt) {
        this.skt.on(event, (data: T) => {
          observer.next(data);
        });

        // Cleanup when the observer unsubscribes
        return () => {
          this.skt.off(event);
        };
      }
    });
  }
}
