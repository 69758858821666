import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BidRequest } from 'src/app/datamodels/bidnegotiateRequestModel';
import { BidRateHandlingService } from 'src/app/services/bid-rate-handling.service';
import { BidfilterdialogmanagerService } from 'src/app/services/bidfilterdialogmanager.service';
import { Sort } from '@angular/material/sort';
import { AuthService } from 'src/app/auth/auth.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-viewbids',
  templateUrl: './viewbids.component.html',
  styleUrls: ['./viewbids.component.css']
})
export class ViewbidsComponent implements OnInit {
  isLoading = false;
  private bidWriter: string
  public currentUserBids: BidRequest[] = []
  private bidsForUserListing: any
  priceChange: number
  private updateBidRequestResponseCancel: any;
  private updateBidRequestResponseApprove: any;
  bidRequestFormDetails: FormGroup;
  private bidId: string;
  private filteredProducts: any[]
  private filteredStatus: any[]
  sendBidsforFilterAndSort: any;
  isFilterApplied: boolean = false
  panelOpenState = false;
  sortBidData: BidRequest[];
  isSellerCheckingBids: boolean = false
  isSellerCreatedBids: boolean = false
  private loginUserInfo: any
  private currentUserId: string
  bidsOfBuyerPlaced: BidRequest[];
  bidsOfSellerPlaced: BidRequest[];
  bidsOfSellerReceived: BidRequest[];
  companyNameSeller: string;
  businessName: string;
  constructor(
    private util: UtilsService,
    private router: Router,
    private authService: AuthService,
    public route: ActivatedRoute,
    private bidrequesthandle: BidRateHandlingService,
    private bidfilterhandle: BidfilterdialogmanagerService
  ) { }
  // convert string to number all places where prices are used 
  ngOnInit() {
    try {
      this.isLoading = true;
      this.loginUserInfo = this.authService.getUserLoginInfo();
      const accountType = this.loginUserInfo.accounttype
      if (accountType === 'Seller') {
        this.isSellerCheckingBids = true
      }
      this.currentUserId = this.loginUserInfo.userId
      this.companyNameSeller = this.loginUserInfo.companyName
      this.businessName = this.util.getBusinessNameForURL(this.companyNameSeller)

      this.bidRequestFormDetails = new FormGroup({
        isBid: new FormControl(null, { validators: [Validators.required] }),
        isDiscount: new FormControl(null, { validators: [Validators.required] }),
        isRateSelected: new FormControl(null, { validators: [Validators.required] }),
        bidRate: new FormControl(null, { validators: [Validators.required] }),
        quantityValue: new FormControl(null, { validators: [Validators.required] }),
        requestStatus: new FormControl(null, { validators: [Validators.required] }),
        bidCreator: new FormControl(null, { validators: [Validators.required] }),
        listingId: new FormControl(null, { validators: [Validators.required] }),
        listingProduct: new FormControl(null, { validators: [Validators.required] }),
        listingTitle: new FormControl(null, { validators: [Validators.required] }),
        imageUrls: new FormControl([], { validators: [Validators.required] }),
        newPriceOffered: new FormControl(null, { validators: [Validators.required] }),
        isPending: new FormControl(null, { validators: [Validators.required] }),
        isApproved: new FormControl(null, { validators: [Validators.required] }),
        isCanceled: new FormControl(null, { validators: [Validators.required] }),
        oldPriceListed: new FormControl(null, { validators: [Validators.required] }),
        bidWriter: new FormControl(null, { validators: [Validators.required] }),
        bidCreatorName: new FormControl(null, { validators: [Validators.required] }),
        bidWriterName: new FormControl(null, { validators: [Validators.required] }),
        bidCreateDate: new FormControl(null),
        bidActionDate: new FormControl(null)

      })
      this.bidWriter = this.route.snapshot.params.listingCreatorId;
      console.log('see id on load', this.bidWriter)
      this.getCurrentUserBids(this.currentUserId, this.loginUserInfo.accounttype)
      // this.filterBidsforStatusandProduct(this.bidWriter)

      // this.bidId=this.currentUserBids.id
    }
    catch (e) { }
  }

  async getCurrentUserBids(currrentUser: string, accountType: string) {
    try {
      this.bidrequesthandle.getUserOnlyBidRequest(currrentUser, accountType).then(
        userBids => {
          if (userBids && userBids.status == 200) {
            this.bidsOfBuyerPlaced = userBids.bidsbuyerPlaced
            this.bidsOfSellerPlaced = userBids.bidssellerPlaced
            this.bidsOfSellerReceived = userBids.bidssellerReceived
            this.bidsForUserListing = userBids
            this.currentUserBids = this.bidsForUserListing.userBids
            this.sendBidsforFilterAndSort = this.bidsForUserListing.userBids
            this.sortBidData = this.bidsForUserListing.userBids
            this.isLoading = false;
            console.log('usr bids for writer', this.currentUserBids)
          }
        }
      )
    }
    catch (e) {
      throw e
    }


  }
  // async filterBidsforStatusandProduct(bidWriter: string) {
  //   this.bidsForUserListing = await this.bidrequesthandle.getUserOnlyBidRequest(bidWriter)
  //   this.sendBidsforFilter = this.bidsForUserListing.userBids
  //   this.isLoading = false;
  //   console.log('usr bids for writer', this.sendBidsforFilter)
  // }
  async cancelBid(cancelBidObj: any) {
    this.isLoading = true;

    try {
      //take bid Id of canceled bid to send and replace in put method
      this.bidId = cancelBidObj._id

      if (cancelBidObj && cancelBidObj.isPending) {
        console.log('CancelthisBid', cancelBidObj)
        this.bidRequestFormDetails.setValue({
          'isBid': cancelBidObj.isBid,
          'isDiscount': cancelBidObj.isDiscount,
          'isRateSelected': cancelBidObj.isRateSelected,
          'bidRate': cancelBidObj.bidRate,
          'quantityValue': cancelBidObj.quantityValue,
          'requestStatus': 'Cancelled',
          'bidCreator': cancelBidObj.bidCreator,
          'listingId': cancelBidObj.listingId,
          'listingProduct': cancelBidObj.listingProduct,
          'listingTitle': cancelBidObj.listingTitle,
          'imageUrls': cancelBidObj.imageUrls,
          'newPriceOffered': cancelBidObj.newPriceOffered,
          'oldPriceListed': cancelBidObj.oldPriceListed,
          'isPending': false,
          'isApproved': false,
          'isCanceled': true,
          'bidWriter': cancelBidObj.bidWriter,
          'bidCreatorName': cancelBidObj.bidCreatorName,
          'bidWriterName': cancelBidObj.bidWriterName,
          'bidCreateDate': cancelBidObj.bidCreateDate,
          'bidActionDate': cancelBidObj.bidActionDate,

        })
        console.log('checkFormValNewCancel', this.bidRequestFormDetails.value)
        const bidOrderValue = cancelBidObj.bidOrderValueWithoutTaxes
        this.bidrequesthandle.updateBidRequestCancel(
          this.bidId,
          this.bidRequestFormDetails.value.listingId,
          this.bidRequestFormDetails.value.bidWriter,
          this.bidRequestFormDetails.value.listingProduct,
          this.bidRequestFormDetails.value.imageUrls,
          this.bidRequestFormDetails.value.isDiscount,
          this.bidRequestFormDetails.value.isBid,
          this.bidRequestFormDetails.value.isRateSelected,
          this.bidRequestFormDetails.value.bidRate,
          this.bidRequestFormDetails.value.quantityValue,
          bidOrderValue,
          this.bidRequestFormDetails.value.bidCreator,
          this.bidRequestFormDetails.value.newPriceOffered,
          this.bidRequestFormDetails.value.listingTitle,
          this.bidRequestFormDetails.value.isPending,
          this.bidRequestFormDetails.value.isApproved,
          this.bidRequestFormDetails.value.isCanceled,
          this.bidRequestFormDetails.value.oldPriceListed,
          this.bidRequestFormDetails.value.requestStatus,
          this.bidRequestFormDetails.value.bidCreatorName,
          this.bidRequestFormDetails.value.bidWriterName,
          this.bidRequestFormDetails.value.bidCreateDate,
          this.bidRequestFormDetails.value.bidActionDate,

        ).subscribe(cancelledBidResponse => {
          this.updateBidRequestResponseApprove = cancelledBidResponse
          console.log('CanceleD Bid', this.updateBidRequestResponseCancel)

          this.getCurrentUserBids(this.bidWriter, this.loginUserInfo.accountType)
        })

      }
    }
    catch (e) {

    }
  }
  async approveBid(approveBidObj: any) {
    this.isLoading = true;
    try {
      this.bidId = approveBidObj._id
      if (approveBidObj && approveBidObj.isPending) {
        console.log('ApprovethisBid', approveBidObj, this.bidId)
        this.bidRequestFormDetails.setValue({
          'isBid': approveBidObj.isBid,
          'isDiscount': approveBidObj.isDiscount,
          'isRateSelected': approveBidObj.isRateSelected,
          'bidRate': approveBidObj.bidRate,
          'quantityValue': approveBidObj.quantityValue,
          'requestStatus': 'Approved',
          'bidCreator': approveBidObj.bidCreator,
          'listingId': approveBidObj.listingId,
          'listingProduct': approveBidObj.listingProduct,
          'listingTitle': approveBidObj.listingTitle,
          'imageUrls': approveBidObj.imageUrls,
          'newPriceOffered': approveBidObj.newPriceOffered,
          'oldPriceListed': approveBidObj.oldPriceListed,
          'isPending': false,
          'isApproved': true,
          'isCanceled': false,
          'bidWriter': approveBidObj.bidWriter,
          'bidCreatorName': approveBidObj.bidCreatorName,
          'bidWriterName': approveBidObj.bidWriterName,
          'bidCreateDate': approveBidObj.bidCreateDate,
          'bidActionDate': approveBidObj.bidActionDate,
        })
        console.log('checkFormValNewApprov', this.bidRequestFormDetails.value)
        const bidOrderValue = approveBidObj.bidOrderValueWithoutTaxes
        this.bidrequesthandle.updateBidRequestApprove(
          this.bidId,
          this.bidRequestFormDetails.value.listingId,
          this.bidRequestFormDetails.value.bidWriter,
          this.bidRequestFormDetails.value.listingProduct,
          this.bidRequestFormDetails.value.imageUrls,
          this.bidRequestFormDetails.value.isDiscount,
          this.bidRequestFormDetails.value.isBid,
          this.bidRequestFormDetails.value.isRateSelected,
          this.bidRequestFormDetails.value.bidRate,
          this.bidRequestFormDetails.value.quantityValue,
          bidOrderValue,
          this.bidRequestFormDetails.value.bidCreator,
          this.bidRequestFormDetails.value.newPriceOffered,
          this.bidRequestFormDetails.value.listingTitle,
          this.bidRequestFormDetails.value.isPending,
          this.bidRequestFormDetails.value.isApproved,
          this.bidRequestFormDetails.value.isCanceled,
          this.bidRequestFormDetails.value.oldPriceListed,
          this.bidRequestFormDetails.value.requestStatus,
          this.bidRequestFormDetails.value.bidCreatorName,
          this.bidRequestFormDetails.value.bidWriterName,
          this.bidRequestFormDetails.value.bidCreateDate,
          this.bidRequestFormDetails.value.bidActionDate,
        ).subscribe(approveResponse => {
          this.updateBidRequestResponseApprove = approveResponse
          console.log('ApproveD Bid', this.updateBidRequestResponseApprove)
          this.getCurrentUserBids(this.bidWriter, this.loginUserInfo.accountType)
        })
      }
    }
    catch (e) {

    }
  }

  filterBids() {
    const currentUserBidstoFilter = this.sendBidsforFilterAndSort
    //find unique product names from array
    const uniqueProducts = [...new Set(currentUserBidstoFilter.map(item => item.listingProduct))]
    //conver unique product name strings from array to array object - with id and name 
    // This is done because we want to append another key-value of checked, and this can only be done to array Objects, not array strings
    let uniqueProductsFilter = uniqueProducts.map((value, index) => {
      return {
        id: index + 1,
        productName: value
      }
    }).map(ch => ({
      //we will need isChecked property at time of filter
      ...ch, checked: false
    }));
    console.log('filter unique product ob', uniqueProductsFilter)
    //find unique request status from array
    const requestStatus = [...new Set(currentUserBidstoFilter.map(status => status.requestStatus))];
    //conver unique product name strings from array to array object - with id and name 
    // This is done because we want to append another key-value of checked, and this can only be done to array Objects, not array strings
    let uniqueRequestStatusFilter = requestStatus.map((value, index) => {
      return {
        id: index + 1,
        requestStatus: value
      }
    }).map(ch => ({
      //we will need isChecked property at time of filter
      ...ch, checked: false
    }));

    const bidType = [...new Set(currentUserBidstoFilter.map(bidType => bidType.isDiscount))];
    //conver unique product name strings from array to array object - with id and name 
    // This is done because we want to append another key-value of checked, and this can only be done to array Objects, not array strings
    let uniqueBidTypes = bidType.map((value, index) => {
      return {
        id: index + 1,
        isDiscount: value
      }
    }).map(ch => ({
      //we will need isChecked property at time of filter
      ...ch, checked: false
    }));
    console.log('filter unique status ob', uniqueRequestStatusFilter)
    console.log('filter unique request status', requestStatus)
    console.log('filter unique bidTypes', uniqueBidTypes)

    this.bidfilterhandle.filterBids({
      data: {
        uniqueProductsFilter,
        uniqueRequestStatusFilter,
        currentUserBidstoFilter,
        uniqueBidTypes
      }
    }).subscribe((check) => {
      if (!check) {
        console.log('no Filter used')
        this.isFilterApplied = false
        return
      }
      console.log('Filter used', check)
      const filteredValues = check[0].productFilters
      this.currentUserBids = [...filteredValues]
      this.isFilterApplied = true
    })
  }

  viewUserProfile(userId: string, businessName: string) {
    try {
      this.router.navigate(["Merchant_Network/Check_Business_Profile", userId, businessName])
    }
    catch (e) {
      throw e
    }
  }

  sortData(sort: Sort) {
    const data = this.currentUserBids
    if (!sort.active || sort.direction === '') {
      this.sortBidData = data;
      return;
    }
    this.sortBidData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'bidCreateDate':
          return compare(a.bidCreateDate, b.bidCreateDate, isAsc);
        case 'oldPriceListed':
          return compare(a.oldPriceListed, b.oldPriceListed, isAsc);
        case 'newPriceOffered':
          return compare(a.newPriceOffered, b.newPriceOffered, isAsc);
        case 'quantityValue':
          return compare(a.quantityValue, b.newPriceOffered, isAsc);
        case 'bidRate':
          return compare(a.bidRate, b.bidRate, isAsc);
      }
    })
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
