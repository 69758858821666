<div class="content">
    <div class="forgotBox">
        <mat-card>
            <mat-spinner *ngIf="isLoading"></mat-spinner>
            <!-- enter email and verify user -->
            <form [formGroup]="loginform" *ngIf="!isLoading">
                <div class="filleither">
                    <div *ngIf="!this.loginform.value.email" class="sec1">
                        <mat-form-field>
                            <input matInput type="tel" name="phone" formControlName="phone"
                                [placeholder]="'phone number'|translate" pattern="[0-9]*" maxlength="10">
                            <mat-error *ngIf="formErrors.phone">{{formErrors.phone}}</mat-error>
                            <mat-hint>98xxxxxxx9</mat-hint>
                        </mat-form-field>
                    </div>
                    <div *ngIf="!this.loginform.value.phone && !this.loginform.value.email" class="sec2">
                        <mat-hint>
                            {{'or'|translate}}
                        </mat-hint>
                    </div>
                    <div *ngIf="!this.loginform.value.phone" class="sec3">
                        <mat-form-field>
                            <input matInput type="email" name="email" formControlName="email"
                                [placeholder]="'email'|translate">
                            <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error>
                            <mat-hint>Paw***ar@emailprovider.com</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <button mat-raised-button class="forgot-button" *ngIf="!this.otpSent" (click)="onverifyuserExist()">{{'next'|translate}}
                </button>
            </form>
            <!-- enter otp and verify userId and Otp -->
            <form [formGroup]="verifyOTPForm" *ngIf="!isLoading &&  this.otpSent" (submit)="resetPasswordWithOTP()">
                <mat-form-field>
                    <mat-label>otp to reset password</mat-label>
                    <input matInput type="number" maxlength="5"
                        [disabled]="this.otpVerified && this.resetPasswordEnable" pattern="[0-9]*"
                        formControlName="forgotPasswordOtp" autocomplete="off" />
                    <mat-hint>226020</mat-hint>
                </mat-form-field>
                <mat-form-field>
                    <input matInput type="password" name="password" formControlName="password"
                        [placeholder]="'password'|translate" [type]="hide? 'password':'text'" required>
                    <button mat-icon-button matSuffix (click)="hide=!hide" type="button"
                        [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide? 'visibility_off' : 'visibility'}}</mat-icon></button>
                    <mat-error *ngIf="formErrors.password">{{formErrors.password}}</mat-error>
                </mat-form-field>
                <button mat-raised-button class="forgot-button" *ngIf="this.otpSent">
                    {{"change password now"|translate}}
                </button>
            </form>
            <!-- enter and reset password -->
            <!-- <form [formGroup]="verifyOTPForm" *ngIf="!isLoading &&  this.otpSent" (submit)="resetPasswordWithOTP()">
                <mat-form-field>
                    <mat-label>OTP to reset password</mat-label>
                    <input matInput type="number" maxlength="5" [disabled]="this.otpVerified && this.resetPasswordEnable"
                        pattern="[0-9]*" formControlName="forgotPasswordOtp" autocomplete="off" />
                    <mat-hint>226020</mat-hint>
                </mat-form-field>
                <button mat-raised-button color="accent" *ngIf="this.otpSent" (click)="changePassword()">Confirm OTP
                </button>
            </form> -->
        </mat-card>
    </div>
</div>