<div *ngIf="this.categoryComms">
    <div class="banner">
        <div class="overlay">
            <h1 class="fade-in-text">{{ this.loadHeader|translate}}</h1>
        </div>
    </div>
    <mat-card class="cardMat">
        <div class="cardsContainer">
            <div class="allItems">
                <div class="selectAll list-section">
                    <mat-checkbox class="example-margin" [checked]="allComplete" [indeterminate]="someComplete()"
                        (change)="setAll($event.checked)">
                        <span>Select All</span>
                    </mat-checkbox>
                </div>
                <div class="outCard" *ngFor="let items of this.categoryComms">
                    <div [ngClass]="{'highlight': items.checked}" class="eachCard shine-splash">
                        <mat-checkbox [(ngModel)]="items.checked" (ngModelChange)="updateAllComplete()">
                            <div class="mainItem eachItem">
                                <div class="itemTitle">
                                    <img class="cardImage" [src]="items.commImg" [alt]="items.commCategoryName" />

                                </div>
                            </div>
                        </mat-checkbox>
                        <h5 class="title">{{items.commCategoryName | translate}}
                        </h5>
                    </div>
                </div>
                <div class="actions">
                    <div class=" back">
                        <button (click)="goBack()" mat-stroked-button>{{'back'|translate}}</button>
                    </div>
                    <div class="actionButton next">
                        <button (keyup.enter)="nextOne()" (click)="nextOne()"
                            mat-raised-button>{{'next'|translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</div>