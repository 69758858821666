import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SignUpPageShiftService } from 'src/app/services/sign-up-page-shift.service';
interface selectAccount {
  id: number,
  accountTypeName: string,
  accountTypeIMG: string,
  accountSubType: string,
  checked: boolean
}
@Component({
  selector: 'app-one-accountype',
  templateUrl: './one-accountype.component.html',
  styleUrls: ['./one-accountype.component.css']
})
export class OneAccountypeComponent implements OnInit {
  allComplete: boolean = false;
  atleastOneItemSelected: boolean = false;
  selectedAccountsCards: selectAccount[] = []
  accountTypes: selectAccount[] = [
    { id: 1, checked: false, accountTypeName: "Seller", accountTypeIMG: '../../../../assets/accountTypes/myapaar-producer-fpo.png', accountSubType: "Farmers & Producers Company" },
    { id: 2, checked: false, accountTypeName: "Seller", accountTypeIMG: '../../../../assets/accountTypes/myapaar-mill-fatory.png', accountSubType: "Mills & Food Processing Factory" },
    // { id: 3, checked: false, accountTypeName: "Seller", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/3-myp-agroprocess.png', accountSubType: "Agro-Processor" },
    // { id: 6, checked: false, accountTypeName: "Buyer", accountTypeIMG: '../../../../assets/accountTypes/agent-signup.svg', accountSubType: "Agent | Broker" },
    // { id: 5, checked: false, accountTypeName: "Seller", accountTypeIMG: '../../../../assets/accountTypes/myapaar-trader-whoelsale.png', accountSubType: "Trader & Merchant" },
    // { id: 6, checked: false, accountTypeName: "Buyer", accountTypeIMG: '../../../../assets/accountTypes/myapaar-foodsandbeverages.png', accountSubType: "FMCG Company" },
    { id: 6, checked: false, accountTypeName: "Buyer", accountTypeIMG: '../../../../assets/accountTypes/myapaar-foodsandbeverages.png', accountSubType: "Food & Beverage Company" },
    { id: 10, checked: false, accountTypeName: "Buyer", accountTypeIMG: '../../../../assets/accountTypes/myapaar-ayurvedawellnessmyapaar.png', accountSubType: "Herbaceuticals & Ayurveda Company" },
    { id: 7, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/6-myp-rest.png', accountSubType: "Restaurant & Cafe" },
    // { id: 8, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/7-myp-cafe.png', accountSubType: "Cafe" },
    // { id: 9, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/8-cloudKitchen.png', accountSubType: "Cloud Kitchen" },
    { id: 10, checked: false, accountTypeName: "Buyer", accountTypeIMG: 'https://openassetsmyapaar.s3.ap-south-1.amazonaws.com/AccountTypes/10-kirana.png', accountSubType: "Supermart & Grocer" },

  ]
  loadAccountTypes: selectAccount[] = []
  sendAccTypeData: any;
  headerLoad: string
  constructor(private pageShift: SignUpPageShiftService,
    private _snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit(): void {
    if (this.router.url.includes('SellerPartnership')) {
      this.loadAccountTypes = this.accountTypes.filter(ac => {
        return ac.accountTypeName == "Seller"
      })
      this.headerLoad = 'select your business for supply partnership'
    }
    if (this.router.url.includes('DailyBulkSupply')) {
      this.loadAccountTypes = this.accountTypes.filter(ac => {
        return ac.accountTypeName == "Buyer"
      })
      this.headerLoad = 'trade & merchant network'
    }
  }
  updateAllComplete() {
    try {
      this.allComplete = this.accountTypes != null && this.accountTypes.every(t => t.checked);
    }
    catch (e) { throw e }
  }

  someComplete(): boolean {
    try {
      if (this.accountTypes == null) {
        return false;
      }
      const check = this.atleastOneItemSelected && !this.allComplete;
      this.atleastOneItemSelected = this.accountTypes.filter(t => t.checked).length > 0
      if (this.atleastOneItemSelected) {
        this.selectedAccountsCards = this.accountTypes.filter(t => t.checked)
      }
      return check
    }
    catch (e) {
      throw e
    }
  }

  setAll(completed: boolean) {
    try {
      this.allComplete = completed;
      if (this.accountTypes == null) {
        return;
      }
      this.accountTypes.forEach(t => (t.checked = completed));
    }
    catch (e) { throw e }
  }

  nextOne() {
    try {
      let useAccountType: string = ''
      const checkIfSeller = this.selectedAccountsCards.some(eachCard => {
        return eachCard && eachCard.accountTypeName && eachCard.accountTypeName === 'Seller'
      })
      checkIfSeller ? useAccountType = 'Seller' : useAccountType = 'Buyer'
      const allSubAccountTypes = this.selectedAccountsCards.map(eachCard => {
        return eachCard.accountSubType
      })
      if (!allSubAccountTypes || allSubAccountTypes.length === 0) {
        this.signupError('Pick Business Types', "Ok")
        return
      }
      const addAccountType = {
        accountTypeName: useAccountType,
        subAccountTypesChosen: allSubAccountTypes
      }
      this.pageShift.setAccTypeData(addAccountType)
      this.router.navigate(['/signup/PersonalInfo'])
    }
    catch (e) {
      throw e
    }
  }
  signupError(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    })
  }
}
