<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Publish Buy Trades </h1>
            <h3>Book supplies for your Buy Trade</h3>
        </div>
        <mat-spinner></mat-spinner>
    </div>
</div>
<div class="userVerification">
    <div class="unverifiedID" *ngIf="this.gstDetailsPending && this.gstVerificationPending">
        <h5>Verification Pending</h5>
        <h6>Add Details</h6>
    </div>
    <div class="unverifiedID" *ngIf="!this.gstDetailsPending && this.gstVerificationPending">
        <h5>Request Submitted</h5>
        <h6>KYC Pending. We will update in 24 Hours</h6>
        <h6>Email us at solutions@myapaar.com</h6>
    </div>
    <div class="visitUserProfileUpdateGST" *ngIf="this.gstDetailsPending">
        <button (click)="changeUserInfo()" mat-raised-button>Update GST & PAN Information
        </button>
    </div>
</div>
<div class="banner">
    <div class="overlay">
        <h1 class="fade-in-text">Create Buy Trade</h1><br>
    </div>
</div>
<div class="verifiedID" *ngIf="!this.gstDetailsPending && !this.gstVerificationPending">
    <h5>Verified
    </h5>
</div>

<div class="typeOfListing" *ngIf="this.sortexListing == undefined && this.mode == 'createListing'">
    <div class="center">
        <mat-hint class="listHead">Bookings expire daily at 1 P.M</mat-hint><br>
        <mat-hint class="listHead">Indian Standard Time</mat-hint>
    </div>
    <div class="closeSecRow">
        <div *ngIf="this.showFarmListing" (click)="selectedlistingType(false)" class="card looseType">
            <div class="imgSec">
                <div class="optionListing">
                    <span>
                        Farm supply </span> <br>
                    <mat-hint class="shint">
                        MoQ is <br> 25 Metric Ton
                    </mat-hint>
                </div>
                <div class="loose">
                </div>
            </div>
            <div class="actionBottom">
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Available at Mandi Rates
                    </span>
                </div>
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Non Sortexed Non Cleaned</span>
                </div>
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>White PP Bags</span>
                </div>
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Jute Gunny Bags</span>
                </div>
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Verified Farm Gate Supplier</span>
                </div>
            </div>
        </div>
        <div *ngIf="this.showFactoryListing" (click)="selectedlistingType(false)" class="card sortexType">
            <div class="imgSec">
                <div class="optionListing">
                    <span>
                        Factory Supply
                    </span> <br>
                    <mat-hint class="shint">
                        MoQ is <br> 25 Metric Ton
                    </mat-hint>
                </div>
                <div class="packed">
                </div>
            </div>
            <div class="actionBottom">
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>White PP Bags</span>
                </div>
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Jute Gunny Bags</span>
                </div>
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Plastic Pack / Bottles / Drums</span>
                </div>
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Tinplate Packs / Containers</span>
                </div>
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Sortex & Cleaned</span>
                </div>
            </div>
        </div>
        <div *ngIf="this.showWholesaleListing" (click)="selectedlistingType(true)" class="card sortexType">
            <div class="imgSec">
                <div class="optionListing">
                    <span>
                        Wholesale Supply
                    </span> <br>
                    <mat-hint class="shint">
                        MoQ is <br> 50 Packets
                    </mat-hint>
                </div>
                <div class="wholes">
                </div>
            </div>
            <div class="actionBottom">
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>White PP Bags</span>
                </div>
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Jute Gunny Bags</span>
                </div>
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Plastic Pack / Bags</span>
                </div>
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Plastic Bottles / Containers</span>
                </div>
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Tinplate Packs / Containers</span>
                </div>
                <div class="detailLine">
                    <mat-icon>check_circle_outline</mat-icon>
                    <span>Sortex & Cleaned</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="this.sortexListing !== undefined" class="contentCreateListing">
    <ng-template #formOne>
        <!-- <mat-spinner *ngIf="isLoading"></mat-spinner> -->
        <form [formGroup]="listingFormOneItemDetails" *ngIf="!isLoading">
            <div class="secRow aa">
                <div class="category">
                    <mat-form-field appearance="outline">
                        <mat-hint>Grains</mat-hint>
                        <mat-label>Select Category</mat-label>
                        <mat-select [(ngModel)]="selectedCategoryValue"
                            (ngModelChange)="filterProductsAndChooseCategory()" name="category"
                            formControlName="categoryUnique">
                            <mat-option *ngFor="let category of productcategories" [value]="category.categoryUnique">
                                {{category?.categoryName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="product">
                    <mat-form-field appearance="outline">
                        <mat-hint>Rice...&nbsp;&nbsp; <a target="_blank"
                                href="https://www.myapaar.com/requirement/NewRequirement" mat-list-item>
                                <mat-icon class="adminPoint">check_circle_outline</mat-icon> Item not present ?
                                Suggest myapaar to add item
                            </a></mat-hint>
                        <mat-label>Select Item</mat-label>
                        <mat-select [disabled]="isfilterProductsLoading" [(ngModel)]="selectedProductValue"
                            (ngModelChange)="filterVarietiesAndChooseProduct()" name="product"
                            formControlName="productUnique">
                            <mat-option *ngFor="let product of filteredProducts" [value]="product.productUnique">
                                {{product?.productName}}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>
                </div>
            </div>
            <div class="secRow ab">
                <div class="varietyToggle">
                    <mat-slide-toggle formControlName="isVarietyAvailable" [(ngModel)]="isVarietyAvailable">Add Variety
                    </mat-slide-toggle><br>
                    <mat-hint>Optional</mat-hint>
                </div>
                <div class="varietyField" *ngIf="isVarietyAvailable">
                    <mat-form-field appearance="outline">
                        <mat-hint>Basmati Sella 1121...&nbsp;&nbsp; <a target="_blank"
                                href="https://www.myapaar.com/requirement/NewRequirement" mat-list-item>
                                <mat-icon class="adminPoint">check_circle_outline</mat-icon> Variety not present ?
                                Suggest myapaar to add item
                            </a></mat-hint>
                        <mat-label>Choose variety from options</mat-label>
                        <mat-select [disabled]="isfilterVarietiesLoading" [(ngModel)]="selectedVarietyValue"
                            (ngModelChange)="chooseFilteredVariety()" name="variety" formControlName="varietyUnique">
                            <mat-option *ngFor="let variety of  this.filteredVarieties" [value]="variety.varietyUnique">
                                {{variety?.varietyName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="secRow ac">
                <div class="packSize">
                    <mat-form-field appearance="outline">
                        <mat-label>Packet Size ( Kg )</mat-label>
                        <input type="tel" name="phone" matInput formControlName="packetSize"
                            [(ngModel)]="productPacketSize" autocomplete="off" pattern="[0-9]*" required />
                        <mat-error *ngIf="formErrors.packetSize">{{formErrors.packetSize}}</mat-error>
                        <mat-hint *ngIf="!this.sortexListing"> 50 </mat-hint>
                        <mat-hint *ngIf="this.sortexListing"> 1 </mat-hint>
                    </mat-form-field>
                </div>
                <div class="pricingInfo">
                    <mat-form-field appearance="outline">
                        <mat-label>Target Price ( ₹ / {{this.useShortListingUnit}} )</mat-label>
                        <input matInput formControlName="bulkPrice" [(ngModel)]="bulkPriceValue" autocomplete="off"
                            pattern="[0-9]*" required />
                        <mat-error *ngIf="formErrors.bulkPrice">{{formErrors.bulkPrice}}</mat-error>
                        <mat-hint> Per {{this.useListingUnit}} </mat-hint>
                    </mat-form-field>
                </div>
                <div class="prodQuantity">
                    <mat-form-field appearance="outline">
                        <mat-label>Requirement ( {{this.useListingUnit}}s ) </mat-label>
                        <input matInput formControlName="bulkQuantity" [(ngModel)]="productQuantityValue"
                            autocomplete="off" pattern="[0-9]*" required />
                        <mat-error *ngIf="formErrors.bulkQuantity">{{formErrors.bulkQuantity}}</mat-error>
                        <mat-hint> 500 </mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <div class="packagingBar">
            <mat-accordion *ngIf="!this.sortexListing">
                <mat-expansion-panel [disabled]="this.disablepackagingType">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Packaging
                        </mat-panel-title>
                        <mat-panel-description>
                            Packaging types available
                            <mat-icon>loyalty</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <section class="example-section">
                        <span class="example-list-section">
                            <mat-checkbox class="example-margin" [checked]="allpackagingComplete"
                                [indeterminate]="somepackagingComplete()" (change)="setpackagingAll($event.checked)"
                                [disabled]="this.disablepackagingType">
                                Select All
                            </mat-checkbox>
                        </span>
                        <div class="packageSelection" *ngFor="let packaging of this.usePackagingType">
                            <mat-checkbox [(ngModel)]="packaging.checked" (ngModelChange)="updateAllpackagingComplete()"
                                [disabled]="this.disablepackagingType">
                                {{packaging.packagingType}}
                            </mat-checkbox>
                        </div>
                    </section>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <!-- 
      <div *ngIf="productQuantityValue>=1 && this.selectedPackaging" class="summary">
        <div class="itemquantitydetails">
          <p>Total Available Quantity is <span class="highlightVal">{{(productQuantityValue).toLocaleString('en-IN')}}
              Quintal</span>
          </p>
          <p>Price Per Quintal is <span class="highlightVal">{{(bulkPriceValue).toLocaleString('en-IN')}} ₹ </span>
          </p>
          <p>Total Price of Inventory is <span class="highlightVal">{{ (bulkPriceValue *
              productQuantityValue).toLocaleString('en-IN')}} ₹</span> </p>
          <p>Minimum order size is <span class="highlightVal">{{(productLotSizeValue).toLocaleString('en-IN')}}
              Quintal</span> makes 1 lot</p>
          <p>Price of 1 lot is <span class="highlightVal">{{(bulkPriceValue *
              productLotSizeValue).toLocaleString('en-IN')}} ₹</span> </p>
          <p>Packaging used <span class="highlightVal"> {{this.selectedPackaging.type}}</span></p>
        </div>
        <div class="setpackaging">
          <img class="packageImg" [src]='this.selectedPackaging.pimg' />
        </div>
      </div> -->
    </ng-template>
    <ng-template #formTwo>
        <div class="sectiontwo">
            <div class="imgTable">
                <form [formGroup]="listingFormTwoImages">
                    <div class="imgup twim">
                        <div class="imageUp">
                            <div>
                                <mat-hint>4 Photos Allowed </mat-hint>
                                <div *ngIf="!this.images" id="imagesReview">
                                </div>
                                <div id="imagesReview" *ngIf="this.imagesUriArray && this.imagesUriArray.length >= 0">
                                    <div *ngFor="let eimg of this.imagesUriArray" class="eachImg">
                                        <img class="eachImgPreview" [src]="eimg" />
                                    </div>
                                </div>
                                <button mat-stroked-button color="primary" class="imagePickerbutton"
                                    (click)="filePicker.click()">
                                    <span *ngIf="!this.images">Choose Sample Images</span>
                                    <br><mat-hint>for quality matching</mat-hint>
                                    <div>
                                        <span *ngIf="this.images">{{this.images.length}} picked</span>
                                        <mat-spinner *ngIf="isImageLoading" diameter="20"></mat-spinner>
                                    </div>
                                </button>
                                <mat-error *ngIf="!this.images">{{this.noUploadMsg}}</mat-error>
                                <input hidden #filePicker type='file' name='images' multiple
                                    (change)="onImagesPicked($event)" />
                            </div>
                            <mat-hint>Clear photos, Zoom in and out</mat-hint>
                        </div>
                    </div>
                </form>
            </div>
            <div class="infoTable">
                <div class="headerDetails">
                    <div>
                        <div class="secRow">
                            <mat-slide-toggle [(ngModel)]="isProductDetailsAdd">
                                <span class="infoHeader"> Add Product Details</span>
                            </mat-slide-toggle>
                            <mat-hint> Get more bookings</mat-hint>
                        </div>
                    </div>
                </div>
                <div class="allDetailRows" *ngIf="this.isProductDetailsAdd">
                    <form [formGroup]="listingFormTwoImages">
                        <mat-list-item>
                            <mat-form-field class="descriptionArea" appearance="outline">
                                <mat-label>Description (optional)</mat-label>
                                <textarea formControlName="description" matInput></textarea>
                                <mat-hint>Describe in 100 words</mat-hint>
                            </mat-form-field>
                        </mat-list-item>
                    </form>
                    <div class="detailRow">
                        <div class="detailType">
                            <mat-form-field appearance="outline">
                                <mat-label>Detail 1</mat-label>
                                <input maxlength="52" matInput [(ngModel)]="this.useDetailType1" autocomplete="off" />
                                <mat-hint>grain length and breadth</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="detailMetric">
                            <mat-form-field appearance="outline">
                                <mat-label>Property 1</mat-label>
                                <input maxlength="26" matInput [(ngModel)]="this.useMetricType1" autocomplete="off" />
                                <mat-hint>7.5 mm x 2 mm</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="detailRow">
                        <div class="detailType">
                            <mat-form-field appearance="outline">
                                <mat-label>Detail 2</mat-label>
                                <input maxlength="52" matInput [(ngModel)]="this.useDetailType2" autocomplete="off" />
                                <mat-hint> moisture content</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="detailMetric">
                            <mat-form-field appearance="outline">
                                <mat-label>Property 2</mat-label>
                                <input maxlength="26" matInput [(ngModel)]="this.useMetricType2" autocomplete="off" />
                                <mat-hint> 11 to 13 %</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="detailRow">
                        <div class="detailType">
                            <mat-form-field appearance="outline">
                                <mat-label>Detail 3</mat-label>
                                <input maxlength="52" matInput [(ngModel)]="this.useDetailType3" autocomplete="off" />
                                <mat-hint> refractive index</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="detailMetric">
                            <mat-form-field appearance="outline">
                                <mat-label>Property 3</mat-label>
                                <input maxlength="26" matInput [(ngModel)]="this.useMetricType3" autocomplete="off" />
                                <mat-hint> 1.457 to 1.466</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="detailRow">
                        <div class="detailType">
                            <mat-form-field appearance="outline">
                                <mat-label>Detail 4</mat-label>
                                <input maxlength="52" matInput [(ngModel)]="this.useDetailType4" autocomplete="off" />
                                <mat-hint> optical rotation</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="detailMetric">
                            <mat-form-field appearance="outline">
                                <mat-label>Property 4</mat-label>
                                <input maxlength="26" matInput [(ngModel)]="this.useMetricType4" autocomplete="off" />
                                <mat-hint> 12.5 to 6</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="detailRow">
                        <div class="detailType">
                            <mat-form-field appearance="outline">
                                <mat-label>Detail 5</mat-label>
                                <input maxlength="52" matInput [(ngModel)]="this.useDetailType5" autocomplete="off" />
                                <mat-hint> micronair</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="detailMetric">
                            <mat-form-field appearance="outline">
                                <mat-label>Property 5</mat-label>
                                <input maxlength="26" matInput [(ngModel)]="this.useMetricType5" autocomplete="off" />
                                <mat-hint> 2.5 to 7.5</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #formThree>
        <div class="packagingBar">
            <mat-accordion>
                <mat-expansion-panel [disabled]="this.disableCreditTerms">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Payment Terms
                        </mat-panel-title>
                        <mat-panel-description>
                            Pick token charge
                            <mat-icon>date_range</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <section class="example-section">
                        <span class="example-list-section">
                            <mat-checkbox class="example-margin" [checked]="allComplete"
                                [indeterminate]="someComplete()" (change)="setAll($event.checked)"
                                [disabled]="this.disableCreditTerms">
                                Select All
                            </mat-checkbox>
                        </span>
                        <div class="eachCT" *ngFor="let credit of this.creditTerms">
                            <mat-checkbox [(ngModel)]="credit.checked" (ngModelChange)="updateAllComplete()"
                                [disabled]="this.disableCreditTerms">
                                <span
                                    style=" font-size: 21px !important;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif  !important;"
                                    class="creditTermVal">{{credit.viewValue}}</span>
                            </mat-checkbox>
                            <br>
                            <span
                                style="color: grey ;padding-left: 7px !important;font-size: 13px !important;
              font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif  !important;"
                                class="creditTermHint">{{credit.hint}}</span>
                            <br>
                        </div>
                    </section>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <form [formGroup]="listingFormThreePickupAddress">
            <div class="secRow ad">
                <div class="pinField">
                    <mat-form-field appearance="outline">
                        <mat-label>PIN Code</mat-label>
                        <input [(ngModel)]="selectedPincode" (ngModelChange)="getPincodeDetails()" matInput
                            formControlName="pinCode" autocomplete="off" />
                        <mat-hint>226020</mat-hint>
                    </mat-form-field>
                </div>
                <div class="stateField">
                    <mat-form-field appearance="outline">
                        <input matInput type="text" [(ngModel)]="stateName" name="state" formControlName="state"
                            placeholder="State">
                        <mat-error *ngIf="formErrors.state">{{formErrors.state}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="cityField">
                    <mat-form-field appearance="outline">
                        <input matInput type="text" name="city" [(ngModel)]="districtName" formControlName="city"
                            placeholder="City">
                        <mat-error *ngIf="formErrors.city">{{formErrors.city}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="secRow ae">
                <div class="localityField">
                    <mat-form-field appearance="outline">
                        <mat-label>Locality</mat-label>
                        <input matInput placeholder="Search" [(ngModel)]="localityName" [matAutocomplete]="auto"
                            formControlName="addressStreet" />
                        <mat-autocomplete #auto="matAutocomplete">
                            <ng-container>
                                <mat-option *ngFor="let locality of this.pinLocalities" [value]="locality">
                                    <span>{{locality}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="buildingField">
                    <mat-form-field appearance="outline">
                        <mat-label>Building/Shop Name</mat-label>
                        <input matInput formControlName="addressBuildingNameAndFloor" autocomplete="off" />
                        <mat-hint>My business building</mat-hint>
                    </mat-form-field>
                </div>
                <div class="landmarkField">
                    <mat-form-field appearance="outline">
                        <mat-label>Landmark</mat-label>
                        <input matInput formControlName="landmark" autocomplete="off" />
                        <mat-hint>Near Mega Lotus Hotel</mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </ng-template>

    <ng-template #formFour>
        <div class="secCol">
            <mat-hint>Publish begins at 11 A.M</mat-hint><br>
            <mat-hint>Publish closes at 1 P.M</mat-hint><br>
            <button class="createListingButton" mat-raised-button
                [disabled]="this.gstDetailsPending && this.gstVerificationPending" type="submit"
                (click)="onSaveListing()">
                <!-- <span *ngIf="this.createListing">Create Listing</span>
          <span *ngIf="this.editListing">Update Listing</span> -->
                <div class="postListing">
                </div>
            </button>
            <!-- <div class="afterListingInfo">
          <p>
            All your listings are stored in
            <span>Manage Listings</span> section.
          </p>
          <p>
            Later, you can change Price, Title and other details from
            <span>Manage Listings</span> section.
          </p>
          <p>
            You can delete your listings from
            <span>Manage Listings</span> section.
        </div> -->
            <br>
            <mat-hint *ngIf="this.gstDetailsPending && this.gstVerificationPending">GST verification and Details
                pending</mat-hint>
        </div>
        <div [hidden]="!this.gstVerificationPending">
            <form [formGroup]="listingFormFourContact">
                <mat-list>
                    <mat-list-item>
                        <mat-form-field appearance="outline">
                            <input matInput type="text" name="listingCreator" formControlName="listingCreator"
                                placeholder="Full Name">
                            <mat-error *ngIf="formErrors.listingCreator">{{formErrors.listingCreator}}</mat-error>
                            <mat-hint>Mukul Mishra</mat-hint>
                        </mat-form-field>
                    </mat-list-item>

                    <mat-list-item>
                        <mat-form-field appearance="outline">
                            <input matInput name="email" formControlName="email" placeholder="abc@gmail.com">
                            <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error>
                            <mat-hint>Abc@gmail.com</mat-hint>
                        </mat-form-field>
                    </mat-list-item>

                    <mat-list-item>
                        <mat-form-field appearance="outline">
                            <input matInput type="string" name="phone" formControlName="phone"
                                placeholder="Phone Number">
                            <mat-error *ngIf="formErrors.phone">{{formErrors.phone}}</mat-error>
                            <mat-hint>8014801480 [val - only allow numbers - 10 max]</mat-hint>
                        </mat-form-field>
                    </mat-list-item>

                    <mat-list-item>
                        <mat-form-field appearance="outline">
                            <input matInput type="string" name="alternatePhoneContact"
                                formControlName="alternatePhoneContact" placeholder="Alternate Phone Number">
                            <mat-error
                                *ngIf="formErrors.alternatePhoneContact">{{formErrors.alternatePhoneContact}}</mat-error>
                            <mat-hint>9081908190 [val - only allow numbers - 10 max]</mat-hint>
                        </mat-form-field>
                    </mat-list-item>

                    <div class="visitUserProfile">
                        <button (click)="changeUserInfo()" mat-stroked-button color="warn">To Edit go to User
                            Profile
                            Settings </button>
                    </div>
                    <mat-list-item>
                        <mat-slide-toggle formControlName="isGSTAvailable" [(ngModel)]="isGSTAvailable">Do you have
                            valid GST number
                            ?</mat-slide-toggle>

                    </mat-list-item>

                    <mat-list-item *ngIf="isGSTAvailable">
                        <mat-form-field appearance="outline">
                            <input matInput type="text" name="GST" formControlName="GST_Id" placeholder="GST Number">
                            <mat-error *ngIf="formErrors.GST_Id">{{formErrors.GST_Id}}</mat-error>
                            <mat-hint>GST Verification</mat-hint>
                        </mat-form-field>
                    </mat-list-item>

                </mat-list>

            </form>
        </div>
    </ng-template>

    <ng-template [ngIf]="smallScreen" [ngIfElse]="bigScreen">
        <mat-vertical-stepper linear #stepper>
            <mat-step label="What are you buying?">
                <ng-container *ngTemplateOutlet="formOne"></ng-container>
            </mat-step>
            <mat-step label="Upload images">
                <ng-container *ngTemplateOutlet="formTwo"></ng-container>
            </mat-step>
            <mat-step label="Delivery and payment">
                <ng-container *ngTemplateOutlet="formThree"></ng-container>
            </mat-step>
            <mat-step label="Publish Trade">
                <ng-container *ngTemplateOutlet="formFour"></ng-container>
            </mat-step>
        </mat-vertical-stepper>
    </ng-template>

    <ng-template #bigScreen>
        <mat-horizontal-stepper linear #stepper>
            <mat-step label="What are you supplying ?">
                <ng-container *ngTemplateOutlet="formOne"></ng-container>
            </mat-step>
            <mat-step label="Upload images">
                <ng-container *ngTemplateOutlet="formTwo"></ng-container>
            </mat-step>
            <mat-step label="Payment terms & pickup">
                <ng-container *ngTemplateOutlet="formThree"></ng-container>
            </mat-step>
            <mat-step label="Create listing">
                <ng-container *ngTemplateOutlet="formFour"></ng-container>
            </mat-step>
        </mat-horizontal-stepper>
    </ng-template>
</div>