import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class HandleUserLinkService {
  private domain: string | undefined
  constructor(private http: HttpClient, private settingsService: SettingsService
  ) {
    this.domain = settingsService.cherryURL
  }

  startLink(currLogUsr: string, linkToUser: string) {
    try {
      if (currLogUsr == linkToUser) {
        return false
      }
      else {
        this.chckLnkOno(currLogUsr, linkToUser).then(checkResult => {
        })
        return true
      }

    }
    catch (e) {
      throw e
    }

  }

  async chckLnkOno(currUs: string, ownrUsr: string): Promise<any> {
    try {
      const queryParams = `?currUs=${currUs}&&ownrUsr=${ownrUsr}`
      const chkLnk = await this.http.get(`${this.domain}api/usLnk/chkLnkAdV/` + queryParams).toPromise()
      return chkLnk
    }
    catch (e) {
      throw e
    }
  }

  async buttnStat(currUs: string, ownrUsr: string): Promise<any> {
    try {
      const queryParams = `?currUs=${currUs}&&ownrUsr=${ownrUsr}`
      const chkBtn = await this.http.get(`${this.domain}api/usLnk/chkbtnstat/` + queryParams).toPromise()
      return chkBtn
    }
    catch (e) {
      throw e
    }
  }

  async withdlnkReq(currUs: string, ownrUsr: string): Promise<any> {
    try {
      const queryParams = `?currUs=${currUs}&&ownrUsr=${ownrUsr}`
      const chkLnk = await this.http.get(`${this.domain}api/usLnk/wthdLnkreq/` + queryParams).toPromise()
      return chkLnk
    }
    catch (e) {
      throw e
    }
  }
  async sndlnkReq(currUs: string, ownrUsr: string): Promise<any> {
    try {
      const queryParams = `?currUs=${currUs}&&ownrUsr=${ownrUsr}`
      const chkLnk = await this.http.get(`${this.domain}api/usLnk/nwLnkRq/` + queryParams).toPromise()
      return chkLnk
    }
    catch (e) {
      throw e
    }
  }
}
