<div class="content">
    <div id="listingCard">
        <main>
            <!-- <form [formGroup]="addListingToCart"> -->
            <div *ngIf="this.currentListingwithId" class="card">
                <div class="rowButtonSet">
                    <span *ngIf="this.currentListingwithId.SKU">
                        #SKU {{this.currentListingwithId.SKU}}
                    </span>
                </div>
                <div class="cardTitle">
                    <div class="icon">
                        <a href="#"><i class="fa fa-arrow-left"></i></a>
                    </div>
                    <h3>{{this.currentListingwithId.productName}}
                        <span>{{this.currentListingwithId.categoryName}}</span>
                    </h3>
                    <!-- <div *ngIf="!this.sellerViewingOwnListing" class="stock"><span>AVAILABLE</span></div>
                    <div *ngIf="this.sellerViewingOwnListing" class="preview"><span>PREVIEW LISTING</span></div> -->
                </div>
                <div class="cardBody">
                    <div class="firsthalf">
                        <div class="img-slider">
                            <div class="slide">
                                <mat-carousel class="mat-carousel" timings="200ms ease-in" [autoplay]="true"
                                    interval="4000" color="accent" [loop]="true" maxWidth="auto" [hideIndicators]="true"
                                    [hideArrows]="false" [useKeyboard]="true" [maintainAspectRatio]="false"
                                    orientation="ltr">
                                    <mat-carousel-slide *ngFor="let slide of this.allImagesListing; let i = index"
                                        [image]="slide" overlayColor="#rgba(255, 255, 255, 0.3)"
                                        [hideOverlay]="false"></mat-carousel-slide>
                                </mat-carousel>
                            </div>
                        </div>
                    </div>
                    <div class="secondhalf">
                        <div class="spacerDiv">
                            <div class="featured_text">
                                <span *ngIf="this.currentListingwithId.varietyName
                                    && this.currentListingwithId.varietyName != '' 
                                    && this.currentListingwithId.varietyName != null
                                    && this.currentListingwithId.varietyName != undefined  " class="sub">
                                    {{this.currentListingwithId.varietyName}}
                                </span>
                                <div class="priceEnq">
                                    <div *ngIf="this.currentListingwithId.useUnit == 'Packet'" class="priceTrendSec">
                                        <span class="upPrice">
                                            ₹&nbsp;{{(this.priceUpShowForSortex).toLocaleString('en-IN')}} /
                                            {{this.currentListingwithId.useUnitShort}}
                                        </span>
                                        <span><mat-icon class="saleTrend">trending_down</mat-icon></span>
                                    </div>
                                    <div class="tpandNote">
                                        <p class="price">
                                            ₹&nbsp;{{(this.currentListingwithId.bulkPrice).toLocaleString('en-IN')}}&nbsp;/
                                            <span>
                                                {{this.currentListingwithId.useUnitShort}}
                                            </span>
                                            <span id="packetSize" *ngIf="this.currentListingwithId.packetSize && this.currentListingwithId.packetSize  !== undefined 
                                                && this.currentListingwithId.packetSize !== null">
                                                [ {{this.currentListingwithId.packetSize}} KG Packet ]
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="color-changing-border">
                                <span class="sub">
                                    @Ex-factory rate on myapaar
                                </span>
                            </div>
                        </div>
                        <div class="card infoLot">
                            <div class="cardFooter">
                                <div class="action-slider">
                                    <div class="shake">
                                        <mat-hint>Slide to Select Quantity</mat-hint>
                                    </div>
                                    <!-- <mat-slider [min]="min" [max]="max" [step]="step" formControlName="quantityValue"
                                        thumbLabel [(ngModel)]="quantityValue"
                                        (change)="onQuantityChange($event)"></mat-slider> -->
                                    <mat-slider [min]="min" [max]="max" [step]="step" thumbLabel
                                        [(ngModel)]="quantityValue" (change)="onQuantityChange($event)"></mat-slider>
                                </div>
                                <div class="rowButtonSet">
                                    <div class="buttonSet limitwidth col1">
                                        <div class="buttonSetItem quantitySelected">
                                            <mat-form-field class="limitwidth" appearance="outline">
                                                <mat-label>{{quantityValue}}
                                                    {{this.currentListingwithId.useUnit}}s,
                                                    {{quantityValue / step | number:
                                                    '1.0-0'}}
                                                    Lot</mat-label>
                                                <input matInput type="number" min="1" max="this.max"
                                                    style="font-size: large;" placeholder="this.max" readonly
                                                    [(ngModel)]="quantityValue" required>
                                                <!-- <input matInput type="number" min="1" max="this.max"
                                                    style="font-size: large;" placeholder="this.max"
                                                    formControlName="quantityValue" readonly [(ngModel)]="quantityValue"
                                                    required> -->
                                            </mat-form-field>
                                        </div>
                                        <div class="buttonSetItem policyButton">
                                            <button class="refundPolicy" mat-stroked-button
                                                routerLink="/TermsAndConditionPolicy"> Return
                                                Policy</button>
                                        </div>

                                    </div>
                                    <div class="buttonSet limitwidth col2">



                                        <!-- <div class="buttonSetItem bulkCart">
                                            <button class="bulkorder cartButton" mat-raised-button
                                                [disabled]="this.sellerViewingOwnListing || quantityValue===0 || quantityValue===undefined || this.selectedCreditDays=== undefined"
                                                (click)="addToCart(this.currentListingwithId,'bulk')">Add to
                                                Cart</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="threehalf">
                    <mat-card>
                        <div class="aboutSec listingDesc">
                            <div class="prodAboutDetailSec">
                                <div class="aboutSec"
                                    *ngIf="this.currentListingwithId.productDetailInfo && this.currentListingwithId.productDetailInfo.length > 0 && this.isDisplayProductDetails">
                                    <h1 class="detailHeader">Product Details</h1>
                                    <div class="productInfoDetailsTable">
                                        <div class="itemInfoTable"
                                            *ngFor="let prodInfodetail of this.currentListingwithId.productDetailInfo">
                                            <span class="detkeyProduct"
                                                *ngIf="prodInfodetail && prodInfodetail.type && prodInfodetail.type !== ''">
                                                {{prodInfodetail.type}}</span>
                                            <span class="detvalueProduct"
                                                *ngIf="prodInfodetail && prodInfodetail.value && prodInfodetail.value !== ''">
                                                {{prodInfodetail.metric}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="this.currentListingwithId.description && this.currentListingwithId.description != ''">
                                    <h1 class="detailHeader">About Product</h1>
                                    <div id="listingDescription">
                                        <p>{{this.currentListingwithId.description}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="listDetailSec">
                                <h1 class="detailHeader">Listing Details</h1>
                                <div class="listingDetailsTable">
                                    <div class="keyProduct">
                                        <p
                                            *ngIf="this.currentListingwithId.categoryName && this.currentListingwithId.categoryName !== ''">
                                            Category</p>
                                        <p
                                            *ngIf="this.currentListingwithId.productName && this.currentListingwithId.productName !== ''">
                                            Product</p>
                                        <p
                                            *ngIf="this.currentListingwithId.varietyName && this.currentListingwithId.varietyName !== ''">
                                            Variety</p>
                                        <p *ngIf="this.currentListingwithId.packetSize && this.currentListingwithId.packetSize  !== undefined 
                                                && this.currentListingwithId.packetSize !== null">Packet Size
                                        </p>
                                        <p *ngIf="this.currentListingwithId.lotSize && this.currentListingwithId.lotSize  !== undefined 
                                                && this.currentListingwithId.lotSize !== null">Min. Order- MOQ
                                        </p>
                                        <p *ngIf="this.currentListingwithId.bulkPrice && this.currentListingwithId.bulkPrice !== undefined
                                                && this.currentListingwithId.bulkPrice !== null">Price per
                                            {{this.currentListingwithId.useUnit}}
                                        </p>
                                    </div>
                                    <div class="valueProduct">
                                        <p
                                            *ngIf="this.currentListingwithId.categoryName && this.currentListingwithId.categoryName !== ''">
                                            {{this.currentListingwithId.categoryName}}</p>
                                        <p
                                            *ngIf="this.currentListingwithId.productName && this.currentListingwithId.productName !== ''">
                                            {{this.currentListingwithId.productName}}</p>
                                        <p
                                            *ngIf="this.currentListingwithId.varietyName && this.currentListingwithId.varietyName !== ''">
                                            {{this.currentListingwithId.varietyName}}</p>
                                        <p *ngIf="this.currentListingwithId.packetSize && this.currentListingwithId.packetSize  !== undefined 
                                                && this.currentListingwithId.packetSize !== null">
                                            {{this.currentListingwithId.packetSize}} KG</p>
                                        <p *ngIf="this.currentListingwithId.lotSize && this.currentListingwithId.lotSize  !== undefined 
                                                    && this.currentListingwithId.lotSize !== null">
                                            {{this.currentListingwithId.lotSize}}
                                            {{this.currentListingwithId.useUnit}}</p>
                                        <p *ngIf="this.currentListingwithId.bulkPrice && this.currentListingwithId.bulkPrice !== undefined
                                                    && this.currentListingwithId.bulkPrice !== null">
                                            ₹&nbsp;{{this.currentListingwithId.bulkPrice}}&nbsp;/{{this.currentListingwithId.useUnit}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <!-- </form> -->
        </main>
    </div>
</div>