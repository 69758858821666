import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Validations } from 'src/app/datamodels/Validation';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { BidRateHandlingService } from 'src/app/services/bid-rate-handling.service';
interface negotiateRates {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-bid-listing-component',
  templateUrl: './bid-listing-component.component.html',
  styleUrls: ['./bid-listing-component.component.css']
})
export class BidListingComponentComponent implements OnInit {
  selectedRateValue: any;
  isRateSelected = false;
  bidRequestResponse: any;
  isdiscountRequestPlaced: boolean = true
  requestSentObject: any
  quantityValue = null;
  isQuantityEntered = false;
  bidRequestFormDetails: FormGroup;
  negotiateBidRequestDetails: FormGroup;
  newPriceOffered: number;
  validationMessages = {
    'selectedRateValue': {
      'required': 'Rate is required',
    },
    'quantityValue': {
      'required': 'Quantity is required',
    }
  }
  formErrors = {
    'selectedRateValueDiscount': 'Select Discount rate',
    'selectedRateValueBid': 'Choose Bid rate',
    'quantityValue': 'Enter item Quantity'
  }
  //same bid rates are being used by discount as well as bid-up, this can be changed later
  bidRates: negotiateRates[] = [
    { value: '1', viewValue: '1 %' },
    { value: '3', viewValue: '3 %' },
    { value: '6', viewValue: '6 %' },
    { value: '9', viewValue: '9 %' },
    { value: '13', viewValue: '13 %' },
    { value: '15', viewValue: '15 %' },
    { value: '17', viewValue: '17 %' },
    { value: '21', viewValue: '21 %' }
  ];
  discountPrice: number;
  finalDiscPrice: number;
  bidUpPrice: number;
  finalBidupPrice: number;

  constructor(@Inject(MAT_DIALOG_DATA) public bidListing: any,
    public dialogRef: MatDialogRef<BidListingComponentComponent>,
    private router: Router,
    private bidrequesthandle: BidRateHandlingService
  ) { }
  ngOnInit(): void {
    try {
      this.setDiscountRatesAndPrices()
      this.bidRequestFormDetails = new FormGroup({
        isBid: new FormControl(null, { validators: [Validators.required] }),
        isDiscount: new FormControl(null, { validators: [Validators.required] }),
        isRateSelected: new FormControl(null, { validators: [Validators.required] }),
        bidRate: new FormControl(null, { validators: [Validators.required] }),
        quantityValue: new FormControl(null, { validators: [Validators.required] }),
        requestStatus: new FormControl(null, { validators: [Validators.required] }),
        bidCreator: new FormControl(null, { validators: [Validators.required] }),
        listingId: new FormControl(null, { validators: [Validators.required] }),
        listingProduct: new FormControl(null, { validators: [Validators.required] }),
        listingTitle: new FormControl(null, { validators: [Validators.required] }),
        imageUrls: new FormControl([], { validators: [Validators.required] }),
        newPriceOffered: new FormControl(null, { validators: [Validators.required] }),
        isPending: new FormControl(null, { validators: [Validators.required] }),
        isApproved: new FormControl(null, { validators: [Validators.required] }),
        isCanceled: new FormControl(null, { validators: [Validators.required] }),
        oldPriceListed: new FormControl(null, { validators: [Validators.required] }),
        bidWriter: new FormControl(null, { validators: [Validators.required] }),
        bidCreatorName: new FormControl(null),
        bidWriterName: new FormControl(null),
        bidCreateDate: new FormControl(null),
        bidActionDate: new FormControl(null)

      })
      this.negotiateBidRequestDetails = new FormGroup({

      })
    }
    catch (e) { }
  }
  setDiscountRatesAndPrices() {
    // this.discountPrice = 
  }
  rateInput() {
    try {
      if (this.selectedRateValue === undefined || this.selectedRateValue === '') {
        this.isRateSelected = false;
      }
      else {
        this.isRateSelected = true;
        if (this.bidListing.data.isDiscount) {
          this.discountPrice = (this.selectedRateValue * this.bidListing.data.bulkPrice / 100)
          this.finalDiscPrice = this.bidListing.data.bulkPrice - this.discountPrice
        }
        if (this.bidListing.data.isBid) {
          this.bidUpPrice = (this.selectedRateValue * this.bidListing.data.bulkPrice / 100)
          this.finalBidupPrice = this.bidListing.data.bulkPrice + this.bidUpPrice
        }
      }
    }
    catch (e) { }

  }
  quantityInput() {
    try {
      this.isQuantityEntered = false
      if (this.quantityValue == 0 || this.quantityValue < 0 || this.quantityValue == null || this.quantityValue === undefined) {
        this.quantityValue = null
        this.isQuantityEntered = false
      }
      else {
        if (this.quantityValue > 0) {
          this.isQuantityEntered = true
          this.setFormValues()
        }
      }
    }
    catch (e) { }
  }
  setFormValues() {
    if (this.bidListing.data.isDiscount && !this.bidListing.data.isBid) {
      const numberBulkPrice = Number(this.bidListing.data.bulkPrice);
      const percentageChange = (this.selectedRateValue / 100) * numberBulkPrice
      this.newPriceOffered = numberBulkPrice - percentageChange
    }
    else if (!this.bidListing.data.isDiscount && this.bidListing.data.isBid) {
      const numberBulkPrice = Number(this.bidListing.data.bulkPrice);
      const percentageChange = (this.selectedRateValue / 100) * numberBulkPrice
      this.newPriceOffered = numberBulkPrice + percentageChange
    }
    this.bidRequestFormDetails.setValue({
      'isBid': this.bidListing.data.isBid,
      'isDiscount': this.bidListing.data.isDiscount,
      'isRateSelected': this.isRateSelected,
      'bidRate': this.bidRequestFormDetails.value.bidRate,
      'quantityValue': this.bidRequestFormDetails.value.quantityValue,
      'requestStatus': 'Pending',
      'bidCreator': this.bidListing.data.bidCreator,
      'listingId': this.bidListing.data.listingId,
      'listingProduct': this.bidListing.data.listingProductName,
      'listingTitle': this.bidListing.data.listingTitle,
      'imageUrls': this.bidListing.data.imageUrls,
      'newPriceOffered': this.newPriceOffered,
      'oldPriceListed': this.bidListing.data.bulkPrice,
      'isPending': true,
      'isApproved': false,
      'isCanceled': false,
      'bidWriter': this.bidListing.data.listingCreatorID,
      'bidCreatorName': this.bidListing.data.bidCreatorName,
      'bidWriterName': this.bidListing.data.bidWriterName,
      'bidCreateDate': null,
      'bidActionDate': null,
    })
  }
  async onSendBidRequest() {
    try {
      if (this.bidRequestFormDetails.invalid || this.bidRequestFormDetails == undefined) {
        return
      }
      if (this.bidRequestFormDetails.valid) {
        this.isdiscountRequestPlaced = false;
        const useBidAmount = (this.bidRequestFormDetails.value.newPriceOffered * this.bidRequestFormDetails.value.quantityValue).toFixed(2)
        this.bidrequesthandle.sendBidRequest(
          this.bidRequestFormDetails.value.listingId,
          this.bidRequestFormDetails.value.bidWriter,
          this.bidRequestFormDetails.value.listingProduct,
          this.bidRequestFormDetails.value.imageUrls,
          this.bidRequestFormDetails.value.isDiscount,
          this.bidRequestFormDetails.value.isBid,
          this.bidRequestFormDetails.value.isRateSelected,
          this.bidRequestFormDetails.value.bidRate,
          this.bidRequestFormDetails.value.quantityValue,
          useBidAmount,
          this.bidRequestFormDetails.value.bidCreator,
          this.bidRequestFormDetails.value.newPriceOffered,
          this.bidRequestFormDetails.value.listingTitle,
          this.bidRequestFormDetails.value.isPending,
          this.bidRequestFormDetails.value.isApproved,
          this.bidRequestFormDetails.value.isCanceled,
          this.bidRequestFormDetails.value.oldPriceListed,
          this.bidRequestFormDetails.value.requestStatus,
          this.bidRequestFormDetails.value.bidCreatorName,
          this.bidRequestFormDetails.value.bidWriterName,
          this.bidRequestFormDetails.value.bidCreateDate,
          this.bidRequestFormDetails.value.bidActionDate,
        ).then(checkRes => {
          if (checkRes && checkRes.status === 200) {
            this.isdiscountRequestPlaced = true
            this.dialogRef.close(this.isdiscountRequestPlaced);
          }
        })
      }
    }
    catch (e) {
    }
  }
}
