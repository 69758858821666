<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <h2 *ngIf="this.LoadingText !== ''">
            {{this.LoadingText}}
        </h2>
        <mat-spinner></mat-spinner>
    </div>
</div>

<div class="content">
    <div class="bannerSCentral">
    </div>
    <div>
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
            <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" class="product-card"
                *ngFor="let product of selectedProduct">
                <mat-card class="mat-elevation-z4">
                    <div class="listingcontainer">
                        <div class="listingtitle">
                            <div class="productname">
                                <p (click)="onSelectProduct(product.productName)" style="font-size:large;">
                                    {{product.productName|translate
                                    }}</p>
                            </div>
                            <div class="categoryname">
                                <span style="color: #808080 ; font-size: 13px;">
                                    {{'more' | translate}} {{product.categoryName|translate}}
                                </span>
                            </div>
                        </div>
                        <div class="listingInfo">
                        </div>
                    </div>
                    <img (click)="onSelectProduct(product.productName)" mat-card-image
                        [src]="product.productImage[0]" />
                    <mat-card-content>
                        <div id="productstate">
                            <button mat-raised-button color="primary" style="margin-right:5px"
                                (click)="onSelectProduct(product.productName)">{{'View All'|translate}}</button>
                            <span id="singlestates" *ngFor="let state of product.productState">
                                <button mat-stroked-button color="primary"
                                    (click)="onSelectProductwithState(product.productName,state)">{{state|translate}}</button></span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>