<div *ngIf="isLoading" class="content-loading">
    <div id="loadingScreen">
        <div class="loadingText">
            <h1> Loading</h1>
            <h2>My Trades </h2>
        </div>
        <mat-spinner></mat-spinner>
    </div>
</div>
<div class="contentPO">
    <div class="banner">
        <div class="overlay">
            <h1 class="fade-in-text">My Trades</h1><br>
        </div>
    </div>
    <div class="verifiedID" *ngIf="!this.gstDetailsPending && !this.gstVerificationPending">
        <h5>Verified
        </h5>
    </div>
    <div *ngIf="!isLoading">
        <div *ngIf="isTradAvl">
            <div class="searchField">
                <mat-form-field appearance="outline">
                    <mat-label>Search Item</mat-label>
                    <input matInput (keyup)="searchItem($event)" placeholder="Item Name" #input>
                </mat-form-field>
            </div>
            <div class="tradeTableActivate">
                <div class="purchaseOrderContainer">
                    <div class="center">
                        <mat-hint class="listHead">Bookings expire daily at 1 P.M</mat-hint><br>
                        <mat-hint class="listHead">Indian Standard Time</mat-hint>
                    </div>
                    <div class="tradeTable">
                        <div class="columnsRow frow">
                            <div class="colname contr">
                                <div class="colIcon supIc"> <mat-icon> insert_drive_file</mat-icon>
                                    <span>Contract Id</span>
                                </div>
                            </div>

                            <div class="colname appls">
                                <div class="colIcon supIc"> <mat-icon>supervisor_account</mat-icon>
                                    <span>Bookings</span>
                                </div>
                            </div>

                            <div class="colname items">
                                <div class="colIcon supIc">
                                    <mat-icon>burst_mode</mat-icon>
                                    <span>Item SKU</span>
                                </div>
                            </div>
                        </div>
                        <div class="detailsRow">
                            <div *ngFor="let trs of this.userIdTRs" class="allTrades frow">
                                <div class="rowname contr">
                                    <button (click)="openBottomSheet('stds','contract',trs.contractDetails)" [ngClass]="{'checkBuyer':trs.contractDetails.contractType === 'Buy',
                                    'checkSupplier':trs.contractDetails.contractType === 'Sell'}" class="skuBt"
                                        class="ctrBt" mat-stroked-button>
                                        <span>{{trs.contractDetails.contrId}}</span>
                                        <br>
                                        <mat-hint *ngIf="trs.contractDetails.contrCreatorBizName"
                                            class="skuHint">{{trs.contractDetails.contrCreatorBizName}}</mat-hint>
                                    </button>
                                    <mat-divider></mat-divider>
                                </div>

                                <div class="rowname appls">
                                    <button [ngClass]="{'checkBuyer':trs.contractDetails.contractType === 'Buy',
                                    'checkSupplier':trs.contractDetails.contractType === 'Sell'}" class="skuBt"
                                        class="appBt" mat-stroked-button>
                                        {{trs.contractDetails.contractApplicants.length}}
                                    </button>
                                    <mat-divider></mat-divider>
                                </div>

                                <div class="rowname items">
                                    <button (click)="openBottomSheet('stds','item',trs.itemDetails)" [ngClass]="{'checkBuyer':trs.contractDetails.contractType === 'Buy',
                                        'checkSupplier':trs.contractDetails.contractType === 'Sell'}" class="skuBt"
                                        mat-stroked-button>
                                        <span>{{trs.itemDetails.productName}}</span><br>
                                        <mat-hint *ngIf="trs.itemDetails.SKU"
                                            class="skuHint">#SKU&nbsp;{{trs.itemDetails.SKU}}</mat-hint>
                                    </button>
                                    <mat-divider></mat-divider>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="noOrders" *ngIf="!isTradAvl">
            <div>
                <div class="contentNoPo">
                    <div class="secHead">
                        <h3 class="headNoPo">No Trades Published.</h3>
                    </div>
                    <div class="noPoSec">
                        <h3>eContracts</h3>
                        <h5>
                            Identify genuine Selling & Buying with paid tokens.
                        </h5>
                    </div>
                    <div class="noPoSec">
                        <h3>
                            Timing
                        </h3>
                        <h5>
                            Trade window opens at 11am daily, and all trades close by 1pm for quick procurement.
                        </h5>
                    </div>
                    <div class="noPoSec">
                        <h3>
                            Network
                        </h3>
                        <h5>
                            Get in link with top tier Suppliers & Buyers, without any cost.
                        </h5>
                    </div>
                    <div class="noPoSec">
                        <h3>Quality</h3>
                        <h5>
                            Connect with top-tier businesses for industrial quality.
                        </h5>
                    </div>
                    <div class="noPoSec">
                        <h3>Vaayu Delivery</h3>
                        <h5>
                            Myapaar's Vaayu Delivery for bulk orders delivered right to your location.
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>