import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HandleListingsService } from 'src/app/services/handle-listings.service';

@Component({
  selector: 'app-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.css']
})
export class RequirementsComponent implements OnInit {
  isNewRequirement: boolean = true;
  isBuyerFormDisplay: boolean = false;
  isSellerFormDisplay: boolean = false;
  itemName: string
  itemQuantity: number
  phoneContact: number
  selectedAccountType: string;
  targetPrice: string;
  accountTypes: string[] = [
    "Seller",
    "Buyer",]
  displayPostSuccess: boolean = false;
  isLoading: boolean = false;

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private usels: HandleListingsService
  ) { }

  ngOnInit(): void {
    if (this.router.url.includes('VerifiedSellers')) {
      this.isSellerFormDisplay = true
      this.selectedAccountType = 'Buyer'
    }
    if (this.router.url.includes('VerifiedBuyers')) {
      this.isBuyerFormDisplay = true
      this.selectedAccountType = 'Seller'
    }
   
  }

  doneForm() {
    try {
      if (!this.phoneContact || this.phoneContact === undefined) {
        this.notify('Add Details')
        return
      }
      this.isLoading = true
      const reqForm = {
        accountType: this.selectedAccountType,
        itemName: this.itemName,
        itemQuantity: this.itemQuantity,
        phone: this.phoneContact,
        targetPr: this.targetPrice
      }
      this.usels.newRequirement(reqForm).then(posted => {
        this.isLoading = false
        let notifyMess: string
        if (posted && posted.status === 201) {
          this.displayPostSuccess = true
          notifyMess = posted.message
        }
        else {
          notifyMess = posted.message
        }
        this.notify(notifyMess)
      })
    }
    catch (e) { throw e }
  }
  notify(mess: string) {
    try {
      const action = "Ok"
      // give snackBar error then route to profule page on action
      // login error notific
      let snackbarref = this._snackBar.open(mess, action, {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
      snackbarref.afterDismissed().subscribe(() => {
        this.router.navigate([''])
      })
      // go to profile page - the user can see all the info here and edit it - like upload GST and PAN as well
    }
    catch (e) {
      throw e
    }
  }
}
