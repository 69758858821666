<div class="contentSC">
    <div class="bannerSCentral">
        <div class="shopbanner">
            <div class="backgroundBanner">
                <img *ngIf="!this.isUserShopImagePresent"
                    src="../../../assets/svgiconsandimages/profilebannerImg.svg" />
                <img *ngIf="this.isUserShopImagePresent" [src]="this.displayShopImg" />
            </div>

            <div class="shopimg sec">
                <div class="profileActions settingsButton">
                    <div class="playstoreLinks">
                        <div class="ln editProfile" *ngIf="this.isOwnerProfile">
                            <button class="circle-link" matTooltip="Myapaar Profile Settings"
                                [routerLink]="['/accountInformation', this.userId]">
                                <img alt="playstore myapaar" src="../../../assets/svgiconsandimages/settings-teams.svg"
                                    alt="Myapaar team settings" style="width:100%;">
                            </button>
                            <div class="editButton">Settings</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="logoImg">
                <img *ngIf="!this.isUserLogoPresent" src="../../../assets/svgiconsandimages/4-brand-svgrepo-com.svg" />
                <img *ngIf="this.isUserLogoPresent" [src]="this.displayLogo" />
            </div>
        </div>
    </div>
    <div *ngIf="isLoading" class="content-loading">
        <div id="loadingScreen">
            <div class="loadingText">
                <h1> Verified Merchants </h1>
                <h3> Loading Profile </h3>
            </div>
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div *ngIf="!this.isLoading" class="profileDetails">
        <div class="ucol cardDetails">
            <h1 class="business-name">{{this.userCompanyName}}</h1>
            <div class="rowCol account">
                <div class="row gst" *ngIf="this.isUserGSTavailable">
                    <div class="keyIs">GSTIN</div>
                    <div class="valueIs gstBlock">{{this.userGST}}
                        <div matTooltip="GST Verified User" *ngIf="this.isUserGSTVerified" class="verifiedLogo"></div>
                    </div>
                    <div>
                        <mat-icon *ngFor="let ratings of ratingsAsStars(this.userRating)"
                            style="color: rgb(226, 226, 7);">star</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!this.isLoading" class="profileDetails">
        <div class="ucol cardDetails">
            <div class="cardHeader one">
                {{'Account Details' | translate}}
            </div>
            <div class="two">
                <div class="userName">
                    <span matTooltip="User registered with GST. To report fraud kindly mail at solutions@myapaar.com"
                        class="userIs"> {{this.userName}}</span>
                    <!-- <button *ngIf="this.showLnkCTA && !this.isOwnerProfile" class="linkUser" mat-raised-button>
                        + Link <mat-icon>supervisor_account</mat-icon>
                    </button> -->
                </div>

            </div>

            <div class="account">
                <div class="allAccountTypes" *ngFor="let accountTypes of this.userSubAccountTypes">
                    <div class="valueIs">
                        {{accountTypes | translate}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="this.isOwnerProfile && this.currentUserId === this.userId" class="ucol cardDetails">
            <p class="referral mainText">
                {{'Grow your Ratings & Trade Network' | translate }} ?
            </p>
            <p class="referral subText">
                <b> {{'Invite others with personal referral code' | translate}}
                </b>
            </p>
            <div>
                <button class="shareRef" [cdkCopyToClipboard]="this.copyReferral" title="Whatsapp Share"
                    mat-stroked-button
                    (click)="this.successCopyToClipboard('Referral Message Copied ! Share on whatsapp')">
                    <img class="refim whatsappref" alt="myapaar referral"
                        src="./../../../assets/svgiconsandimages/whatsapp-ref.svg" />
                </button>
                <a class="shareRef" [href]="this.useEmailReferral" title="EmailRef" mat-stroked-button>
                    <img class="refim emailref" alt="myapaar referral"
                        src="./../../../assets/svgiconsandimages/email-ref.svg" />
                </a>
            </div>
        </div>

        <div *ngIf="this.filteredCats" class="ucol cardDetails">
            <p class="referral subText">
                <b> Deals in...</b>
            </p>
        </div>
        <div class="container">
            <div class="card" *ngFor="let cats of this.filteredCats">
                <img [src]="cats.categoryImages[0]" alt="Image Name">
                <div class="overlay">{{cats.categoryName}}</div>
            </div>
        </div>

        <div class="ucol cardDetails">
            <div class="cardHeader one">
                {{'Activity' | translate}}
            </div>
            <div class="rowCol three">
                <div class="row gst" *ngIf="this.isSellerProfile">
                    <div class="keyIs"> {{'Sold' | translate}}</div>
                    <div class="valueIs">
                        <span>{{this.userSoldTotalPos}} <mat-icon class="ico">check_box</mat-icon></span>
                    </div>
                </div>
                <div class="somespace"></div>

                <div class="row gst" *ngIf="this.isSellerProfile || this.isBuyerProfile">
                    <div class="keyIs"> {{'Paid' | translate}}</div>
                    <div class="valueIs">
                        <span>{{this.userPaidTotalPos}}<mat-icon class="ico">check</mat-icon> </span>
                    </div>
                </div>
                <div class="row gst" *ngIf="this.isBuyerProfile">
                    <div class="keyIs"> Placed</div>
                    <div class="valueIs">
                        <span>{{this.userPlacedTotalPos}}<mat-icon class="ico">loyalty</mat-icon></span>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="this.isSellerProfile" class="ucol listingcardDetails">
            <div class="cardHeader one">
                {{'Listings' | translate}}
            </div>
            <div class="contentMyListings">
                <div *ngIf="this.myListings.length>0 && !isLoading" fxLayout="row wrap" fxLayoutGap="16px grid">
                    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngFor="let listing of this.myListings">
                        <mat-card
                            [routerLink]="['/Listing/Place_Bulk_Order',listing._id,listing.categoryUnique,listing.productName]"
                            class="mat-elevation-z4">
                            <div class="listingcontainer">
                                <div class="titleVariety">
                                    <div [routerLink]="['/Listing/Place_Bulk_Order',listing._id,listing.categoryUnique,listing.productName]"
                                        class="listingtitle">
                                        {{listing.title}}
                                    </div>
                                    <div>
                                        <mat-card-subtitle *ngIf="listing.isVarietyAvailable">
                                            {{listing.varietyName | translate}}
                                        </mat-card-subtitle>
                                    </div>
                                </div>
                                <div class="spacerDiv"></div>
                                <div class="listingDetails">
                                    <div class="listingPrice">
                                        <span>{{(listing.bulkPrice).toLocaleString('en-IN')}}
                                            <span style="font-size: 12px;">₹
                                                / {{listing.useUnitShort}}</span>
                                        </span>
                                    </div>
                                    <div class="listingState">{{listing.packetSize}} Kg Packet</div>
                                </div>
                            </div>
                            <img [routerLink]="['/Listing/Place_Bulk_Order',listing._id,listing.categoryUnique,listing.productName]"
                                mat-card-image [src]="listing.imageUrls[0]" />
                            <!-- <img mat-card-image src="https://ik.imagekit.io/ikmedia/backlit.jpg"> -->
                            <div id="subtitle">
                                <div class="categoryProduct">
                                    <div id="categoryName"> {{listing.categoryName | translate }} </div>
                                    <div id="productName"> {{listing.productName | translate }} </div>
                                </div>
                                <div class="soldBy">
                                    <div class="listState">
                                        <span><mat-icon class="subtitle-spacer">
                                                location_on</mat-icon></span>
                                        <span>
                                            &nbsp;{{listing.state |
                                            translate}}
                                        </span>
                                    </div>
                                    <div class="sellerName">
                                        {{listing.companyName}}</div>
                                </div>
                            </div>
                            <mat-card-content>
                                <p class="listingDesc">
                                    {{listing.description}}
                                </p>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div *ngIf="this.noListingFound && this.userAccountType=='Seller'">
                    <h2>{{noListings}}</h2>
                    <div [routerLink]="['/createListing']" class="card">
                        <img src="https://via.placeholder.com/300x400" alt="Image Name">
                        <div class="overlay">Add Listings</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="this.isBuyerProfile" class="ucol cardDetails">
            <div class="cardHeader one">
                <span class="head">
                    Payments
                </span>
            </div>
            <div class="rowCol three">
                <div class="row gst">
                    <div class="keyIs">Invoices Paid</div>
                    <div class="valueIs"><span>{{this.paidInvoices}} <mat-icon class="ico">check</mat-icon></span></div>
                </div>
                <div class="row gst">
                    <div class="keyIs">Items Paid Intime</div>
                    <div class="valueIs"><span>{{this.paidInvoIntime}}<mat-icon
                                class="ico">playlist_add_check</mat-icon></span>
                    </div>
                </div>
                <div class="row gst">
                    <div class="keyIs">Items Late Payment</div>
                    <div class="valueIs"><span>{{this.paidInvoLate}}<mat-icon
                                class="ico">error_outline</mat-icon></span></div>
                </div>
                <div class="row gst">
                    <div class="keyIs">Items Pending Payment</div>
                    <div class="valueIs"><span>{{this.paidInvoPending}} <mat-icon class="ico">history</mat-icon> </span>
                    </div>
                </div>

            </div>
        </div>
        <div class="ucol cardDetails">
            <div class="deleteAccount">
                <a routerLink="/ByeMyapaar" mat-raised-button>Delete My Account</a>
            </div>
        </div>

    </div>
</div>