<div class="contentTrade">
    <div *ngIf="this.validOptions && this.validOptions.length > 0" class="firstSec">
        <div *ngFor="let options of this.validOptions">
            <button class="options" mat-raised-button (click)="switchRoute(options.optionlink)">
                <span id="eachOption">{{options.optionName}}</span>
            </button>
        </div>
    </div>
    <div class="mainSec">
        <router-outlet></router-outlet>
    </div>
</div>