<div class="contentSC">
    <div class="bannerSCentral">
        <div class="shopbanner">
            <div class="backgroundBanner">
                <img *ngIf="!this.isUserShopImagePresent"
                    src="../../../assets/svgiconsandimages/profilebannerImg.svg" />
                <img *ngIf="this.isUserShopImagePresent" [src]="this.displayShopImg" />
            </div>
            <div class="logoImg">
                <img *ngIf="!this.isUserLogoPresent" src="../../../assets/svgiconsandimages/4-brand-svgrepo-com.svg" />
                <img *ngIf="this.isUserLogoPresent" [src]="this.displayLogo" />
            </div>
        </div>
    </div>
    <div class="profileDetails">
        <div class="ucol cardDetails">
            <h1 class="business-name">{{this.userCompanyName}}</h1>
            <div class="rowCol account">
                <div class="row gst" *ngIf="this.isUserGSTavailable">
                    <div class="keyIs">GSTIN</div>
                    <div class="valueIs gstBlock">{{this.userGST}}
                        <div matTooltip="GST Verified User" *ngIf="this.isUserGSTVerified" class="verifiedLogo"></div>
                    </div>
                    <div>
                        <mat-icon *ngFor="let ratings of ratingsAsStars(this.userRating)"
                            style="color: rgb(226, 226, 7);">star</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="profileDetails">
        <div class="ucol cardDetails">
            <div class="cardHeader one">
                {{'Account Details' | translate}}
            </div>
            <div class="two">
                <div class="userName">
                    <span matTooltip="User registered with GST. To report fraud kindly mail at solutions@myapaar.com"
                        class="userIs"> {{this.userName}}</span>
                    <!-- <button *ngIf="this.showLnkCTA && !this.isOwnerProfile" class="linkUser" mat-raised-button>
                        + Link <mat-icon>supervisor_account</mat-icon>
                    </button> -->
                </div>

            </div>

            <div class="account">
                <div class="allAccountTypes" *ngFor="let accountTypes of this.userSubAccountTypes">
                    <div class="valueIs">
                        {{accountTypes | translate}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="this.filteredCats" class="ucol cardDetails">
            <p class="referral subText">
                <b> Deals in...</b>
            </p>
        </div>
        <div class="container">
            <div class="card" *ngFor="let cats of this.filteredCats">
                <img [src]="cats.categoryImages[0]" alt="Image Name">
                <div class="overlay">{{cats.categoryName}}</div>
            </div>
        </div>
        <div class="container">
            <div class="card" *ngFor="let cats of this.filteredCats">
                <img [src]="cats.categoryImages[0]" alt="Image Name">
                <div class="overlay">{{cats.categoryName}}</div>
            </div>
        </div>
    </div>
</div>