import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { HandleListingsService } from 'src/app/services/handle-listings.service';
import { ListingdialogmanagerServiceService } from 'src/app/services/listingdialogmanager-service.service';
import { FormGroup, FormControl, Validators, FormBuilder, ValidationErrors, AbstractControl } from '@angular/forms';
import { InCartService } from 'src/app/services/in-cart.service';
import { ListingModel } from 'src/app/datamodels/ListingModel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreditTermsModel } from 'src/app/datamodels/groupedmodel/creditTermsDetails';
import { PackagingTypeModel } from 'src/app/datamodels/groupedmodel/packagingTypeModel';
import { UtilsService } from 'src/app/services/utils.service';
import { CommentpostService } from 'src/app/services/commentpost.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ConfirmPaymentmodeComponent } from './confirm-paymentmode/confirm-paymentmode.component';
import { HandleUserLinkService } from 'src/app/services/handle-user-link.service';
interface enquiryDetails { itemName: string, listingId: string, listingCreatorCompany: string, listingCreatorId: string }
@Component({
  selector: 'app-single-listing',
  templateUrl: './single-listing.component.html',
  styleUrls: ['./single-listing.component.css']
})
export class SingleListingComponent implements OnInit {
  public getScreenWidth: any;

  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  loginUserInfo: any
  public currentUserId: any
  isLoading = false;
  listingId: string;
  currentListingwithId: any;
  allImagesListing: []
  private currentUserFirstName: string;
  private currentUserLastName: string;
  private currentUserFullName: string;
  disabled = false;
  max: number;
  min: number = 0;
  step: number = 0;
  quantityValue: number;
  addListingToCart: FormGroup;
  addTargetPrice: FormGroup;
  totalReviewsonCurrentListing: number;
  cartItemsforUser: ListingModel[] = []
  listingExistsInCart: boolean = false
  selectedCreditDays: CreditTermsModel;
  selectedPackagingType: PackagingTypeModel;
  isAddBrandingSection: boolean = false
  genericUserInfo: any;
  isGeneric: boolean = true;
  userCart: any;
  cartIdforUser: string;
  addedListings: ListingModel[]
  sellerViewingOwnListing: boolean = false
  reviewImagePreview: string;
  panelOpenState = false;
  businessName: string
  validationMessages = {
    'quantityValue': {
      'required': 'Add Quantity from slider'
    },
    'creditTerms': {
      'required': 'Select Credit terms'
    },
    'packagingType': {
      'required': 'Select Packaging Type'
    }
  }
  formErrors = {
    'quantityValue': 'Add Quantity',
    'creditTerms': 'Select Credit terms',
    'packagingType': 'Select Packaging'
  }
  reviewSubmitted: string;
  reviewDeleted: string;
  copyReferral: string;
  isDisplayProductDetails: boolean = true;
  isSortexListing: boolean;
  priceUpShowForSortex: number;
  companyNameSeller: string;
  showLnkCTA: boolean;
  _wrap: boolean = false;
  _value: number;
  _step: number;
  quickEnquiryDetails: enquiryDetails

  constructor(
    private usrLnkSrv: HandleUserLinkService,
    public ldms: ListingdialogmanagerServiceService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private handleListingService: HandleListingsService,
    private inCartService: InCartService,
    private utils: UtilsService,
    private commentservice: CommentpostService,
    private _bottomSheet: MatBottomSheet,
  ) { }

  ngOnInit(): void {
    try {
      this.doAuth()
      this.addListingToCart = new FormGroup({
        quantityValue: new FormControl('', { validators: [Validators.required] }),
        creditTerms: new FormControl('', { validators: [Validators.required] }),
        packagingType: new FormControl('', { validators: [Validators.required] }),
      })
      this.addTargetPrice = new FormGroup({
        targetPriceValue: new FormControl('', { validators: [Validators.required] }),
      })
      this.isLoading = true;
      this.route.params.subscribe(params => {
        if (params && params.listingId) {
          this.listingId = params.listingId;
          this.getCurrentListingwithId(this.listingId)
        }
      })

      const rawUserCart = localStorage.getItem("userCart")
      if (rawUserCart) {
        const userCart = JSON.parse(rawUserCart)
        this.cartIdforUser = userCart._id
      }
      // const rawCachedCart = localStorage.getItem("cachedCart")
      // const cachedCart = JSON.parse(rawCachedCart)
    }
    catch (e) {
      throw e
    }
  }

  packagingChange(newpackaging: PackagingTypeModel) {
    try {
      this.selectedPackagingType = newpackaging
      if (this.selectedPackagingType && this.selectedPackagingType.packagingType.includes('Branded')) {
        this.selectedPackagingType.isBranded = true
        this.isAddBrandingSection = true
      }
      else {
        this.selectedPackagingType.isBranded = false
        this.isAddBrandingSection = false
      }
    }
    catch (e) {
      throw e
    }
  }
  openBottomSheet(bulkCardData, currentListing): void {
    this._bottomSheet.open(ConfirmPaymentmodeComponent, {
      panelClass: 'custom-width',
      data: { currentOrder: bulkCardData, listing: currentListing },
    });
  }


  errorForm(err: string) {
    const action = "Ok"
    try {
      this._snackBar.open(err, action, {
        duration: 5000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
      })
      this.isLoading = false
    }
    catch (e) {
      throw e
    }
  }
  async getCurrentListingwithId(listingId: string) {
    try {
      this.handleListingService.getSingleListing(listingId).then(
        (fetchedlisting) => {
          this.currentListingwithId = fetchedlisting
          this.companyNameSeller = this.currentListingwithId.companyName
          this.businessName = this.utils.getBusinessNameForURL(this.companyNameSeller)
          this.quickEnquiryDetails = {
            itemName: this.currentListingwithId.title,
            listingId: this.currentListingwithId._id,
            listingCreatorCompany: this.businessName,
            listingCreatorId: this.currentListingwithId.listingCreatorID
          }
          const priceUpByPerc = this.currentListingwithId.taxSlab + 8
          const principalPrice = this.currentListingwithId.bulkPrice
          const priceUpAmt = priceUpByPerc * (principalPrice / 100)
          this.priceUpShowForSortex = principalPrice + priceUpAmt
          this.isSortexListing = this.currentListingwithId.isListingSortexType
          const useCurrentURL = window.location.href
          this.copyReferral = `Hey ! Check ${this.currentListingwithId.title} on Myapaar. Open Listing : ${useCurrentURL}. Check price and other details.`
          this.currentListingwithId.listingCreatorID === this.currentUserId ? this.sellerViewingOwnListing = true : this.sellerViewingOwnListing = false
          if (!this.currentListingwithId.productDetailInfo
            || !this.currentListingwithId.productDetailInfo[0].type
            || !this.currentListingwithId.productDetailInfo[0].metric
            || !this.currentListingwithId.productDetailInfo[1].type
            || !this.currentListingwithId.productDetailInfo[1].metric
          ) {
            this.isDisplayProductDetails = false
          }
          this.allImagesListing = this.currentListingwithId.imageUrls
          this.isLoading = false;
          //make quantity value = 0 and selectedCreditTerm as 1st option of choosen CT array of seller
          this.quantityValue = 0
          // if (this.currentListingwithId.creditTerms && this.currentListingwithId.creditTerms[0].viewValue != "Pay Later") {
          //   let idInst = (Math.random() + 1).toString(36).substring(7);
          //   const instantCTArr = [{
          //     checked: "true",
          //     percentage: 0,
          //     value: 0,
          //     viewValue: "Pay Later",
          //     _id: idInst
          //   }]
          //   const newArrayWithInstantPO = instantCTArr.concat(this.currentListingwithId.creditTerms)
          //   this.currentListingwithId.creditTerms = newArrayWithInstantPO
          // }
          this.selectedCreditDays = this.currentListingwithId.creditTerms[0]
          this.selectedPackagingType = this.currentListingwithId.packagingType[0]
          // max value that can be ordered - 50% of total quantity added by seller
          this.max = ((50 / 100) * this.currentListingwithId.bulkQuantity);
          this.min = this.currentListingwithId.lotSize;
          this.step = this.currentListingwithId.lotSize;
          this._value = this.currentListingwithId.lotSize;
          this.addListingToCart.patchValue({
            'quantityValue': this.max,
          });
          this.updateViewCount(this.currentListingwithId._id, this.currentListingwithId.listingViewCount)
        }
      )
    }
    catch (e) { }
  }

  updateViewCount(lId: string, viewC: number) {
    this.handleListingService.updtViewCount(lId, viewC)
  }
  //counter

  shouldDisableDecrement(inputValue: number): boolean {
    try {
      return !this._wrap && inputValue <= this.min;
    }
    catch (e) {
      throw e
    }
  }

  shouldDisableIncrement(inputValue: number): boolean {
    try {
      return !this._wrap && inputValue >= this.max;
    }
    catch (e) {
      throw e
    }
  }

  incrementValue(step: number = 1): void {
    try {
      let inputValue = this._value + step;

      if (this._wrap) {
        inputValue = this.wrappedValue(inputValue);
      }

      this._value = inputValue;
    }
    catch (e) {
      throw e
    }

  }

  private wrappedValue(inputValue): number {
    try {
      if (inputValue > this.max) {
        return this.max + inputValue - this.max;
      }

      if (inputValue < this.min) {

        if (this.max === Infinity) {
          return 0;
        }

        return this.max + inputValue;
      }

      return inputValue;
    }
    catch (e) {
      throw e
    }
  }


  getTotalReviewsonListing(reviewsNumber: any) {
    try {
      this.totalReviewsonCurrentListing = reviewsNumber
    }
    catch (e) {
      throw e
    }
  }

  reloadPostList(submittedStatus: string) {
    try {
      this.reviewSubmitted = submittedStatus
      // this.totalReviewsonCurrentListing = reviewsNumber
    }
    catch (e) {
      throw e
    }
  }

  viewUserProfile(userId: string, businessName: string) {
    try {
      const lnkCTA = this.checkLink(this.currentUserId, userId)
      this.router.navigate(["Merchant_Network/Check_Business_Profile", userId, businessName, lnkCTA])
    }
    catch (e) {
      throw e
    }
  }

  reloadPostListOnDelete(submittedStatus: string) {
    try {
      this.reviewDeleted = submittedStatus
      // this.totalReviewsonCurrentListing = reviewsNumber
    }
    catch (e) {
      throw e
    }
  }

  onQuantityChange($event: any) {
    try { }
    catch (e) {
      throw e
    }
    this.quantityValue = $event.value
  }

  doAuth() {
    try {   //add conditions to if where backbutton should not be visible
      //since app component is loading first and then other components- we need to manually check with authservice for latest auth status
      // keep checking for user auth status
      // this.authService.autoAuthUser();
      this.userIsAuthenticated = this.authService.getIsAuth();
      this.loginUserInfo = this.authService.getUserLoginInfo()
      this.currentUserId = this.loginUserInfo.userId
      if (this.userIsAuthenticated) {
        this.isGeneric = false
      }
      else if (!this.userIsAuthenticated) {
        this.isGeneric = true
      }
      this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        //if auth status changes, get user info again
        this.loginUserInfo = this.authService.getUserLoginInfo()
        this.currentUserId = this.loginUserInfo.userId
      });
    }
    catch (e) { }
  }

  // dialog box to ask for discount from seller
  // #Hidden
  openDiscountDialog(listingId: string, listingCreatorID: string, listingProductName: string, listingTitle: string, bulkPrice: string, bulkQuantity: string, imageUrls: [], bidWriterName: string) {
    console.log('authentc', this.userIsAuthenticated)
    try {
      const isDiscount = true
      const isBid = false
      //when login First time, Javascript Object is returned , which can be used unparsed, 
      //for already loggedin user (on page reload),JSON string object is returned which needs to be parsed
      this.loginUserInfo = this.authService.getUserLoginInfo();
      if (this.userIsAuthenticated) {
        console.log('reached disc - check login details ?', this.loginUserInfo)
        this.currentUserId = this.loginUserInfo?.userId
        this.currentUserFirstName = this.loginUserInfo?.firstname
        this.currentUserLastName = this.loginUserInfo?.lastname
        this.currentUserFullName = this.currentUserFirstName + " " + this.currentUserLastName
        console.log('unparsedlogDetails', this.loginUserInfo?.userId)
        if (this.currentUserId === undefined || this.currentUserId === null) {
          const parsedUserDetails = JSON.parse(this.loginUserInfo)
          this.currentUserId = parsedUserDetails.userId
          this.currentUserFirstName = parsedUserDetails.firstname
          this.currentUserLastName = parsedUserDetails.lastname
          this.currentUserFullName = this.currentUserFirstName + " " + this.currentUserLastName
          console.log('parsedLog', this.currentUserId)
        }
        // const loggedInUser = JSON.parse(this.loginUserInfo)
        const bidCreator = this.currentUserId
        console.log('bidCreator', bidCreator)
        const bidCreatorName = this.currentUserFullName
        this.ldms.askDiscountonListing({ data: { listingId, listingCreatorID, listingProductName, listingTitle, isDiscount, isBid, bidCreator, bulkPrice, bulkQuantity, imageUrls, bidWriterName, bidCreatorName } }).subscribe((discount => {
          this.successCopyToClipboard("Bid Placed. View bids and add to cart after approval.")
        }))
      }
    }
    catch (e) { }
  }

  openBidDialog(listingId: string, listingCreatorID: string, listingProductName: string, listingTitle: string, bulkPrice: string, bulkQuantity: string, imageUrls: [], bidWriterName: string) {
    try {
      const isDiscount = false
      const isBid = true
      //when login First time, Javascript Object is returned , which can be used unparsed, 
      //for already loggedin user (on page reload),JSON string object is returned which needs to be parsed
      this.loginUserInfo = this.authService.getUserLoginInfo();
      if (this.userIsAuthenticated) {
        this.currentUserId = this.loginUserInfo?.userId
        this.currentUserFirstName = this.loginUserInfo?.firstname
        this.currentUserLastName = this.loginUserInfo?.lastname
        this.currentUserFullName = this.currentUserFirstName + '' + this.currentUserLastName
        console.log('unparsedlogDetails', this.loginUserInfo?.userId)
        if (this.currentUserId === undefined || this.currentUserId === null) {
          const parsedUserDetails = JSON.parse(this.loginUserInfo)
          this.currentUserId = parsedUserDetails.userId
          this.currentUserFirstName = parsedUserDetails.firstname
          this.currentUserLastName = parsedUserDetails.lastname
          this.currentUserFullName = this.currentUserFirstName + '' + this.currentUserLastName
          console.log('parsedLog', this.currentUserId)
        }

        // const loggedInUser = JSON.parse(this.loginUserInfo)
        // console.log('userInf', loggedInUser)
        const bidCreator = this.currentUserId
        console.log('bidCreator', bidCreator)
        console.log('bidCreator', bidCreator)
        const bidCreatorName = this.currentUserFullName

        //listingCreatorId is bidWriter or one who receives the bid to approve , bidcreator is one who is requesting approval for discount or bidUp
        this.ldms.placeBidonListing({ data: { listingId, listingCreatorID, listingProductName, listingTitle, isDiscount, isBid, bidCreator, bulkPrice, bulkQuantity, imageUrls, bidWriterName, bidCreatorName } }).subscribe((bid => {
          this.successCopyToClipboard("Bid Placed. View bids and add to cart after approval.")
        }))
      }
      else if (!this.userIsAuthenticated) {
        this.loginError("You need to log in to create Bid Request", "Login")
        return
      }
    }
    catch (e) {
    }
  }


  addToCart(currentlisting: ListingModel, type: string) {
    try {
      if (!this.userIsAuthenticated) {
        this.loginError("Login to add items to cart", "Login")
        return
      }
      const now = new Date();
      if (type === 'sample') {
        this.inCartService.saveCartItems(
          type,
          this.cartIdforUser,
          currentlisting._id,
          currentlisting.isListingSortexType,
          currentlisting.useUnit,
          currentlisting.useUnitShort,
          this.selectedCreditDays,
          this.selectedPackagingType,
          // sample pack quantity is 0.005 quintal or 500gm but is how 250 grams on frontend , 250 grams is taken by team for return if needed
          (currentlisting.packetSize / 1000).toString(),
          // (0.0025).toString(),
          now
        ).then(savedCart => {
          this.userCart = savedCart.cart
          localStorage.setItem('userCart', JSON.stringify(this.userCart));
          this.itemAdded("Sample Pack added to cart", "")
          //save cached cart from items that are in cart 
          if (this.userCart && this.userCart.itemListings && this.userCart.itemListings.length !== 0) {
            this.authService.createCacheCart(this.userCart)
          }
        })
      }
      if (type === 'bulk') {
        const bulkOrderData = {
          orderType: type,
          userCartID: this.cartIdforUser,
          listingID: currentlisting._id,
          listingSortexType: currentlisting.isListingSortexType,
          listingUnit: currentlisting.useUnit,
          listingUnitShort: currentlisting.useUnitShort,
          selectedPaymentMode: null,
          selectedPackagingType: null,
          bulkQuantity: this.addListingToCart.value.quantityValue,
          date: now
        }
        this.openBottomSheet(bulkOrderData, this.currentListingwithId)
      }
    }
    catch (e) {
      throw e
    }
  }

  sendTargetPrice() {
    try {
      if (!this.userIsAuthenticated) {
        this.loginError("Login to add items to cart", "Login")
        return
      }
    }
    catch (e) {
      throw e
    }
  }

  getLatestPrice() {
    try {
      if (!this.userIsAuthenticated) {
        this.loginError("Login to get latest price", "Login")
        return
      }
      else {
        this.priceUpdatedNotif("Latest Price will be updated by our Vaayu team. We will call you soon", "")
        this.handleListingService.priceUpdate
          (
            this.currentListingwithId._id,
            this.currentListingwithId.title,
            this.currentListingwithId.listingCreatorID,
            this.currentListingwithId.listingCreator,
            this.currentListingwithId.phone,
            this.currentListingwithId.email,
            this.currentUserId,
            this.loginUserInfo.firstname + this.loginUserInfo.lastname,
            this.loginUserInfo.phone || null,
            this.loginUserInfo.email || null,
            this.currentListingwithId.bulkPrice,
          )
      }
    }
    catch (e) {
      throw e
    }
  }

  successCopyToClipboard(success: string) {
    try {
      const action = "Ok"
      // give snackBar error then route to profule page on action
      // login error notific
      this._snackBar.open(success, action, {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      })
    }
    catch (e) {
      throw e
    }
  }

  checkLink(currLogUsr: string, linkToUser: string) {
    try {
      this.showLnkCTA = this.usrLnkSrv.startLink(currLogUsr, linkToUser)
      return this.showLnkCTA
    }
    catch (e) {
      throw e
    }
  }

  itemAdded(message: string, action: string) {
    try {
      this._snackBar.open(message, action, {
        duration: 1500,
        verticalPosition: 'bottom',
        // horizontalPosition: 'center',
      })
    }
    catch (e) {
      throw e
    }
  }

  priceUpdatedNotif(message: string, action: string) {
    try {
      this._snackBar.open(message, action, {
        duration: 3500,
        verticalPosition: 'top',
        // horizontalPosition: 'center',
      })
    }
    catch (e) {
      throw e
    }
  }

  loginError(message: string, action: string) {
    try {
      let snackbarRef = this._snackBar.open(message, action, {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
      })
      snackbarRef.onAction().subscribe(() => {
        this.router.navigate(['/login']);
      })
    }
    catch (e) {
      throw e
    }
  }
}
